
import {filter,  map } from 'rxjs/operators';
import { GetOpportunityDetails } from 'app/content/opportunity/profile/opportunity-profile.graphql';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModelService } from './model.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { GetOpportunityApplications } from 'app/graphql/queries';
import { ErrorService } from './error.service';
import { AppApolloService } from './app.apollo.service';
import { GetPeopleCount } from 'app/content/opportunity/add/add.graphql';

@Injectable()
export class Opportunity {
    opportunityUpdated$: Subject<any> = new Subject();
    opportunity = new BehaviorSubject(null);
    selectedOpportunity: any;
    applicants = new Subject();
    watchlistApplicants = new Subject();
    customStatuses = new BehaviorSubject(null);
    customStatus = new BehaviorSubject(null);
    opportunityId = new BehaviorSubject(null);

    showFloatingInfo = new Subject();
    constructor(
        private model: ModelService,
        private router: Router,
        private apollo: Apollo,
        private appApollo: AppApolloService,
        private errorService: ErrorService
    ) {}

    new() {
        this.opportunity.next({});
    }

    create(data) {
        return new Promise((resolve, reject) => {
            this.model.post(`opportunities`, { opportunity: data })
                .subscribe(response => {
                    this.opportunity.next(response);
                    this.opportunityId.next(response.id);
                    resolve(response);
                }, error => {
                    console.log('error', error)
                    reject(error);
                });
        });
    }

    edit(data) {
        return new Promise((resolve, reject) => {
            this.model.patch(`opportunities/${this.opportunity.value.id}`, { opportunity: data })
                .subscribe(response => {
                    this.getOpportunity(response.id);
                    resolve(response);
                    this.setFloatingInfo();
                    this.router.navigate(['/opportunity', this.opportunity.value.id]);
                }, error => {
                    console.log('Error is: ', error);
                    this.errorService.showErrorModal(error)
                });
        })
    }

    redirectAfterMutation(opportunityId) {
        this.router.navigate(['/opportunity', opportunityId]);
    }

    update(data) {
        return this.model.patch(`opportunities/${this.opportunity.value.id}`, { opportunity: data })
    }

    getOpportunity(id) {
        this.apollo.watchQuery({
            query: GetOpportunityDetails,
            variables: {id: id}
        })
        .valueChanges
        .pipe(
            map(res => res.data)
        ).pipe(
        filter(data => data !== undefined))
        .subscribe(response => {
            const opportunity = JSON.parse(JSON.stringify(response['getOpportunity']));
            this.opportunity.next(opportunity);
            this.opportunityId.next(opportunity.id);
            }, err => {
            this.errorService.showErrorModal(err.networkError);
        })
    }

    getApplications(opportunityId, qs) {
        if (!qs) {
            qs = '';
        }

        this.apollo.query({
            query: GetOpportunityApplications,
            variables: qs
        })
        .subscribe((response: any) => {
            this.applicants.next(response.data['allOpportunityApplication'].data);
        }, error => {
            console.log('Error for applicants: ', error);
        });

    }

    getWatchlistApplicants(opportunityId) {
        const qs = `&with=watchlist_for_opportunity,nationalities`
        this.model.get({name: `opportunities/${opportunityId}/watchlist`, qs: qs})
            .subscribe(watchlistApplicants => {
                this.watchlistApplicants.next(watchlistApplicants)
            }, error => {
                console.log('Error fetching watchlist applicants: ', error)
            })
    }


    getCustomStatuses(opportunityId) {
        this.model.get({ name: `opportunities/${opportunityId}/custom_statuses`, qs: '' })
            .subscribe(data => {
                this.customStatuses.next(data);
            }, error => {
                console.log('Error for custom statuses: ', error);
            })
    }

    createCustomStatus(customStatus) {
        this.model.post(`opportunities/${this.opportunity.value.id}/custom_statuses`, customStatus)
            .subscribe(customStatuses => this.customStatuses.next(customStatuses),
            error => console.log('Error is: ', error))
    }

    updateCustomStatus(id, object) {
        if (!id || !object) {
            return;
        }
        this.model.patch(`opportunities/${this.opportunity.value.id}/custom_statuses/${id}`, { custom_status: object })
            .subscribe(customStatuses => this.customStatuses.next(customStatuses),
            error => console.log('Error when updating custom status: ', error))
    }

    deleteCustomStatus(id) {
        if (!id) {
            return;
        }
        this.model.delete(`opportunities/${this.opportunity.value.id}/custom_statuses/${id}`)
            .subscribe(customStatuses => {
                if (customStatuses) {
                    this.customStatuses.next(customStatuses)
                } else {
                    this.customStatuses.next([])
                }
            },
            error => console.log('Error when deleting custom status: ', error))
    }

    resolveComment(commentId) {
        return new Promise((resolve, reject) => {
            this.model.post(`opportunities/${this.opportunity.value.id}/comments/${commentId}/resolve`, {})
                .subscribe(data => {
                    resolve(data);
                }, error => {
                    console.log('Error is: ', error);
                });
        })
    }

    removeFromWatchlist(opportunityId, personId) {
        return this.model.delete(`opportunities/${opportunityId}/watchlist/${personId}`)
    }

    submitForReview(opportunityId) {
        return this.model.post(`opportunities/${opportunityId}/submit_for_review`, {});
    }

    getTotalCount(variables) {
        return new Promise((resolve, reject) => {
          this.appApollo.query(GetPeopleCount, variables, 'network-only')
          .then(response => {
              resolve(response['allPeople']);
          }, err => {
              this.errorService.showErrorModal(err.networkError);
          });
        });
      }

    setFloatingInfo() {
        this.showFloatingInfo.next(true);
        setTimeout(() => {
            this.showFloatingInfo.next(false);
        }, 3000);
    }

}
