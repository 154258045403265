
import {map} from 'rxjs/operators';
import { ListService } from './list.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { filterInt } from 'app/modular.functions';
import { CurrentPersonQuery } from '../content/search/search.graphql';
import { Apollo } from 'apollo-angular';
import { statusesArray } from 'app/constants';
@Injectable()
export class SearchService {

    public nationalities: any;
    public languages: any;
    public skills: any;
    public backgrounds: any;
    public study_levels: any;
    public statuses = statusesArray;
    public previousRoute: any;
    loading: Subject<any> = new Subject();
    filterInt = filterInt;
    peopleVariables: any;
    peopleGraphqlQuery: any;

    constructor(
        private list: ListService,
        private apollo: Apollo,
    ) {

    }

    getConstants() {
        return new Promise((resolve, reject) => {
            this.list.getListArray(['nationalities', 'languages', 'backgrounds', 'study_levels', 'sdg_goals', 'skills'])
                .subscribe(data => {
                    this.nationalities = this.mergeSort(data.nationalities);
                    this.languages = this.mergeSort(data.languages);
                    this.skills = this.mergeSort(data.skills);
                    this.backgrounds = this.mergeSort(data.backgrounds);
                    this.study_levels = this.mergeSort(data.study_levels);
                    return resolve(true);
                });
        })
    }

    makeArrayValues(values) {
        if (typeof values === 'string') {
            const int = +values;
            if (isNaN(int)) {
                return [values];
            } else {
                return [int];
            }
        }
        if (typeof values === 'number') {
            return [values];
        }
        if (typeof values === 'object') {
            if (Array.isArray(values)) {
                const int = +values[0];
                if (isNaN(int)) {
                    return values;
                } else {
                    return values.map(t => parseInt(t, 10));
                }
            }
            return null;
        }
        return null;
    }

    mergeSort(arr) {
        if (arr.length < 2) {
            return arr;
        }

        const middle = (arr.length / 2);
        const left = arr.slice(0, middle);
        const right = arr.slice(middle, arr.length);

        return this.merge(this.mergeSort(left), this.mergeSort(right));
    }

    merge(left, right) {
        const result = [];
        while (left.length && right.length) {
            if (!left[0].position && !right[0].position) {
                if (left[0].text.toLowerCase() < right[0].text.toLowerCase()) {
                    result.push(left.shift());
                } else {
                    result.push(right.shift());
                }

            } else {
                if (left[0].position < right[0].position) {
                    result.push(left.shift());
                } else {
                    result.push(right.shift());
                }
            }
        }

        while (left.length) {
            result.push(left.shift());
        }

        while (right.length) {
            result.push(right.shift());
        }

        return result;
    }

    getCurrentPerson(fetchPolicy?) {
        return this.apollo.query({
            query: CurrentPersonQuery,
            fetchPolicy: fetchPolicy || 'cache-first'
        }).pipe(
        map((data: any) => data.data.currentPerson))
    }

    makeFiltersFromData(data) {
        if (!data) {
            return;
        }
        const { backgrounds, languages, nationalities, skills, study_levels } = data;

        const fields = ['backgrounds', 'languages', 'nationalities', 'skills', 'study_levels'];

        const filters = {
            backgrounds,
            languages,
            nationalities,
            skills,
            study_levels,
        }

        for (const field of fields) {
            if (Array.isArray(filters[field]) && filters[field].length) {
                filters[field] = filters[field].map(t => t.constant_id || t.id);
            } else {
                filters[field] = null;
            }
        }

        if (data.graduation_date && data.graduation_date.to) {
            filters['graduation_date'] = {
                to: new Date(data.graduation_date.to)
            };
        }
        if (data.is_aiesecer) {
            filters['is_aiesecer'] = data.is_aiesecer;
        }

        return filters;
    }

    makeFilterObjects(filters) {
        if (!filters || typeof filters !== 'object' || Array.isArray(filters)) {
            return filters;
        }
        const filterWithObjects = {};
        const fields = ['backgrounds', 'languages', 'nationalities', 'skills', 'study_levels', 'statuses'];
        for (const field of fields) {
            if (filters[field]) {
                filterWithObjects[field] = this.getObjectsFromIds(filters[field], this[field]);
            }
        }
        return filterWithObjects;
    }

    getObjectsFromIds(listIds, list) {
        const array = listIds.map(id => list.find(l => +l.id === +id));
        return array;
    }

    makeHumanReadableDate(date: Date) {
        if (!date) {
            return date;
        }
        return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    }

}
