import gql from 'graphql-tag';

export const UpdateOpportunity = gql`
    mutation UpdateOpportunity($id: ID!, $opportunity: OpportunityInput) {
        updateOpportunity(id: $id, opportunity: $opportunity) {
            id
            scorecard {
                id
                name
                scorecard_attributes {
                    id
                    name
                }
            }
        }
    }
`