import {Component, OnDestroy, Input} from '@angular/core';
import {Slide} from './slide.component';
export enum Direction {UNKNOWN, NEXT, PREV}

@Component({
    selector: 'carousel-t',
    template: `
    <div (mouseenter)="pause()" (mouseleave)="play()" class="carousel slide">
    <div class="carousel-flex-parent">
    <div class="carousel-flex-selector">
      <a class="carousel-controls" (click)="prev()" [hidden]="!slides.length">
      <div [ngClass]="{'side-slide-active': sideSlideActive}" [style.background-image]="'url('+allSlides[getCurrentIndex() - 1 < 0 ? slides.length - 1 : getCurrentIndex() - 1].image+')'" class="side-slides">
      <div class="overlay"></div>
        <div style="position: absolute; top: 45%; left: 0; right: 0; z-index: 20;">
          <i class="fa-chevron-left fa-light icon-text" aria-hidden="true"></i>
        </div>
      </div>
      </a>
    </div>
    <div class="carousel-flex-slide">
    <div class="carousel-flex-slide">
      <div class="carousel-inner" (swipeleft)="next()" (swiperight)="prev()"><ng-content></ng-content></div>
    </div>    
    <div class="carousel-flex-selector">
      <a class="carousel-controls" (click)="next()" [hidden]="!slides.length">
      <div [ngClass]="{'side-slide-active': sideSlideActive}" [style.background-image]="'url('+allSlides[getCurrentIndex() < slides.length - 1 ? getCurrentIndex() + 1 : getCurrentIndex()].image+')'" class="side-slides">
      <div class="overlay"></div>
        <div style="position: absolute; top: 45%; left: 0; right: 0; z-index: 20;">
        <i class="fa-chevron-right fa-light icon-text" aria-hidden="true"></i>
        </div>
        </div>
     </a>
   </div>
    </div>
    </div>
  `,
  styleUrls: ['./carousel.component.scss']
})
// this.slides[this.getCurrentIndex()]
export class CarouselSetting implements OnDestroy {
    SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
    @Input() public noWrap:boolean;
    @Input() public noPause:boolean;
    @Input() public noTransition:boolean;
    public sideSlideActive:boolean = false;
    @Input() public allSlides;

    @Input() public get interval():number {
        return this._interval;
    }

    public set interval(value:number) {
        this._interval = value;
        this.restartTimer();
    }

     slides:Array<Slide> = [];
     currentInterval:any;
     isPlaying:boolean;
    //  sideSlideActive:boolean;
     destroyed:boolean = false;
     currentSlide:Slide;
     _interval:number;

    public ngOnDestroy() {
        this.destroyed = true;
    }

    public ngOnInit() {
        // console.log(this.getCurrentIndex());
        // console.log(this.slides);
    }

    public select(nextSlide:Slide, direction:Direction = Direction.UNKNOWN) {
        let nextIndex = nextSlide.index;
        if (direction === Direction.UNKNOWN) {
            direction = nextIndex > this.getCurrentIndex() ? Direction.NEXT : Direction.PREV;
        }

        // Prevent this user-triggered transition from occurring if there is already one in progress
        if (nextSlide && nextSlide !== this.currentSlide) {
            this.goNext(nextSlide, direction);
        }
    }

     goNext(slide:Slide, direction:Direction) {
        if (this.destroyed) {
            return;
        }

        slide.direction = direction;

        if (direction === Direction.PREV) {
            slide.slidingLeft = true;
            slide.active = true;
        }
        if (direction === Direction.NEXT) {
            slide.slidingRight = true;
            slide.active = true;
        }

        if (this.currentSlide) {
            this.currentSlide.direction = direction;
            this.currentSlide.active = false;
            this.currentSlide.slidingRight = false;
            this.currentSlide.slidingLeft = false;
        }

        this.currentSlide = slide;

        // every time you change slides, reset the timer
        this.restartTimer();
    }

     getSlideByIndex(index:number) {
        let len = this.slides.length;
        for (let i = 0; i < len; ++i) {
            if (this.slides[i].index === index) {
                return this.slides[i];
            }
        }
    }

     getCurrentIndex() {
        return !this.currentSlide ? 0 : this.currentSlide.index;
    }

     next() {
        this.sideSlideActive = true;
        let newIndex = (this.getCurrentIndex() + 1) % this.slides.length;

        if (newIndex === 0 && this.noWrap) {
            this.pause();
            return;
        }

        return this.select(this.getSlideByIndex(newIndex), Direction.NEXT);
    }

     prev() {
        this.sideSlideActive = true
        let newIndex = this.getCurrentIndex() - 1 < 0 ? this.slides.length - 1 : this.getCurrentIndex() - 1;

        if (this.noWrap && newIndex === this.slides.length - 1) {
            this.pause();
            return;
        }
        return this.select(this.getSlideByIndex(newIndex), Direction.PREV);
    }

     restartTimer() {
        this.resetTimer();
        let interval = +this.interval;
        if (!isNaN(interval) && interval > 0) {
            this.currentInterval = setInterval(() => {
                let nInterval = +this.interval;
                if (this.isPlaying && !isNaN(this.interval) && nInterval > 0 && this.slides.length) {
                    this.next();
                } else {
                    this.pause();
                }
            }, interval);
        }
    }

     resetTimer() {
        if (this.currentInterval) {
            clearInterval(this.currentInterval);
            this.currentInterval = null;
        }
    }

    public play() {
        if (!this.isPlaying) {
            this.isPlaying = true;
            this.restartTimer();
        }
    }

    public pause() {
        if (!this.noPause) {
            this.isPlaying = false;
            this.resetTimer();
        }
    }

    public addSlide(slide:Slide) {
        slide.index = this.slides.length;
        this.slides.push(slide);
        if (this.slides.length === 1 || slide.active) {
            this.select(this.slides[this.slides.length - 1]);
            if (this.slides.length === 1) {
                this.play();
            }
        } else {
            slide.active = false;
        }
    }

    public removeSlide(slide:Slide) {
        this.slides.splice(slide.index, 1);

        if (this.slides.length === 0) {
            this.currentSlide = null;
            return;
        }

        for (let i = 0; i < this.slides.length; i++) {
            this.slides[i].index = i;
        }
    }
}
