import gql from 'graphql-tag';

const OpportunityApplicationForUnion = gql`
  fragment OpportunityApplicationForUnion on OpportunityApplication {
    id
    opportunity {
      id
      title
      location
      profile_photo
    }
  }
`;

const PersonForUnion = gql`
  fragment PersonForUnion on Person {
    id
    created_at
    message
    read
    notifiable {
      ... OpportunityApplicationForUnion
    }
    notifiable_type
  }
`;

const BranchEmployeeForUnion = gql`
  fragment BranchEmployeeForUnion on BranchEmployee {
    id
    branch {
      id
      name
    }
    person {
      id
      profile_photo
    }
  }
`;

const fragments = {
  notificationFragment: gql`
    fragment notificationFragment on Notification {
      id
      created_at
      title
      message
      read
      icon
      notifiable {
        ... OpportunityApplicationForUnion
        ... BranchEmployeeForUnion
      }
      notifiable_type
    }
    ${OpportunityApplicationForUnion}
    ${BranchEmployeeForUnion}
  `
};
// QUERY

export const NotificationsList = gql`
  query notificationList($page: Int, $per_page: Int, $filters: NotificationFilter) {
    notificationList(page: $page, per_page: $per_page, filters: $filters) {
      data {
        ... notificationFragment
      }
      paging {
        current_page
        total_items
        total_pages
      }
    }
  }
  ${fragments.notificationFragment}
`;

export const NotificationUnreadCount = gql`
query notificationList($filters: NotificationFilter) {
  notificationList(filters: $filters) {
    paging {
      total_items
    }
  }
}
`;


// MUTATION

export const NotificationReadMutation = gql`
  mutation notificationRead($id: ID) {
    notificationRead(id: $id) {
      ... notificationFragment
    }
  }
  ${fragments.notificationFragment}
`;

