import gql from 'graphql-tag';

export const GetOpportunities = gql`
    query GetOpportunities($filters: OpportunityFilter) {
        allOpportunity(filters: $filters) {
            data {
                id
                status
            }
            paging {
                total_items
            }
        }
    }
`;


export const CurrentPersonQuery = gql`
    query CurrentPersonQuery {
        currentPerson {
            id
            managed_branches {
                branch_employees {
                    edges {
                        node {
                            id
                            person {
                                id
                            }
                            status
                        }
                    }
                }
                id
            }
            managed_companies {
                id
            }
            saved_filters {
                edges {
                    node {
                        body
                        id
                        model
                        name 
                    }
                }
            }
        }
    }
`
const fragments = {
    opportunity: gql`
    fragment opportunity on Opportunity {
        created_at
        id
        location
        managers {
            id
            full_name
            profile_photo
        }
        organisation {
            id
            name
        }
        scorecard {
            id
            name
            scorecard_attributes {
                id
                name
            }
        }
        title
        status
        questionnaire {
            id
            name
        }
      }
    `
};

export const UpdateOpportunity = gql`
    mutation UpdateOpportunity($id: ID!, $opportunity: OpportunityInput) {
        updateOpportunity(id: $id, opportunity: $opportunity) {
            ... opportunity
        }
    }
    ${fragments.opportunity}
`;
