import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-validation',
  templateUrl: './validation-component.component.html',
  styleUrls: ['./validation-component.component.scss']
})
export class ValidationComponent implements OnInit {

  @Input() message:string;
  constructor() { }

  ngOnInit() {
  }

}
