import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ApplicationsService} from "../../../../../services/applications.service";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";

@Component({
    selector: 'app-interview-modal',
    templateUrl: './interview-modal.component.html',
    styleUrls: ['./interview-modal.component.scss']
})
export class InterviewModalComponent implements OnInit {

    @Input() applicationOpportunityID: any;
    @Output() close: EventEmitter<boolean> = new EventEmitter(null);
    @ViewChild('onlineInteviewDetialsModal', { static: true }) onlineInteviewDetialsModal;
    @ViewChild('offilineInteviewDetialsModal', { static: false }) offilineInteviewDetialsModal;
    showOfflineInterviewModal: boolean;

    //Object varialbes
    applications = new Object();

    // Form grop variables
    interviewTypeForm: FormGroup;
    conductInterviewForm: FormGroup;

    // Other Variables
    openInterviewStep: number = 1;
    interviewHeader: string = "Schedule an interview";

    constructor(private applicationsService: ApplicationsService, private fb: FormBuilder) {
        this.openInterviewStep = 1;
        this.createInterviewTypeForm();
        this.createConductInterviewForm();
    }

    ngOnInit() {
    }

    createInterviewTypeForm() {
        this.interviewTypeForm = this.fb.group({
            type: new FormControl('online')
        })
    }

    createConductInterviewForm() {
        this.conductInterviewForm = this.fb.group({
            flag: new FormControl('yes')
        })
    }

    // Events

    onSubmitInterviewType(value) {
        if (value.type == "online") {
            this.applications["type"] = value.type;
            this.interviewHeader = "Online Interview";
            this.openInterviewStep = 2;
            // this.applicationsService.setMessage("");
        } else {
            this.applications["type"] = value.type;
            this.showOfflineInterviewModal = true
            this.offilineInteviewDetialsModal.show();
        }
    }

    onSubmitConductInterviewForm(value) {
        if (value.flag == "yes") {
            this.applications["aiesec_to_conduct_interview"] = true;
            this.applicationsService.postApi(`applications/${this.applicationOpportunityID}/update_interview_details`, this.applications).subscribe(applicationDetails => {
                this.interviewHeader = "AIESEC Interview";
                // this.applicationsService.setMessage("");
                if(applicationDetails) this.applicationsService.selectedApplication.next(applicationDetails)
                this.openInterviewStep = 3;
            });


        } else {
            this.applications["aiesec_to_conduct_interview"] = false;
            //this.getTimeslotApi();
            if (this.applications["type"] == "online") {
                this.onlineInteviewDetialsModal.show();
            } else {
                this.showOfflineInterviewModal = true
                this.offilineInteviewDetialsModal.show();
            }
        }
    }

    getTimeslotApi() {
        this.applicationsService.getApi(`applications/${this.applicationOpportunityID}/interview_slots`).subscribe(response => {
            let msg;
            for (let i = 0; i < response.length; i++) {
                if (response[i].accepted == true) {
                    msg = "Candidate has chosen has selected";
                    this.applicationsService.setMessage(msg);
                    return;
                } else {
                    msg = "Timeslots have been sent to the candidate. Waiting for their response";
                    this.applicationsService.setMessage(msg);
                }
            }
        });
    }

    closeModal() {
        this.openInterviewStep = 1;
        this.close.emit();
    }

    closeOnlineInterviewModal() {
        this.onlineInteviewDetialsModal.hide();
        this.closeModal();
    }

    closeOfflineInterviewModal() {
        this.showOfflineInterviewModal = false
        this.offilineInteviewDetialsModal.hide();
        this.closeModal();
    }
}
