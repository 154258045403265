import { UserService } from 'app/services/user.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { environment } from './../environments/environment';
import { CookieService } from 'ngx-cookie';
import { Title } from '@angular/platform-browser';
import { SEO_ITEMS } from './constants';
import { LinearProgressBarService } from './blocks/linear-progress-bar/linear-progress-bar.service';
import { filter } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { TranslationService } from './services/translation.service';
import { ActionBannerService } from './blocks/action-banner/action-banner.service';
declare const hj;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  userPresent = false;
  public currentUser: any = null;
  loading = false;
  isShowCookies = false;
  cookiesContainer: any;
  randomMatch: boolean;
  has_completed_platform_nps: boolean;
  showProgressBar: boolean;
  showUpdateLc: boolean;
  showActionBanner: boolean;

  constructor(
    public router: Router,
    private userService: UserService,
    private _cookieService: CookieService,
    private title: Title,
    private progressBar: LinearProgressBarService,
    private translation: TranslationService,
    private actionBannerService: ActionBannerService,
  ) {
    this.initTranslationProps();
  }

  ngOnInit() {
    this.subscribeToProgressBar();
    this.subscribeToShowUpdateLc();
    this.cookiesContainer = document.getElementById('cookiesContainer');
    this.router.events.subscribe((evt) => {

      if (evt instanceof NavigationStart) {
        let route;
        if (evt.url.length === 1) {
          route = evt.url;
        } else {
          const url = evt.url.split('/')[1];
          if (url === 'partner') {
            route = evt.url.split('/')[2];
          }
        }
        this.deleteMetaTags();
        if (SEO_ITEMS[route]) {
          this.setTitle(SEO_ITEMS[route]['Page title'])
          const metaPageDescription = SEO_ITEMS[route]['Meta Description'];
          const pageDescriptionElement = this.createElement('meta', 'description', metaPageDescription);
          const head = document.getElementsByTagName('head')[0];

          if (!environment.production) {
            const noIndexTag = this.createElement("meta", "robots", "noindex");
            head.appendChild(noIndexTag);
          }

          head.appendChild(pageDescriptionElement);
        } else {
          this.setTitle('AIESEC Partner Opportunities Portal');
        }
      }

      this.triggerHotjarNPS(evt);

      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      const regExp = new RegExp(/^(\/search$|\/search\?type=\d$|(?!\/search).*$)/);

      if (regExp.test(evt.url)) {
        window.scrollTo(0, 0)
      }
    });

    document.getElementById('preLoading').style.display = 'none';

    this.loading = true;
    this.userService.userPresent.subscribe(data => {
      if (data) {
        this.userPresent = true
        this.userService.userDataLoading.subscribe(userLoaded => {
          if (userLoaded) {
            this.currentUser = this.userService.currentUser;
            this.has_completed_platform_nps = this.currentUser.permissions.has_completed_platform_nps;
          }
        })
      } else {
        this.currentUser = null
        this.loading = false;
      }
    });
    this.loadGooglePlacesScript();

    this.checkActionBanner();
  }

  ngAfterViewInit() {
    if (!this._cookieService.get('accept_cookie_policy')) {
      this.cookiesContainer.style.display = 'block';
      setTimeout(() => {
        this.isShowCookies = true;
      }, 1000);
    }
  }

  setCookies() {
    this.isShowCookies = false;
    setTimeout(() => {
      this.cookiesContainer.style.display = '';
    }, 1000);
    const date = new Date();
    this._cookieService
      .put('accept_cookie_policy', 'true', { 'expires': new Date(date.getFullYear() + 1, date.getMonth(), date.getDate()) });
  }


  loadGooglePlacesScript() {
    const isFound = false;
    const dynamicScripts = `https://maps.googleapis.com/maps/api/js?key=${environment.googleApiKey}&libraries=places&language=en`;
    const node = document.createElement('script');
    node.src = dynamicScripts;
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    document.getElementsByTagName('head')[0].appendChild(node);
  }

  deleteMetaTags() {
    const meta = document.querySelectorAll('meta');
    for (let i = 4; i < meta.length; i++) {
      meta[i].remove();
    }
  }
  public setTitle(newTitle: string) {
    this.title.setTitle(newTitle);
  }


  createElement(el, attr, value) {
    const element = document.createElement(el);
    element.setAttribute('name', attr);
    element.setAttribute('content', value);
    return element;
  }

  elementTopForSingleRoute(route) {
    const isRoute = this.router.url.indexOf('/' + route + '/') > -1;
    if (!isRoute) {
      return false;
    }
    const array = this.router.url.split('/');
    if (array.length < 4) {
      return true;
    }
    return false;
  }

  subscribeToProgressBar() {
    this.progressBar.toggle$.subscribe(data => {
      this.showProgressBar = data;
    });
  }

  subscribeToShowUpdateLc() {
    this.userService.showUpdateLc
      .pipe(
        filter(data => data)
      )
      .subscribe(data => {
        this.showUpdateLc = true;
      });
  }

  initTranslationProps() {
    // TODO: UNCOMMENT this after transations are integrated and remove const lang = 'en';
    // const lang = navigator.language.split('-')[0];
    let lang = 'en';
    const isSetByBrowser = this._cookieService.get('browser_lang');

    // if issetbybrowser cookie is present the below code will not run (|| lang === 'lt')
    if (
      ( lang === 'pt' ||
        lang === 'es' ||
        lang === 'tr' ||
        lang === 'de' ) &&
      this.translation.currentLanguage === 'en' &&
      isSetByBrowser !== 'true'
    ) {
      forkJoin(
        this.translation.setLanguage(lang),
        this.translation.getVersion()
      ).subscribe(
        (data: any) => {
          this._cookieService.put('lang', lang);
          this._cookieService.put('browser_lang', 'true');
          this._cookieService.put('lang_version', data[1].translation);
          window.location.reload();
        },
        error => {
          alert('Server error. Language not changed');
        }
      );
    }
  }

  triggerHotjarNPS(evt) {
    if (evt instanceof NavigationEnd) {
      if (this.currentUser) {
        const currentRouteCount = +window.sessionStorage.getItem('currentRouteCount') || 0;
        if (evt.url.indexOf('/onboarding') === 0) {
          // Exclude onboarding route from url depth count to show NPS
          return;
        }
        if (currentRouteCount > 3) {
          hj('trigger', 'nps_survey');
        }
        window.sessionStorage.setItem('currentRouteCount', `${currentRouteCount + 1}`);
      }
    }
  }

  checkActionBanner() {
    if (!sessionStorage.getItem('action_banner')) {
      this.showActionBanner = true;
      this.actionBannerService.isBannerActive = true;
    }
    this.actionBannerService.showBanner.subscribe(data => {
      if (!data) {
        setTimeout(() => {
          this.closeActionBanner();
        }, 1000);
      }
    });
  }

  closeActionBanner() {
    this.showActionBanner = false;
  }

}

