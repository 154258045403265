import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinearProgressBarComponent } from './linear-progress-bar.component';

@NgModule({
  imports: [
      CommonModule
    ],
  providers: [
  ],
  exports: [
    LinearProgressBarComponent
  ],
  declarations: [
    LinearProgressBarComponent
  ]
})
export class LinearProgressBarModule { }
