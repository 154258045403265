import { CommonModule } from '@angular/common';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApolloModule } from 'apollo-angular';
import { HttpLinkModule } from 'apollo-angular-link-http';
import { AppApolloService } from './services/app.apollo.service';
import { TokenService } from './services/token.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    ApolloModule,
    HttpLinkModule,
  ],
  providers: [
    {
        provide: APP_INITIALIZER, useFactory: initializeApollo, deps: [
            AppApolloService
        ], multi: true,
    },
    AppApolloService,
    TokenService,
  ]
})
export class AppApolloModule {}

export function initializeApollo(appApolloService: AppApolloService) {
    return () => appApolloService.initializeApollo()
}
