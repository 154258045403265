import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import { ServicesModule } from './services.module';
import { GuardsModule } from './guards.module';
import { AppApolloModule } from './app.apollo.module';
import { RavenModule } from './raven.module';
import { appRouting } from './app.routing';

/************* Components ***************/
import { AppComponent } from './app.component';
import { NavbarComponent } from './blocks/navbar/navbar.component';
import { NotificationComponent } from './blocks/navbar/notification/notification.component';
import { PageNotFoundComponent } from './blocks/page-not-found/page-not-found.component';

/************* Our modules ***************/
import { ContainerModule } from './content/container.module';
import { SharedModule } from './shared.module';

import { TypeaheadModule, AccordionModule } from 'ngx-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { CookieModule } from 'ngx-cookie';
import { Ng2PageScrollModule } from 'ng2-page-scroll';
import { InfoModalComponent } from './blocks/info-modal/info-modal.component';
import { ErrorModule } from './blocks/error/error.module';
import {ToastrModule} from 'ngx-toastr';
import {AiesecToastComponent} from './blocks/toast/toast.component';
import { UpdateHomelcModule } from './blocks/update-homelc/update-homelc.module';
import { LinearProgressBarModule } from './blocks/linear-progress-bar/linear-progress-bar.module';
import { DragulaService } from 'ng2-dragula';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { TranslationService } from './services/translation.service';
import { ActionBannerComponent } from './blocks/action-banner/action-banner.component';


@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        NavbarComponent,
        NotificationComponent,
        InfoModalComponent,
        AiesecToastComponent,
        ActionBannerComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ServicesModule,
        AppApolloModule,
        RavenModule,
        GuardsModule,
        appRouting,
        ContainerModule,
        SharedModule,
        ErrorModule,
        TypeaheadModule.forRoot(),
        CookieModule.forRoot(),
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyAZAEd_XZ7ArFbcO1TRWsUllCkWq-KYvlE',
            libraries: ['places']
        }),
        Ng2PageScrollModule.forRoot(),
        AccordionModule.forRoot(),
        ToastrModule.forRoot({
            timeOut: 3000,
            positionClass: 'toast-top-right',
            extendedTimeOut: 2000,
            progressBar: true,
            closeButton: true
        }),
        UpdateHomelcModule,
        LinearProgressBarModule,
        NgSelectModule,
        FormsModule,
    ],
    entryComponents: [AiesecToastComponent],
    providers: [
        TranslationService,
        DragulaService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule { }
