
import {forkJoin as observableForkJoin,  Subscription } from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UserService } from './../../services/user.service';
import { Router } from '@angular/router';
import { ErrorService } from 'app/services/error.service';
import { AppApolloService } from 'app/services/app.apollo.service';
import { GetAllOpportunities, GetMyOpportunities, CurrentPersonQuery, OrganisationStatsQuery } from './myDashboard.graphql';
import { Apollo, QueryRef } from 'apollo-angular';
import * as moment from 'moment';
import { SearchService } from 'app/services/search.service';


@Component({
    selector: 'app-my-dashboard',
    templateUrl: './myDashboard.component.html',
    styleUrls: ['./myDashboard.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MyDashboardComponent implements OnInit {
    @ViewChild('applicantModal', { static: false }) applicantModal;
    @ViewChild('candidateModal', { static: false }) candidateModal;

    opportunity: any;
    perPageNumber = 10;
    stats: any;
    previousStats: any;
    loadingElement: any = {
        opportunities: false
    };
    opTypeFilter = [
        { id: 0, text: 'My Opportunities', value: 'my_op' },
        { id: 1, text: 'Branch Opportunities', value: 'branch_op' },
        { id: 2, text: 'All Opportunities', value: 'all_op' },
                    ];
    opStatusFilter = [
                        { id: 0, text: 'All Status', value: null },
                        { id: 1, text: 'Under Review', value: 'under_review' },
                        { id: 2, text: 'Live', value: 'open' },
                        { id: 3, text: 'Draft', value: 'draft' },
                        // { id: 4, text: 'Completed', value: 'completed' },
                        // { id: 5, text: 'Closed', value: 'closed' },
                        { id: 6, text: 'Archived', value: 'removed'}
                    ];
    managedBranchIds: any;
    today = new Date();
    lastWeekDate = moment().add('-1', 'weeks');
    opportunityStats: any = {};
    showOpOptions = {};
    selectedOpportunityId: number;
    showArchiveModal = false;
    showDuplicateModal = false;
    showInput = false;
    opportunitiesQuery: QueryRef<any>;
    opportunitiesSubscription: Subscription;
    opportunityList: any;
    opVariables = {};
    opFilters: any = {};
    selectedFilter = {};
    opDropdownFilters = {};
    variables;
    GetMyOpportunities = GetMyOpportunities;
    GetAllOpportunities = GetAllOpportunities;
    currentPerson: any;
    calculatedStats: any;
    activeStatus: any;
    showStats: boolean;

    constructor(
        private router: Router,
        private userService: UserService,
        private errorService: ErrorService,
        private appApollo: AppApolloService,
        private apollo: Apollo,
        private searchService: SearchService,
        ) {}

    ngOnInit() {

        this.userService.userDataLoading
        .subscribe(res => {
            if (res) {
                this.appApollo.query(CurrentPersonQuery, null)
                    .then((data: any) => {
                        this.currentPerson = data.currentPerson;
                        if (this.currentPerson) {
                            this.managedBranchIds = this.currentPerson.managed_branches.map(b => +b.id);
                            this.showStats = true;
                            if (!this.userService.getApprovedBranchesWithApprovedEmployee().length) {
                                this.opStatusFilter = this.opStatusFilter.filter(f => f.value === 'draft');
                                this.opTypeFilter = this.opTypeFilter.filter(f => f.value === 'my_op');
                                this.showStats = false;
                            }
                            this.activeStatus = this.opStatusFilter[0];
                            this.opFilters.status = this.opStatusFilter[0].value;
                            this.opTypeFilterChanged(this.opTypeFilter[0]);
                        }
                        if (this.currentPerson.managed_companies && this.currentPerson.managed_companies.length) {
                            this.getDashboardStats();
                        }
                    })
            }
        });
    }

    handleShowInput() {
        this.showInput = !this.showInput;
    }

    opTypeFilterChanged(selection) {
        this.opVariables = {
            page: 1,
            per_page: this.perPageNumber
        }
        this.opFilters['branches'] = null;
        this.opFilters['organisation'] = null;
        this.opDropdownFilters['my'] = false;
        switch (selection.value) {
            case 'my_op':
                this.opDropdownFilters['my'] = true;
                break;
            case 'branch_op':
                this.opFilters['branches'] = this.managedBranchIds;
                break;
            case 'all_op':
                this.opFilters['organisation'] = +this.currentPerson.managed_companies[0].id;
                break;
            default:
                break;
        }
        this.getOpportunities(this.opVariables, this.opFilters, this.opDropdownFilters['my']);
    }

    opStatusFilterChanged(statusObject) {
        this.opVariables = {
            page: 1,
            per_page: this.perPageNumber
        }
        this.opFilters.status = statusObject.value;
        this.getOpportunities(this.opVariables, this.opFilters, this.opDropdownFilters['my']);
    }

    getOpportunities(opVariables, filters?, my?) {
        this.loadingElement.opportunities = true;
        this.variables = {
            ...opVariables, filters
        };
        if (this.opportunitiesSubscription) {
            this.opportunitiesSubscription.unsubscribe();
        }
        this.opportunitiesQuery = this.apollo.watchQuery({
            query: my ? GetMyOpportunities : GetAllOpportunities,
            variables: this.variables
        });

        this.opportunitiesSubscription = this.opportunitiesQuery
            .valueChanges.pipe(
            map((data: any) => {
                if (data && data.data) {
                    if (my) {
                        return data.data.myOpportunities;
                    } else {
                        return data.data.allOpportunity;
                    }
                }
                return data.data;
            }))
            .subscribe(opportunityList => {
                this.loadingElement.opportunities = false;
                this.opportunityList = opportunityList;
                this.makeStats(this.opportunityList.data);
            }, err => {
                this.loadingElement.opportunities = false;
                this.opportunityList = {};
                this.errorService.showErrorModal(err);
            })
    }

    fetchMoreOpportunities() {
        if (this.loadingElement.opportunities ||
            (this.opportunityList && this.opportunityList.paging.current_page >= this.opportunityList.paging.total_pages)) {
                return;
        }

        this.loadingElement.opportunities = true;
        this.opportunitiesQuery.fetchMore({
            variables: {
                page: this.opportunityList.paging.current_page + 1,
              },
            updateQuery: (prev, {fetchMoreResult}) => {
                this.loadingElement.opportunities = false;
                if (!fetchMoreResult) {
                    return prev;
                  }
                  const opField = this.opDropdownFilters['my'] ? 'myOpportunities' : 'allOpportunity';
                  const updatedObject = Object.assign({}, prev, {
                    ...prev,
                    [opField]: {
                      ...prev[opField],
                      data: [
                        ...prev[opField].data,
                        ...fetchMoreResult[opField].data
                      ],
                      paging: fetchMoreResult[opField].paging
                    }
                  });
                  return updatedObject;
            }
        })
    }

    makeStats(opportunities) {
        if (!opportunities || !opportunities.length) {
            return;
        }

        opportunities.forEach(op => {
            if (this.opportunityStats[op.id]) {
                return;
            }
            this.opportunityStats[op.id] = {};
            if (op.applications_status_count) {
                for(const key in op.applications_status_count) {
                    this.opportunityStats[op.id][key] = op.applications_status_count[key];
                }
            }
            if (op.applications_close_date) {
                const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                const date = moment(op.applications_close_date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
                const diff = date.diff(today, 'days');
                if (diff === 0) {
                    this.opportunityStats[op.id].countDown = 'Closes today';
                } else if (diff === 1) {
                    this.opportunityStats[op.id].countDown = '1 day left';
                } else if (diff <= 5 && diff > 0) {
                    this.opportunityStats[op.id].countDown = `${diff} days left`;
                } else if (diff < 0) {
                    this.opportunityStats[op.id].countDown = `Closed`;
                }
            }
        })
    }

    getDashboardStats() {

        observableForkJoin(
            this.apollo.query({
                query: OrganisationStatsQuery,
                variables: {
                    id: this.currentPerson.managed_companies[0].id,
                    start_date: this.lastWeekDate,
                    end_date: this.today,
                }
            }).pipe(
            map((data: any) => data.data.getOrganisation))
            ,
            this.apollo.query({
                query: OrganisationStatsQuery,
                variables: {
                    id: this.currentPerson.managed_companies[0].id,
                    start_date: moment().add('-2', 'weeks'),
                    end_date: this.lastWeekDate,
                }
            }).pipe(
            map((data: any) => data.data.getOrganisation))
        )
        .subscribe(res => {
            this.stats = res[0].stats;
            this.previousStats = res[1].stats;
            const currentApprovalRate = (this.stats.approved_applications_count)
                                        / ((this.stats.approved_applications_count + this.stats.rejected_applications_count) || 1);
            const previousApprovalRate = this.previousStats.approved_applications_count
                                        / ((this.previousStats.approved_applications_count + this.previousStats.rejected_applications_count) || 1);
            this.calculatedStats = {
                'applicantsChangePercent': Math.round((((this.stats.applications_count - this.previousStats.applications_count) /
                (this.previousStats.applications_count || 1)) * 100)) || 0,
                'approvalPercent': Math.round((currentApprovalRate * 100)) || 0,
                'approvalChangePercent': Math.round((((currentApprovalRate - previousApprovalRate) / (previousApprovalRate || 1)) * 100)) || 0,
            }
        });
    }

    searchOpportunity(searchText) {
        this.opVariables['q'] = searchText;
        this.getOpportunities(this.opVariables, this.opFilters, this.opDropdownFilters['my']);
    }

    openArchiveModal(opId) {
        this.selectedOpportunityId = opId;
        this.showArchiveModal = true;
    }

    onOpportunityUnarchived(opportunity) {
        console.log('op unarchived: ', opportunity);
    }

    openDuplicateModal(opId) {
        this.selectedOpportunityId = opId;
        this.showDuplicateModal = true;
    }

    toggleOpOptions(opId) {
        const show = this.showOpOptions[opId];
        this.showOpOptions = {};
        this.showOpOptions[opId] = !show;
    }

    hideOptionsMenu(opId) {
        this.showOpOptions[opId] = false;
    }

    routeToSearch(op) {
        this.searchService.previousRoute = this.router.url;
        this.router.navigateByUrl(`/search?opportunity=${op.id}`);
    }

    getHiredCount(opId) {
        const realizedCount = this.opportunityStats[opId].realized || 0;
        const completedCount = this.opportunityStats[opId].completed || 0;
        return realizedCount + completedCount;
    }

    getOpStatusColor(status) {
        switch (status) {
            case 'draft':
                return 'draft';
            case 'open':
                return 'live'
            case 'under_review':
                return 'under-review';
            case 'completed':
            case 'finished':
                return 'completed';
            case 'closed':
                return 'closed';
            case 'removed':
                return 'archived';
        }
    }

    getRouteLink(opportunity) {
        if (!opportunity) {
            return;
        }
        if (opportunity.status === 'open') {
            return `/opportunity/${opportunity.id}/applications`;
        } else {
            return `/opportunity/${opportunity.id}`;
        }
    }
 }
