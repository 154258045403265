import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { AppApolloService } from '../../services/app.apollo.service';
import { SurveyModalQuery, PlatformNpsResponseMutationQuery } from './survey-modal.graphql';
import { Router } from '@angular/router';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss']
})

export class SurveyModalComponent implements OnInit {
  @ViewChild('surveyModal', { static: false }) surveyModal: ModalDirective;
  showModal: boolean;
  title: string;
  details: string;
  route: string;
  surveyMessage: string;
  ratings = [];
  surveyQuestions: any;
  appliedScore: number;
  appliedAnswer: string;
  surveyResponse = [];
  surveyQuestion:any;
  step: number;
  showErrorMessage: boolean;

  constructor(
   private appApollo: AppApolloService, private router: Router, private errorService: ErrorService
  ) {
    for (var i = 1; i <= 10; i++) {
      this.ratings.push(i);
    }
    const variables = {};
    this.appApollo.query(SurveyModalQuery, variables)
      .then(data => {
        this.showModal = true;
        this.title = data['getPlatformNpsQuestions']['title'];
        this.surveyQuestions = data['getPlatformNpsQuestions']['survey_questions'];

        this.surveyMessage = data['getPlatformNpsQuestions']['survey_questions'][0]['question_text'];
      },
        err => {
        });
  }

  ngOnInit() {
  }

  applyRating(value) {
    this.appliedScore = value.toString();
    this.surveyResponse[0] = { question_id: parseInt(this.surveyQuestions[0]['id']), answer: this.appliedScore }
    this.step = 2;
    if (this.appliedScore < 7) {
      this.surveyQuestion = this.surveyQuestions[3]['question_text'];
    } else if (this.appliedScore == 7 || this.appliedScore == 8) {
      this.surveyQuestion = this.surveyQuestions[2]['question_text'];
    } else {
      this.surveyQuestion= this.surveyQuestions[1]['question_text'];
    }
  }


  submit() {
    if (this.appliedAnswer) {
      this.showErrorMessage = false;
      let path = this.router.url;
      if (this.appliedScore < 7) {
        this.surveyResponse[1] = { question_id: parseInt(this.surveyQuestions[3]['id']), answer: this.appliedAnswer }    
      } else if (this.appliedScore === 7 || this.appliedScore === 8) {
        this.surveyResponse[1] = { question_id: parseInt(this.surveyQuestions[2]['id']), answer: this.appliedAnswer }    
      } else {
        this.surveyResponse[1] = { question_id: parseInt(this.surveyQuestions[1]['id']), answer: this.appliedAnswer }
      }
      const variables = {
        responses: this.surveyResponse,
        path: path
      }
      this.appApollo.mutate(PlatformNpsResponseMutationQuery, variables)
        .then(response => {
          this.close();
        }, err => {
          this.errorService.showErrorModal(err.networkErr);
        })
    } else {
      this.showErrorMessage = true;
    }
  }


  close() {
    this.showModal = false;
  }

}
