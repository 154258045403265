import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { slideIn } from 'app/animations';

@Component({
  selector: 'app-maintenance-banner',
  templateUrl: './maintenance-banner.component.html',
  styleUrls: ['./maintenance-banner.component.scss'],
  animations: [slideIn],
  encapsulation: ViewEncapsulation.Emulated
})
export class MaintenanceBannerComponent implements OnInit {

  showMaintenanceBanner = false;

  constructor() { }

  ngOnInit() {
  }

  closeBanner() {
    if(this.showMaintenanceBanner) {
      this.showMaintenanceBanner = false;
    }
  }

}
