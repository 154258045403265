import gql from 'graphql-tag';

export const AllOpportunity = gql`
  query AllOpportunity($q: String, $filters: OpportunityFilter) {
    allOpportunity(q: $q, filters: $filters) {
      paging {
        total_items
      }
    }
  }
`;

export const CurrentPersonQuery = gql`
  query CurrentPersonQuery {
    currentPerson {
      id
      recommended_incoming_programme
      managed_branches {
        programme_manager {
          id
          profile_photo 
          full_name
          home_lc {
            id
            name
          }
          aiesec_email 
          email 
          contact_detail {
            country_code
            phone
          }
          programmes {
            id
            short_name
            short_name_display 
          }
        }
        branch_employees {
          edges {
            node {
              id
              person {
                id
              }
              status
            }
          }
        }
        home_lc {
          id
        }
        id
        name
        status
      }
      managed_companies {
        id
        name
        summary
      }
    }
  }
`

export const GetCommittee = gql`
  query getCommitteeBasicDetails($id: ID!) {
    getCommitteeBasicDetails(id: $id) {
      id
      parent {
        id
      }
      programme_fees {
        edges {
          node {
            programme {
              id
            }
            fee_for
          }
        }
      }
    }
  }
`

export const GetSubProducts = gql`
  query getSubProducts($programme_id: ID) {
    subProducts(programme_id: $programme_id) {
      id
      name
    }
  }
`

export const OpportunityDurationTypes = gql`
  query OpportunityQuery {
    opportunityDurationTypes {
      accommodation
      id
      duration_type
      programme{
        id
      }
      salary
    }
  }
`

export const GetMOI = gql`
  query getMOI($sdg_goal_id: ID!) {
    measureOfImpacts(sdg_goal_id: $sdg_goal_id) {
      id
    }
  }
`

export const GetPeopleCount = gql`
  query getPeopleCount($only: String, $filters: PeopleFilter) {
    allPeople(only: $only, filters: $filters) {
      paging {
        total_items
      }
    }
  }
`;

export const BulkCreateSelectionProcess = gql`
  mutation selectionProcessBulkCreate($opportunity_id: ID!, $selection_processes: [ SelectionProcessInput ]) {
    selectionProcessBulkCreate(opportunity_id: $opportunity_id, selection_processes: $selection_processes) {
      id
      no_of_days
      position
      title
    }
  }
`;

export const GetCityByMc = gql`
  query GetCityByMc($mc_id: Int!, $allocated_to_lc: Boolean!) {
    cityByMc(mc_id: $mc_id, allocated_to_lc: $allocated_to_lc) {
      id
      lat
      lng
      name
    }
  }
`;

export const CreateOpportunity = gql`
  mutation CreateOpportunity($opportunity: OpportunityInput) {
    createOpportunity(opportunity: $opportunity) {
      applicants_count
      application_processing_time
      applications_close_date
      skills {
        id
        constant_id
        constant_name
      }
      id
      specifics_info {
        ask_gip_question
        computer
        ef_test_required
        expected_work_schedule
        salary
        salary_currency {
          alphabetic_code
          id
          name
          numeric_code
          programme_fees {
            fee
            fee_for
            id
            lc_id
            mc_id
          }
        }
        salary_periodicity
        saturday_work
        saturday_work_schedule
      }
      status
      study_levels {
        id
        name
        type_id
      }
      title
      updated_at
      video_url
      view_count
    }
  }
`;

export const BranchContractMutation = gql`
  mutation branchContracts($id: ID, $programme_id: ID) {
    brachContracts(id: $id, programme_id: $programme_id) {
      id
    }
  }
`;

export const BranchContractQuery = gql`
  mutation branchContracts($id: ID) {
    brachContracts(id: $id) {
      id
      contract
      programme {
        id
        short_name_display
      }
    }
  }
`;

export const CreateSlot = gql`
  mutation slotCreate($opportunity_id: ID!, $slot: SlotInput) {
    slotCreate(opportunity_id: $opportunity_id, slot: $slot) {
      id
      title
      status
      openings
      available_openings
      applications_close_date
      start_date
      end_date
    }
  }
`;
