export const environment = {
  development: false,
  // TODO: Remove the need for this
  googleMapUrl: 'https://www.google.com/maps/embed/v1/place?',
  // googleApiKey: 'AIzaSyDkVPn2W0cTotLIlyqlNfSIUgDD7CGYrQ4', // Commutatus key
  // googleApiKey: 'AIzaSyDCs2PIDZwuYC2Ti_PJkmfDEAOXoWsGhrs', // Chennai Volunteers's key
  production: false,
  staging: true,
  // TODO: Remove the need for this
  appApiUrl: 'https://pop-staging-sl.aiesec.org',
  apiUrl: 'https://staging-jruby.aiesec.org/v2',
  apiBase: 'https://staging-jruby.aiesec.org',
  publicToken: 'dd0df21c8af5d929dff19f74506c4a8153d7acd34306b9761fd4a57cfa1d483c',
  cdnUrl: 'https://cdn-expa.aiesec.org',
  googleApiKey: 'AIzaSyB3gQc-6Er2C-aov50YtfAdOhuHZcA12mQ',
  aiesec: 'https://yop-staging.aiesec.org',
  oauth: {
    url: 'https://auth-staging.aiesec.org/users/sign_in',
    cb: 'https://pop-staging.aiesec.org/auth'
  },
  websocket: 'wss://cable-staging.aiesec.org:28081'
}
