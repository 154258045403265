import { Component, OnInit } from '@angular/core';

import {CookieService} from 'ngx-cookie';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./../navbar.component.scss']
})
export class LoginComponent implements OnInit {

	userAlreadyRegistered:any;

  constructor(private cookieService:CookieService) {

  let isRegistered = this.cookieService.get('isRegistered');

    if(isRegistered && isRegistered == 'true')
      this.userAlreadyRegistered = true;
    else 
      this.userAlreadyRegistered = false;
   }

  ngOnInit() {
  }

}
