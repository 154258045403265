import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { InfoModalService } from './info-modal.service';

@Component({
  selector: 'app-info-modal',
  templateUrl: './info-modal.component.html',
  styleUrls: ['./info-modal.component.scss']
})
export class InfoModalComponent implements OnInit {

  @ViewChild('infoModal', { static: false }) infoModal: ModalDirective;
  showModal: boolean;
  title: string;
  message: string;

  constructor(
    private infoModalService: InfoModalService
  ) { }

  ngOnInit() {
    this.infoModalService.showModal.subscribe(data => {
      if(data) {
        this.showModal = true
        this.title = this.infoModalService.title;
        this.message = this.infoModalService.message;
      } else {
        this.showModal = false;
        this.title = null;
        this.message = null;
      }
    })
  }

  close() {
    this.infoModal.hide()
  }

  onHidden() {
    this.infoModalService.hideErrorModal()
  }

}
