import gql from 'graphql-tag';

const fragments = {
    navOpportunity: gql`
    fragment opportunity on Opportunity {
        created_at
        id
        location
        managers {
            id
            full_name
            profile_photo
        }
        organisation {
            id
            name
        }
        scorecard {
            id
            name
            scorecard_attributes {
                id
                name
            }
        }
        title
        status
        slots {
            id
            title
            status
            openings
            available_openings
            applications_close_date
            start_date
            end_date
        }
        questionnaire {
            id
            name
        }
      }
    `
};

export const GetOpportunity = gql`
    query GetOpportunity($id: ID) {
        getOpportunity(id: $id) {
           ... opportunity
        }
    }
    ${fragments.navOpportunity}
`

export const UpdateOpportunity = gql`
    mutation UpdateOpportunity($id: ID!, $opportunity: OpportunityInput) {
        updateOpportunity(id: $id, opportunity: $opportunity) {
            ... opportunity
        }
    }
    ${fragments.navOpportunity}
`
