import gql from 'graphql-tag';

export const AllOpportunity = gql`
    query AllOpportunity($filters: OpportunityFilter) {
        allOpportunity(filters: $filters) {
            data {
                id
                title
            }
        }
    }
`;


export const OpportunityQuery = gql`
    query GetOpportunity($id: ID) {
        getOpportunity(id: $id) {
            id
            status
        }
    }
`;


export const AddToWatchList = gql`
    mutation CreateWatchlist($potential_candidate: OpportunityPotentialCandidate) {
        createWatchlist(potential_candidate: $potential_candidate) {
            id
            opportunity {
                id
                title
            }
            person {
                id
                first_name
            }
        }
    }
`;

export const RemoveFromWatchlist = gql`
    mutation RemoveFromWatchlist($person_id: ID!, $opportunity_id: Int!) {
        deleteWatchlist(person_id: $person_id, opportunity_id: $opportunity_id) {
            id
            opportunity {
                id
                title
            }
            person {
                id
                first_name
            }
        }
    }
`;
