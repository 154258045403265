import gql from 'graphql-tag';

const fragments = {
    scorecard : gql`
        fragment scorecard on Scorecard {
            id
            name
            scorecard_attributes {
                id
                name
            }
        }
    `
}

export const GetScorecardQuery = gql`
 query ScorecardQuery($id: ID!) {
     scorecardGet(id: $id) {
         ...scorecard
     }
 }
 ${fragments.scorecard}
`

export const UpdateOrganisationScorecardAttribute = gql`
    mutation OrganisationMutationQuery($id: ID!, $name: String) {
        organisationScorecardAttributeUpdate(id: $id, name: $name) {
            id
            name
        }
    }
`

export const UpdateOrganisationScorecardName = gql`
    mutation OrganisationMutationQuery($id: ID!, $name: String) {
        organisationScorecardUpdate(id: $id, name: $name) {
            ...scorecard
        }
    }
    ${fragments.scorecard}
`

export const OrganisationScorecardAttributeCreate = gql`
    mutation OrganisationMutationQuery($scorecard_id: ID!, $organisation_scorecard_attribute: [ ScorecardAttributeInput ]) {
        organisationScorecardAttributeCreate(scorecard_id: $scorecard_id, organisation_scorecard_attribute: $organisation_scorecard_attribute) {
            ...scorecard
        }
    }
    ${fragments.scorecard}
`

export const ScorecardAttributeDeleteMutation  = gql`
mutation organisationScorecardAttributeDelete($id: ID!){
    organisationScorecardAttributeDelete(id: $id){
         id
    }
}
`
