import {
  BehaviorSubject,
  Observable,
  AsyncSubject
} from 'rxjs';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Resolve, ActivatedRouteSnapshot, CanActivate } from '@angular/router';

interface Dictionary {
  text: string;
  translation: string;
}

@Injectable()
export class TranslationService implements Resolve<any>, CanActivate {
  loading: AsyncSubject<boolean> = new AsyncSubject();

  currentLanguage: string;
  dictionary: BehaviorSubject<Array<Dictionary>> = new BehaviorSubject([]);
  cdnUrl = environment.cdnUrl;

  constructor(
    private _cookieService: CookieService,
    private http: HttpClient
  ) {
    // TODO: UNCOMMENT this after transations are integrated and remove const currentLanguage = 'en';
    // const currentLanguage = this._cookieService.get('lang');
    const currentLanguage = 'en';
    if (currentLanguage !== 'en') {
      this.dictionary.next(JSON.parse(window.localStorage.getItem('lang')));
    }
    const version = window.localStorage.getItem('lang_version');

    if (currentLanguage) {
      this.currentLanguage = currentLanguage;
      this.getVersion().subscribe((data: any) => {
        if (+data.translation !== +version) {
          this.getLangJson(currentLanguage).subscribe((newLang: any) => {
            this.dictionary.next(newLang);
            window.localStorage.setItem('lang', JSON.stringify(newLang));
            window.localStorage.setItem('lang_version', data.translation);
            this.setIndex();
          });
        } else {
          this.dictionary.next(JSON.parse(window.localStorage.getItem('lang')));
          this.setIndex();
        }
      });
    } else {
      this.currentLanguage = 'en';
      this.loading.next(true);
      this.loading.complete();
    }
  }

  canActivate() {
    console.log('can active');
    return this.loading;
  }

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    return this.loading;
  }

  getVersion() {
    return this.http.get(`${this.cdnUrl}/versions.json`);
  }

  getLangJson(lang: string) {
    return this.http.get(
      `${this.cdnUrl}/translations/pop/${lang}.json`
    );
  }

  setLanguage(key: string) {
    const obs = Observable.create(observer => {
      this.getLangJson(key).subscribe(
        (res: any) => {
          window.localStorage.setItem('lang', JSON.stringify(res));
          this.setIndex();
          observer.next(res);
          observer.complete();
        },
        error => {
          observer.error(error);
          observer.complete();
        }
      );
    });
    return obs;
  }

  setIndex() {
    this.loading.next(true);
    this.loading.complete();
  }

  translateUserData(userData: any) {
    if (this.currentLanguage !== 'en') {
      userData.profile.backgorunds = this.translateArrays(
        userData.profile.backgrounds,
        'name'
      );
      userData.profile.languages = this.translateArrays(
        userData.profile.languages,
        'name'
      );
      userData.profile.skills = this.translateArrays(
        userData.profile.skills,
        'name'
      );
      return userData;
    }
    return userData;
  }

  translate(text: string) {
    if (typeof text !== 'string') {
      return text;
    }

    const length = this.dictionary.value.length;

    return this.binarySearch(this.dictionary.value, text.toLowerCase(), 0, length) || text;

  }

  binarySearch(arr: Array<Dictionary>, text: string, start: number, end: number) {

    const mid = Math.floor((start + end) / 2);

    if (start > end) {
      return null;
    }

    if (start === end) {
      if (arr[start] && arr[start].text.toLowerCase() === text) {
        return arr[start].translation;
      } else {
        return null;
      }
    }

    if (arr[start] && arr[start].text.toLowerCase() === text) {
      return arr[start].translation;
    }

    if (arr[end] && arr[end].text.toLowerCase() === text) {
      return arr[end].translation;
    }

    if (arr[mid] && arr[mid].text.toLowerCase() === text) {
      return arr[mid].translation;
    }

    if (arr[mid].text.toLowerCase() > text) {
      return this.binarySearch(arr, text, start, mid - 1);
    } else {
      return this.binarySearch(arr, text, mid + 1, end);
    }
  }

  translateArrays(values: any, args: string) {
    if (this.currentLanguage !== 'en') {
      if (values) {
        const translatedValues = [];
        for (const item of values) {
          const temp = this.translate(item[args]);
          item[args] = temp;
          translatedValues.push(item);
        }
        return translatedValues;
      }
    } else {
      return values;
    }
  }

}
