
import {map} from 'rxjs/operators';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Opportunity } from 'app/services/opportunity.service';
import { AllOpportunity } from './opportunity-dropdown.graphql';
import { Subscription } from 'rxjs';
import { Apollo } from 'apollo-angular';
import { ErrorService } from '../../services/error.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-opportunity-dropdown',
  templateUrl: './opportunity-dropdown.component.html',
  styleUrls: ['./opportunity-dropdown.component.scss']
})
export class OpportunityDropdownComponent implements OnInit {

  @Output() onOpportunitySelect: EventEmitter<boolean> = new EventEmitter(null);
  @Output() onClose: EventEmitter<boolean> = new EventEmitter(null);

  queriedOpportunities: any;
  selectedOpportunity: any;
  newOpportunityName: string;
  opportunitySubscription: Subscription;
  orgId: any;

  constructor(
    private opportunityService: Opportunity,
    private apollo: Apollo,
    private errorService: ErrorService,
    private userService: UserService,
  ) { 
    this.userService.userDataLoading.subscribe(data => {
      if (data) {
        this.orgId = this.userService.currentUser.managed_companies[0].id;
        this.fetchOpportunities('');
      }
    })
    
  }

  ngOnInit() {
  }

  fetchOpportunities(query) {

    if (this.opportunitySubscription) {
      this.opportunitySubscription.unsubscribe();
    }

    this.opportunitySubscription = this.apollo.query({
      query: AllOpportunity,
      variables: {
        q: query,
        filters: {
          organisations: [parseInt(this.orgId, 10)]
        }
      }
    }).pipe(
    map(res => res.data))
    .subscribe((oppList: any) => {
      if (oppList.allOpportunity && oppList.allOpportunity.data) {
        this.queriedOpportunities = oppList.allOpportunity.data.map(opp => ({ id: opp.id, value: opp.title }));
      }
    }, err => {
      this.errorService.showErrorModal(err.networkErr);
    })
  }

  selectOpportunity(opportunity) {
    this.selectedOpportunity = opportunity
  }

  done() {
    this.onOpportunitySelect.emit(this.selectedOpportunity)
  }

  close() {
    this.onClose.emit(true)
  }

}
