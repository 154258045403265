import gql from 'graphql-tag';

const fragments = {
    opportunity: gql`
      fragment Opportunity on Opportunity {
        applicants_count
        applications_close_date
        applications_status_count
        created_at
        id
        location
        profile_photo
        status
        title
        view_count
      }
    `,
};

export const GetAllOpportunities = gql`
    query GetAllOpportunities($page: Int, $per_page: Int, $q: String, $filters: OpportunityFilter) {
        allOpportunity(page: $page, per_page: $per_page, q: $q, filters: $filters) {
            paging {
                total_items
                total_pages
                current_page
            }
            data {
                ... Opportunity
            }
        }
    }
${fragments.opportunity}
`

export const GetMyOpportunities = gql`
    query GetMyOpportunities($page: Int, $per_page: Int, $q: String, $filters: OpportunityFilter) {
        myOpportunities(page: $page, per_page: $per_page, q: $q, filters: $filters) {
            paging {
                total_items
                total_pages
                current_page
            }
            data {
                ... Opportunity
            }
        }
    }
${fragments.opportunity}
`

export const CurrentPersonQuery = gql`
    query CurrentPersonQuery {
        currentPerson{
            id
            managed_branches {
                id
            }
            managed_companies{
                id
            }
        }
    }
`;

export const OrganisationStatsQuery = gql`
    query getOrganisation($id: ID!, $start_date: DateTime!, $end_date: DateTime!) {
        getOrganisation(id: $id) {
            id
            stats(start_date: $start_date, end_date: $end_date)
        }
    }
`;
