import { NgModule } from '@angular/core';
import { SearchInputDirective } from './searchInput.directive';

@NgModule({
    declarations: [
        SearchInputDirective,
    ],
    exports: [
        SearchInputDirective,
     ]
})
export class SearchInputModule {}
