import gql from 'graphql-tag';

const fragments = {
    question: gql`
        fragment question on Question {
            id
            is_mandatory
            parent {
              id
            }
            position
            question_text
            question_type
            sub_questions {
              id
            }
        }
    `,
    questions: gql`
        fragment questions on Question {
            id
            is_mandatory
            question_text
            question_type
            position
            sub_questions {
                id
                is_mandatory
                question_text
                question_type
                parent {
                    id
                }
            }
        }
    `,
    questionOption: gql`
        fragment questionOption on Question {
          id
          question_text
          question_type
          is_mandatory
          parent {
            id
          }
        }
    `
}

// Mutations

export const QuestionnaireCreateMutation = gql`
mutation createQuestionnaire($company_id: ID!, $questionnaire: QuestionnaireInput) {
    createQuestionnaire(company_id: $company_id, questionnaire: $questionnaire) {
      id
      name
      permissions {
        can_delete
      }
      questions {
        id
      }
      opportunities {
        id
        title
      }
    }
  }
`;

export const DuplicateQuestionnaireMutation = gql`
mutation duplicateQuestionnaire($id: ID!) {
  duplicateQuestionnaire(id: $id) {
    id
    name
    permissions {
      can_delete
    }
    questions {
        ... questions
    }
    opportunities {
      id
      title
    }
  }
}
${fragments.questions}
`

export const AddQuestionMutation = gql`
mutation questionBulkCreate($questionnaire_id: ID, $questions: [ QuestionInput ]) {
    questionBulkCreate(questionnaire_id: $questionnaire_id, questions: $questions) {
        ... question
    }
}
${fragments.question}
`;

export const AddQuestionOptionMutation = gql`
mutation questionBulkCreate($questions: [ QuestionInput ]) {
    questionBulkCreate(questions: $questions) {
        ... question
    }
}
${fragments.question}
`

export const RemoveQuestionMutation = gql`
mutation removeQuestion($id: ID!) {
    removeQuestion(id: $id) {
        id
    }
}
`

export const UpdateQuestionMutation = gql`
mutation updateQuestion($id: ID!, $question: QuestionInput) {
  updateQuestion(id: $id, question: $question) {
    ... questions
  }
}
${fragments.questions}
`

export const questionOptionBulkCreateMutation = gql`
mutation questionBulkCreate($questions: [ QuestionInput ]) {
  questionBulkCreate(questions: $questions) {
    ... questionOption
  }
}
${fragments.questionOption}
`

export const questionOptionBulkUpdateMutation = gql`
mutation questionBulkUpdate($questions: [ UpdateQuestionInput ]) {
  questionBulkUpdate(questions: $questions) {
    ... questionOption
  }
}
${fragments.questionOption}
`

export const questionOptionBulkDeleteMutation = gql`
mutation questionBulkDelete($question_ids: [ QuestionInput ]) {
  questionBulkDelete(questions: $questions) {
    id
  }
}
${fragments.questionOption}
`

export const QuestionnaireDeleteMutation = gql`
mutation deleteQuestionnaire($id: ID!) {
  deleteQuestionnaire(id: $id) {
    id
  }
}
`

export const QuestionnaireUpdateMutation = gql`
mutation updateQuestionnaire($id: ID!, $questionnaire: QuestionnaireInput) {
  updateQuestionnaire(id: $id, questionnaire: $questionnaire) {
    id
    name
  }
}
`

// Queries
export const GetQuestionnaireNamesQuery = gql`
query getOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      questionnaires {
		edges {
          node {
            id
            name
          }
        }
      }
    }
}
`;


export const GetQuestionnairesQuery = gql`
query getOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      questionnaires {
		    edges {
          node {
            id
            name
            permissions {
              can_delete
            }
            questions {
                ... questions
            }
            opportunities {
              id
              title
            }
          }
        }
      }
    }
}
${fragments.questions}
`;

export const AssignQuestionnaire = gql`
  mutation assignQuestionnaire($id: ID!, $questionnaire_id: ID!) {
    assignQuestionnaire(id: $id, questionnaire_id: $questionnaire_id) {
      id
      questionnaire {
        id
        name
        permissions {
          can_delete
        }
        questions {
            ... questions
        }
        opportunities {
          id
          title
        }
      }
    }
  }
  ${fragments.questions}
`;

export const ScorecardDeleteMutation  = gql`
mutation organisationScorecardDelete($id: ID!){
  organisationScorecardDelete(id: $id){
       id
     }
 }
`
