
import {takeWhile} from 'rxjs/operators';
import { CK_EDITOR_CONFIG } from 'app/constants';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ConfirmationService } from 'app/services/confirmation.service';
import { ApplicationsService } from 'app/services/applications.service';
import { AppApolloService } from 'app/services/app.apollo.service';
import { map } from 'rxjs/operators';
import { GetCommentsQuery, CreateCommentMutation } from './comments.graphql';
import { Apollo } from 'apollo-angular';
@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit, OnDestroy {

  applicationId: any;
  ckEditorConfig = CK_EDITOR_CONFIG;
  loading: boolean = false;
  commentsLoading: boolean = false;
  showCommentSection: boolean = false;
  showAlert: boolean = false;
  comments: any;
  commentText: string = "";
  componentAlive = true;

  constructor(
     private confirmationService: ConfirmationService,
     private applicationsService: ApplicationsService,
     private apollo: Apollo,
     private appApollo: AppApolloService) {
    this.ckEditorConfig['removeButtons'] = this.ckEditorConfig['removeButtons'].concat(',Strike'); //remove strike button for comments.
  }

  ngOnInit() {
    this.loadCommentSection();
  }

  openCommentSection() {
    this.showCommentSection = true;
  }

  loadCommentSection() {
    this.commentsLoading = true;
    this.applicationsService.application_id.pipe(
    takeWhile( () => this.componentAlive))
    .subscribe((id) => {
      if (id) {
        this.applicationId = id;
        this.appApollo.query(GetCommentsQuery, {id: this.applicationId})
        .then((res: any) => {
          const comments = res.getApplication.comments.edges;
          this.comments = comments;
          this.commentsLoading = false;
        })
      }
    });
  }

  addComment() {
    if (this.commentText) {
      this.loading = true;
      const variables = {
        comment: {
          record_id: +this.applicationId,
          model_name: 'opportunity_application',
          comment: this.commentText
        }
      }

      this.apollo.mutate({
        mutation: CreateCommentMutation,
        variables: variables,
        update: (store, {data: response}) => {
          const data: any = store.readQuery({query: GetCommentsQuery, variables: {id: this.applicationId}});
          data['getApplication'].comments.edges.push(
            {
              node: response.createComment,
              __typename: 'CommentEdge'
            }
          );
          store.writeQuery({ query: GetCommentsQuery, variables: {id: this.applicationId}, data });
        }
      })
      .pipe(
        map((res: any) => res.data)
      )
      .subscribe((res) => {
        this.loading = false;
        this.showCommentSection = false;
      });
    }
  }

  cancel() {

    const title = `Are you Sure?`;
    const details = `You have entered a comment on this application. If you navigate away from this page without first saving, the comment will be lost.`

    this.confirmationService.showConfirmationModal(title, details)

    let isAlive = true
    this.confirmationService.isConfirmed.pipe(
      takeWhile(() => isAlive))
      .subscribe(isConfirm => {
        if (isConfirm) {
          isAlive = false
          this.abortComment(true)
      } else if (isConfirm === false) {
          isAlive = false
          this.abortComment(false)
      }
      })
  }

  abortComment(value) {
    if (!value) {
      this.showAlert = false;
      // this.commentText = ""
    } else {
      this.commentText = "";
      this.showAlert = false;
      this.showCommentSection = false;
    }
  }

  ngOnDestroy() {
    this.componentAlive = false;
  }

}
