import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'app/services/user.service';

@Injectable()
export class OnboardingGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return Observable.create(observer => {
        this.userService.userDataLoading.subscribe(data => {
          if(data) {
            const url = window.location.href
            const user = this.userService.currentUser
            if(user && user.managed_companies.length === 0) {
                observer.next(true)
            } else {
                if(url.indexOf('onboarding/success') > -1) {
                  return
                } else {
                  observer.next(this.router.navigateByUrl('/'));
                }
                  
            }
          } else if(data === false){
            observer.next(this.router.navigateByUrl('/'));
          }
        }, err => {
          observer.next(this.router.navigateByUrl('/'));
        })
      })
  }
}
