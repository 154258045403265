import gql from 'graphql-tag';

export const MCQuery = gql`
query committeesListBasic($per_page: Int, $filters: OfficeFilter) {
  committeesListBasic(per_page: $per_page, filters: $filters) {
    data {
      id
      name
      sub_office_count
    }
  }
}
`;

export const AllConstantsQuery = gql`
  query allConstants($parent_id: ID, $type_id: String, $type_ids: [String]) {
    allConstants(parent_id: $parent_id, type_id: $type_id, type_ids: $type_ids) {
      id
      name
      meta
      parent_id
      position
      type_name
      option
    }
  }
`;
