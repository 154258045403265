
import {map, takeWhile} from 'rxjs/operators';
import { ErrorService } from 'app/services/error.service';
import { GenerateCv } from './../../../../graphql/queries';
import { AppApolloService } from './../../../../services/app.apollo.service';
import { Component, EventEmitter, OnInit, Output, ViewChild, ViewEncapsulation, OnDestroy } from "@angular/core";
import { UserService } from "app/services/user.service";
import { flagName, getLevelColor } from 'app/modular.functions';
import * as moment from "moment";
import { GetOpApplication, CreateWatchlist, CreateScorecardsApplication, UpdateScorecardAttribute } from './op-ap.graphql';
import { getAppStatusClass } from 'app/constants.functions';
import { ApplicationsService } from '../../../../services/applications.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { GetOpportunity } from '../kanban/kanban.graphql';

@Component({
    selector: 'app-opportunity-application',
    templateUrl: './opportunity-application.component.html',
    styleUrls: ['./opportunity-application.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OpportunityApplicationComponent implements OnInit, OnDestroy {
    @ViewChild('applicantModal', { static: false }) applicantModal;
    @Output() close: EventEmitter<boolean> = new EventEmitter(null);
    flagName = flagName;
    panelName: any;

    // Object varibles
    opportunity: any;
    applicant = new Object();
    applicationId: any;
    applicationDetails: any;
    interviewDetails = new Object();
    currentUserScorecard = new Object();
    newRating = new Object();
    errors = new Object();

    // Other variables
    loading: boolean;
    showOpportunityDropdownModal = false;
    panelMode = 'Scorecard';
    openInterviewStep = 1;
    toggleModeActive = [true];
    showInterviewModal: boolean;
    interviewScheduleMsg: string;
    scorecardLoading: boolean;
    componentAlive = true;
    attributeUpdating = {};
    getAppStatusClass = getAppStatusClass;
    getLevelColor = getLevelColor;

    public toggleMode = [
        {
            'name': 'Scorecard'
        },
        {
            'name': 'Note'
        },
        {
            'name': 'Questionnaire'
        },
        // {
        //     'name': 'Interview Details'
        // }
    ];

    showModal = true;
    appToggle = {
        previous: null,
        next: null
    }
    totalAppId: number;
    indexOfCurrentAppId: number;
    allStatusesArray: any;
    customStatuses: any;
    statusLoading: boolean;
    movingForwardLoading: boolean;
    movingBackwardLoading: boolean;
    showRejectApplicationModal: boolean;

    constructor(private userService: UserService,
        private appApollo: AppApolloService,
        private errorService: ErrorService,
        private applicationsService: ApplicationsService,
        private route: ActivatedRoute,
        private router: Router,
        private apollo: Apollo,
    ) {
      }


    ngOnInit() {

        this.route.params.pipe(
        takeWhile(() => this.componentAlive))
        .subscribe(params => {
            if (params.id) {
                this.applicationsService.application_id.next(params.id);
                this.getApplicationId();
            } else {
                this.getApplicationId();
            }
        });

        this.applicationsService.panelName.pipe(
        takeWhile(() => this.componentAlive))
        .subscribe(res => {
            if (res) {
                this.panelName = res;
                this.panelMode = this.panelName
                this.toggleModeActive[4] = true;
                this.toggleModeActive[0] = false;
            }
        });

        this.applicationsService.statusChanged.pipe(
        takeWhile(() => this.componentAlive))
        .subscribe(res => {
            if (res) {
                this.statusLoading = false;
                this.movingForwardLoading = false;
                this.movingBackwardLoading = false;
            }
        });
        this.watchToggleArray();
    }

    watchToggleArray() {
        this.applicationsService.toggleArrayUpdated
        .subscribe((value) => {
            if (value) {
                this.loadApplication();
            }
        })
    }

    getApplicationId() {
        this.applicationsService.application_id.pipe(
        takeWhile( () => this.componentAlive))
        .subscribe(res => {
            this.applicationId = res;
            this.loadApplication();
            this.getAllStatusesArray();
        })
    }

    loadApplication() {
        this.loading = true;

        const variables = {
            id: this.applicationId
        }
        this.appApollo.watchQuery(GetOpApplication, variables).pipe(
            takeWhile(() => this.componentAlive))
            .subscribe((data: any) => {
                this.loading = false;
                const applicationDetails = JSON.parse(JSON.stringify(data.getApplication));
                this.applicationDetails = applicationDetails;
                this.getCustomStatuses(this.applicationDetails.opportunity.id);
                this.opportunity = this.applicationDetails.opportunity;
                this.currentUserScorecard = this.getCurrentUserScorecard(this.applicationDetails);
                this.setInterviewDetails();
                this.setToggleAppData();
            }, err => {
                this.errorService.showErrorModal(err.networkErr);
            });
    }

    getCurrentUserScorecard(applicationDetails) {
        if (applicationDetails && applicationDetails.application_scorecard && applicationDetails.application_scorecard.person) {
            return applicationDetails.application_scorecard.person.id === +this.userService.currentUser.id;
        }
    }

    ngOnDestroy() {
        this.componentAlive = false;
    }

    closeModal() {
        this.appToggle = null;
        this.applicationsService.appToggleArray = {
            applicationIds: [],
            totalItems: null,
            openedFrom: ''
        };
        let currentUrl = this.router.url;
        var myPattern = new RegExp('(\\w*'+'opportunity'+'\\w*)','gi');
        var matches = currentUrl.match(myPattern);
        if(matches) {
            this.showModal = false;
            this.applicantModal.hide();
            let newArray = currentUrl.split("applications");
            currentUrl = newArray[0] + "applications";
            this.router.navigateByUrl(`${currentUrl}`);
        }
        else {
            this.applicantModal.hide();
            this.showModal = false;
        }
    }

    getApplicationStatus(status) {
        switch (status) {
            case 'open':
                return 'Applied'
            case 'matched':
                return 'Selected'
            case 'accepted':
                return 'Accepted'
            case 'approved_ep_manager':
                return 'Accepted'
            case 'approved_tn_manager':
                return 'Accepted'
            case 'approved':
                return 'Approved'
            case 'remote_realized':
            case 'realized':
            case 'completed':
            case 'finished':
                return 'Hired'
            case 'rejected':
                return 'Rejected'
            case 'withdrawn':
                return 'Withdrawn'
            case 'approval_broken':
                return 'Withdrawn'
            case 'realization_broken':
            case 'remote_realization_broken':
                return 'Withdrawn'
            case 'declined':
                return 'Withdrawn'
            default:
                return ''
        }
    }

    saveRating() {
        if (this.applicationDetails.opportunity.scorecard.scorecard_attributes.length !== Object.keys(this.newRating).length) {
            this.errors['ratingsError'] = "All points must be filled";
            return;
        }

        const RatingObject = function (id, score) {
            this.scorecard_attribute_id = id;
            this.score = score
        }

        let ratings = []
        for (let key in this.newRating) {
            let object = new RatingObject(key, this.newRating[key])
            ratings.push(object)
        }

        this.scorecardLoading = true

        for(let i=0; i<ratings.length; i++) {
            ratings[i].scorecard_attribute_id = parseInt(ratings[i].scorecard_attribute_id);
        }

        const variables = {
            id: this.applicationId,
            application_scorecard: ratings
        }
        this.appApollo.mutate(CreateScorecardsApplication, variables)
        .then((data: any) => {
            const applicationDetails = JSON.parse(JSON.stringify(data.createScorecardsApplication));
            this.applicationDetails = applicationDetails;
            this.currentUserScorecard = this.getCurrentUserScorecard(this.applicationDetails);
            this.scorecardLoading = false;
        }, err => {
            this.scorecardLoading = false;
            this.errorService.showErrorModal(err.networkErr);
        })

    }

    updateRating(attributeId, score) {
        this.attributeUpdating[attributeId] = true;
        const variables = {
            id: +attributeId,
            application_scorecard: {
                score: score
            }
        }

        this.apollo.mutate({
            mutation: UpdateScorecardAttribute,
            variables: variables,
            update: (store, {data: response}) => {
                const data: any = store.readQuery({query: GetOpApplication, variables: {id: this.applicationId}});
                if (+data['getApplication'].id === +this.applicationId) {
                    data['getApplication'].average_score = response['updateApplicationScorecardAttribute'].average_score;
                }
                store.writeQuery({ query: GetOpApplication, variables: {id: this.applicationId}, data });
            }
        })
        .subscribe((response: any) => {
            response = response.data;
            const applicationDetails = JSON.parse(JSON.stringify(response.updateApplicationScorecardAttribute));
            this.applicationDetails = applicationDetails;
            this.currentUserScorecard = this.applicationDetails.application_scorecard.person.id === this.userService.currentUser.id;
            this.attributeUpdating[attributeId] = false;
        }, err => {
            this.attributeUpdating[attributeId] = false;
            this.errorService.showErrorModal(err.networkErr);
        })
        }


    addToWatchlist(opportunity) {

        const variables = {
            potential_candidate: {
                person_id: parseInt(this.applicationDetails.person.id)
            },
            opportunity_id: parseInt(opportunity.id)
        }
        this.appApollo.mutate(CreateWatchlist, variables)
        .then((data: any) => {
            this.showOpportunityDropdownModal = false
        }, err => {
            this.errorService.showErrorModal(err.networkErr);
            this.showOpportunityDropdownModal = false
        })

    }

    getAllStatusesArray() {
        this.applicationsService
        .allStatusesArray.pipe(
        takeWhile(() => this.componentAlive))
        .subscribe(statusesArray  => {
            this.allStatusesArray = statusesArray;
        })
    }

    moveApplication(position) {
        if (position === 'forward') {
            this.movingForwardLoading = true;
            if (!this.checkAheadCondition(this.applicationDetails)) {
                this.movingForwardLoading = false;
                this.errorService.showErrorModal('Error', 'You cannot move this application to the next stage');
                return;
            }
        } else if (position === 'backward') {
            this.movingBackwardLoading = true;
            if (!this.checkBackCondition(this.applicationDetails)) {
                this.movingBackwardLoading = false;
                this.errorService.showErrorModal('Error', 'You cannot move this application back');
                return;
            }
        }
        let fromStatus =
            this.applicationDetails.custom_status ? this.applicationDetails.custom_status.name : this.applicationDetails.status;
        if (fromStatus) {
            this.statusLoading = true;
            fromStatus = fromStatus.toLowerCase();
            this.allStatusesArray.map((status, index) => {
                if (fromStatus === status.value) {
                    const data = {};
                    data['application'] = this.applicationDetails;
                    const  toStatus =
                            position === 'forward' ? this.allStatusesArray[index + 1] : index === 0 ?
                            this.allStatusesArray[index] : this.allStatusesArray[index - 1];
                    data['toStatusName'] = toStatus.value;
                    data['toStatusId'] = toStatus.id ? toStatus.id : null;
                    this.applicationsService.moveApplication.next(data);
                }
            });
        }
    }

    isPropertyMatched(property, id) {
        const item = this.applicationDetails.opportunity[property].find(i => +i.constant_id === +id);
        if (item) {
            return true;
        } else {
            return false;
        }
    }

    isFeatured(option) {
        if (option === 'featured') return true
        else return false
    }

    triggerRejectApplication() {
        //this.rejectApplication.emit(this.applicationDetails);
        //this.applicationsService.reject_application.next(this.applicationDetails);
        this.showRejectApplicationModal = true;
    }

    setInterviewDetails() {
        this.interviewDetails = this.applicationDetails.interview_details;
        if (this.panelName) {
            this.panelMode = this.panelName;
            this.toggleModeActive[4] = true;
            this.toggleModeActive[0] = false;
        }
        this.setInterviewStatusMessage();
    }

    setInterviewStatusMessage() {
        if (this.applicationDetails.status == 'open' || this.applicationDetails.status == 'matched') {
            if (this.applicationDetails.interview_details && this.applicationDetails.interview_details.type == "offline") {
                this.interviewScheduleMsg = "Offline Questionnaire is sent and is due on " + moment(this.applicationDetails.interview_details.deadline).format("Do MMM, YYYY");
            }

            else if (this.applicationDetails.interview_details && this.applicationDetails.interview_details.type == "online" && this.applicationDetails.interview_details.aiesec_to_conduct_interview === true) {
                this.interviewScheduleMsg = "AIESEC is conducting the interview on your behalf";
            }

            else if (this.applicationDetails.interview_details && this.applicationDetails.interview_details.type == "online" && this.applicationDetails.interview_details.aiesec_to_conduct_interview === false) {
                const interviewSlots = this.applicationDetails.interview_slots;
                this.interviewScheduleMsg = "Timeslots have been sent to the candidate. Waiting for their response";
                for (let i = 0; i < interviewSlots.length; i++) {
                    if (interviewSlots[i].accepted == true) {
                        this.interviewScheduleMsg = "Candidate has chosen the selected timeslot " + moment(interviewSlots[i].timeslot).format("Do MMM, YYYY");
                        return;
                    }
                }
            }
        }
    }

    triggerOpenAssignManager() {
        //this.showAssignManager.emit(this.applicationDetails);
        this.applicationsService.showAssignManager.next(this.applicationDetails);
    }

    callGenerateCvMethod() {

        const variables = {
          id: this.applicationDetails.person.id
        }
        const cvTab = window.open('', '_blank');
        cvTab.document.write('Loading preview...');
        if (this.applicationDetails && this.applicationDetails.cv) {
            cvTab.location.href = this.applicationDetails.cv.url;
            return;
        }
        this.appApollo.mutate(GenerateCv, variables)
        .then(response => {
          cvTab.location.href = response['generateCv']['cv_url'];
        }, err => {
          this.errorService.showErrorModal(err.networkError);
        })
    }

    setToggleAppData() {
        if (!this.applicationsService.appToggleArray.applicationIds ||
            this.applicationsService.appToggleArray.applicationIds.length < 2) {
            this.appToggle = null;
            return;
        }
        this.totalAppId = this.applicationsService.appToggleArray.totalItems;
        this.indexOfCurrentAppId = this.applicationsService.appToggleArray.applicationIds.indexOf(parseInt(this.applicationId, 10));
        if (this.indexOfCurrentAppId < 0) {
            this.appToggle = null;
            return;
        }

        if (this.indexOfCurrentAppId === 0) {
            this.appToggle.previous = null;
        } else {
            this.appToggle.previous = this.applicationsService.appToggleArray.applicationIds[this.indexOfCurrentAppId - 1];
        }

        if (this.indexOfCurrentAppId === this.applicationsService.appToggleArray.applicationIds.length - 1) {
            if (this.applicationsService.appToggleArray.applicationIds.length < this.totalAppId) {
                    const status = this.applicationDetails.custom_status ?
                                this.applicationDetails.custom_status.name : this.applicationDetails.status;
                   this.applicationsService.updateAppToggleArray.next(status);
                   return;
            }
            this.appToggle.next = null;
        } else {
            this.appToggle.next = this.applicationsService.appToggleArray.applicationIds[this.indexOfCurrentAppId + 1];
        }
    }

    openAppModal(appId) {
        this.router.navigateByUrl(`opportunity/${this.opportunity.id}/applications/${appId}`);
    }

    checkBackCondition(application) {
        if (application.custom_status) {
            return true;
        }
        return false;
    }

    checkAheadCondition(application) {
        if (application.status === 'open' && !application.custom_status && this.customStatuses.length) {
            return true;
        }
        if (this.customStatuses.length && this.customStatuses.length > application.custom_status.position) {
            return true;
        }
        return false;
    }

    getCustomStatuses(opportunityId) {
        return this.apollo.query({
            query: GetOpportunity,
            variables: {id: opportunityId}
        }).pipe(
        map((data: any) => data.data.getOpportunity.custom_statuses))
        .subscribe(customStatusConnections => {
            this.customStatuses = customStatusConnections.edges.map(e => e.node);
        });
    }

    getDecimalValue(score) {
        if (score) {
            return Number(score).toFixed(1);
        }
        return 'NA'
    }
    
    getBackgroundColor(score) {
        if (score >= 4 && score <= 5 ) {
            return '#4E9100';
        }
        if (score >= 1 && score < 3 ) {
            return '#F85A40';
        }
        return '#FFBC34';
    }

}
