
import {interval as observableInterval} from 'rxjs';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import gql from 'graphql-tag';
import { AppApolloService } from 'app/services/app.apollo.service';


const FeatureCandidateQuery = gql`
  query PeopleQuery {
    featuredCandidates{
      id
      first_name
      home_lc {
        id
        parent {
          id
          name
        }
      }
      profile_photo
      person_profile {
        backgrounds {
          id
          name
        }
        id
      }
    }
  }
`


@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingComponent implements OnInit {


  platformFeatures = [{
    'text': 'Connect with our applicants',
    'icon': './assets/images/1-plateform-feature-blue.svg',
    'image': './assets/images/pop-feature-1.png',
    'isActive': true,
  }, {
    'text': 'Manage your applicants through our customizable shortlisting feature',
    'icon': './assets/images/2-plateform-feature-blue.svg',
    'image': './assets/images/pop-feature-2.png',
    'isActive': false
  }, {
    'text': 'Manage your branches and employees',
    'icon': './assets/images/3-plateform-feature-blue.svg',
    'image': './assets/images/pop-feature-3.png',
    'isActive': false
  }, {
    'text': 'One dashboard including all relevant information you might need',
    'icon': './assets/images/4-plateform-feature-blue.svg',
    'image': './assets/images/pop-feature-4.png',
    'isActive': false
  }, {
    'text': 'Guided user experience through a friendly system flow',
    'icon': './assets/images/5-plateform-feature-blue.svg',
    'image': './assets/images/pop-feature-5.png',
    'isActive': false
  }];
  selectedFeatureIndex = 0;
  selectedImage = JSON.parse(JSON.stringify(this.platformFeatures[0]));
  current_index = 0;
  featuredCandidates: any;
  selectedCandidate: any;
  showCandidateModal: boolean;
  loadingFeaturedCandidate = true;

  constructor(private appApollo: AppApolloService) {
    this.selectedImage.icon = this.selectedImage.icon.replace('blue', 'white');
    this.rotateFeature();
    this.appApollo.query(FeatureCandidateQuery, null)
    .then((res) => {
      this.loadingFeaturedCandidate = false;
      this.featuredCandidates = res['featuredCandidates'];
      if (this.featuredCandidates.length > 4) {
        this.featuredCandidates = this.featuredCandidates.slice(0, 5);
      }
    })
  }

  ngOnInit() {
  }

  rotateFeature() {
    observableInterval(5000).subscribe(() => {
      if (this.current_index === 4) {
        this.current_index = 0;
      } else {
        this.current_index++;
      }
     this.onFeatureClick(this.current_index);
    });
  }

  onFeatureClick(index) {
    this.platformFeatures[this.selectedFeatureIndex].isActive = false;
    this.selectedFeatureIndex = index;
    this.platformFeatures[this.selectedFeatureIndex].isActive = true;
    this.selectedImage = JSON.parse(JSON.stringify(this.platformFeatures[this.selectedFeatureIndex]));
    this.selectedImage.icon = this.selectedImage.icon.replace('blue', 'white');
    this.current_index = index;
  }

  openCandidateModal(candidate) {
    this.selectedCandidate = candidate;
    this.showCandidateModal = true;
  }

  closeCandidateModal(value) {
    if (!value) {
      this.selectedCandidate = null;
      this.showCandidateModal = false;
    }
  }

  openCrisp() {
    (<any>window).$crisp.push(['do', 'chat:open']);
  }

}

