import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared.module';
import { LandingComponent } from './landing.component';
import { VideoComponent } from './video/video.component';
import { SelectModule } from 'ng2-select';
import { TooltipModule } from 'ngx-bootstrap';
import {Ng2PageScrollModule} from 'ng2-page-scroll';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SelectModule,
    TooltipModule.forRoot(),
    Ng2PageScrollModule.forRoot()
  ],
  declarations: [LandingComponent, VideoComponent],
  exports: [
    LandingComponent,
    TooltipModule
  ]
})
export class LandingModule { }
