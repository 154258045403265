import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'app/services/user.service';

@Injectable()
export class ApprovalPendingGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return Observable.create(observer => {
        this.userService.userDataLoading.subscribe(data => {
          if (data) {
            const user = this.userService.currentUser
            if (!user) {
              observer.next(this.router.navigateByUrl('/'));
            }
            if (user.managed_companies.length === 0 || user.managed_branches.length === 0) {
                observer.next(this.router.navigateByUrl('/onboarding'));
            } else {
                if (this.userService.atLeastOneApprovedBranch()) {
                  observer.next(this.router.navigateByUrl('/'));
                } else {
                  observer.next(true);
                }
            }
          } else if (data === false) {
            observer.next(this.router.navigateByUrl('/'));
          }
        }, err => {
          observer.next(this.router.navigateByUrl('/'));
        })
      })
  }
}
