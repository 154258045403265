import gql from 'graphql-tag';

const fragments = {
    comment: gql`
      fragment comment on Comment {
        id
        comment
        created_at
        person {
          id
          full_name
        }
      }`
  };

export const GetCommentsQuery = gql`
    query getApplication($id: ID) {
        getApplication(id: $id) {
        id
        comments {
            edges {
            node {
                ... comment
            }
            }
        }
        }
    } ${fragments.comment}
`;

export const CreateCommentMutation = gql`
    mutation createComment($comment: CommentInput) {
    createComment(comment: $comment) {
        ... comment
    }
    } ${fragments.comment}
`;
