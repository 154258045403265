import { Directive, Input, Output, EventEmitter, ElementRef } from '@angular/core';

@Directive({
    selector: '[googlePlace]'
})

export class GooglePlaceDirective {

    @Input() types = 'cities';
    @Output() place = new EventEmitter();

    constructor(
        private _eleRef: ElementRef
    ) {
        this.setElement();
    }

    setElement() {
        const input = this._eleRef.nativeElement;
        const options = {
            types: [`(${this.types})`]
        };

        const autocomplete = new google.maps.places.Autocomplete(input, options);

        google.maps.event.addListener(autocomplete, 'place_changed', () => {
            this.place.emit(autocomplete.getPlace());
        })

    }

}
