import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class InfoModalService {

  showModal = new BehaviorSubject(null);
  title: string;
  message: string;

  constructor() {
  }

  showInfoModal(title, message) {
    this.title = title;
    this.message = message;
    this.showModal.next(true)
  }

  hideErrorModal() {
    this.title = null;
    this.message = null;
    this.showModal.next(false)
  }

}