import {
    AddQuestionnaireComponent
} from './content/organisation/process-management/questionnaires/add-questionnaire/add-questionnaire.component';
import { EditQuestionComponent } from './blocks/edit-question/edit-question.component';
import { AddQuestionComponent } from './blocks/add-question/add-question.component';
import { AddScorecardComponent } from './blocks/add-scorecard/add-scorecard.component';
import { EditScorecardComponent } from './blocks/edit-scorecard/edit-scorecard.component';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { DropdownComponent } from './blocks/dropdown/dropdown.component';
import { VimeoVideoComponent } from './blocks/vimeo-video/vimeo-video.component';
import { CarouselComponent } from './blocks/carousel/carousel.component'
import { Slide } from './blocks/carousel/slide.component';
import { CarouselSetting } from './blocks/carousel/carousel-setting.component';
import { CountoModule } from 'app/directives/counto/counto.module';

import { AutocompleteDropdownComponent } from './blocks/autocomplete-dropdown/autocomplete-dropdown.component';
import { InlineEditComponent } from './blocks/inline-edit/inline-edit.component';
import { GeneralPartnersExpandedComponent } from './blocks/general-partners-expanded/general-partners-expanded.component';
import { GeneralPartnersComponent } from './blocks/general-partners/general-partners.component';
import { CarouselModule, TooltipModule, ModalModule, BsDatepickerModule } from 'ngx-bootstrap';
import { LoginComponent } from './blocks/navbar/login/login.component';
import { ListComponent } from "./blocks/list/list.component";
import { CardComponent } from "./blocks/card/card.component";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { SearchByPropertyPipe } from './pipes/search-by-property.pipe';
import { ValidationComponent } from "./validation-component/validation-component.component";
import { ErrorModalComponent } from './blocks/error-modal/error-modal.component';
import { SurveyModalComponent } from './blocks/survey-modal/survey-modal.component';
import { CandidateProfileComponent } from './blocks/candidate-profile/candidate-profile.component';
import { ScorecardModalComponent } from './blocks/scorecard-modal/scorecard-modal.component';
import { ConfirmationModalComponent } from './blocks/confirmation-modal/confirmation-modal.component';
import { OpportunityNavbarComponent } from './blocks/opportunity-navbar/opportunity-navbar.component';
import {AssignManagersComponent} from "./content/opportunity/applications/assign-managers/assign-managers.component";
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { GetAgePipe } from 'app/pipes/get-age.pipe';
import { Date2Pipe } from './pipes/date2.pipe';
import { SaveFilterComponent } from './blocks/save-filter/save-filter.component';
import { SearchInputModule } from './directives/searchInput/searchInput.module';
import { GooglePlaceModule } from './directives/googlePlace/googlePlace.module';
import { FilterFieldPipe } from './pipes/filterField.pipe';
import { ClickOutsideModule } from 'ng-click-outside';
import { OpStatusTextPipe } from './pipes/op-status-text.pipe';
import { RelativeDatePipe } from './pipes/relative-date.pipe';
import { TitleizePipe } from './pipes/titleize.pipe';
import { AssignQuestionnaireComponent } from './blocks/assign-questionnaire/assign-questionnaire.component';
import { DragulaModule } from 'ng2-dragula';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TranslateModule } from './pipes/translate.module';
import { CmFloatingInfoModule } from './blocks/cm-floating-info/cm-floating-info.module';
import { DisableControlModule } from './directives/disableControl/disableControl.module';
import { ConfirmationDialogComponent } from './blocks/confirmation-dialog/confirmation-dialog.component';
import { Convert24hTo12hFormatPipe } from './pipes/convert24h-to12h-format.pipe';
import { MaintenanceBannerComponent } from 'app/blocks/maintenance-banner/maintenance-banner.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        TooltipModule.forRoot(),
        CarouselModule.forRoot(),
        ModalModule,
        InfiniteScrollModule,
        SearchInputModule,
        GooglePlaceModule,
        ClickOutsideModule,
        DragulaModule,
        TranslateModule,
        CmFloatingInfoModule,
        BsDatepickerModule
    ],
    declarations: [
        InlineEditComponent,
        GeneralPartnersExpandedComponent,
        VimeoVideoComponent,
        CarouselComponent,
        Slide,
        Date2Pipe,
        CarouselSetting,
        DropdownComponent,
        AutocompleteDropdownComponent,
        GeneralPartnersComponent,
        LoginComponent,
        ListComponent,
        CardComponent,
        ValidationComponent,
        SearchByPropertyPipe,
        FilterFieldPipe,
        ErrorModalComponent,
        SurveyModalComponent,
        CandidateProfileComponent,
        AssignManagersComponent,
        ScorecardModalComponent,
        ConfirmationModalComponent,
        OpportunityNavbarComponent,
        TermsConditionsComponent,
        GetAgePipe,
        SaveFilterComponent,
        OpStatusTextPipe,
        RelativeDatePipe,
        TitleizePipe,
        AssignQuestionnaireComponent,
        AddQuestionnaireComponent,
        AddQuestionComponent,
        EditQuestionComponent,
        AddScorecardComponent,
        EditScorecardComponent,
        SafeHtmlPipe,
        ConfirmationDialogComponent,
        Convert24hTo12hFormatPipe,
        MaintenanceBannerComponent
    ],
    providers: [],
    exports: [
        CommonModule,
        InlineEditComponent,
        ErrorModalComponent,
        SurveyModalComponent,
        DropdownComponent,
        AutocompleteDropdownComponent,
        GeneralPartnersExpandedComponent,
        VimeoVideoComponent,
        CarouselComponent,
        Slide, CarouselSetting,
        CountoModule,
        DisableControlModule,
        ListComponent,
        CardComponent,
        TooltipModule,
        InfiniteScrollModule,
        ModalModule,
        GooglePlaceModule,
        ValidationComponent,
        GeneralPartnersComponent,
        LoginComponent,
        CandidateProfileComponent,
        AssignManagersComponent,
        ScorecardModalComponent,
        ConfirmationModalComponent,
        OpportunityNavbarComponent,
        TermsConditionsComponent,
        GetAgePipe,
        Date2Pipe,
        FilterFieldPipe,
        SaveFilterComponent,
        ClickOutsideModule,
        OpStatusTextPipe,
        RelativeDatePipe,
        SearchInputModule,
        TitleizePipe,
        AssignQuestionnaireComponent,
        AddQuestionnaireComponent,
        AddQuestionComponent,
        EditQuestionComponent,
        AddScorecardComponent,
        EditScorecardComponent,
        DragulaModule,
        SafeHtmlPipe,
        TranslateModule,
        BsDatepickerModule,
        ConfirmationDialogComponent,
        Convert24hTo12hFormatPipe,
        MaintenanceBannerComponent
    ]
})

export class SharedModule {
}
