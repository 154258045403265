import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService} from '../../services/user.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements OnInit {
 
  public content: string = 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.';
  @Output() favouriteEvent: EventEmitter<any> = new EventEmitter();
  sessionPresent: boolean = false;
  error: boolean = false;
  errorDetails:any;
  disabled: boolean = false;
  currentLanguage: string;
  company: string;
  sdg: string;
  @Input() opportunitiesArray: any;
  @Input() infiniteScroll: boolean;
  @Input() data: any;

  //The time to show the next photo
   NextPhotoInterval:number = 500000;
  //Looping or not
   noLoopSlides:boolean = false;
  //Photos
  public slides:Array<any> = [];

  constructor(public userService: UserService) { }

   addNewSlide() {
    for(let op of this.opportunitiesArray){
      if(op.office != null){
        this.company = op.branch.name
      }
      else{
        this.company = ''
      }
      if(op.sdg_info != null && op.sdg_info.sdg_target != null){
        this.sdg = op.sdg_info!.sdg_target!.goal_index
      }
      else {
        this.sdg = ''
      }
      this.slides.push(
        {id: op.id, image: op.cover_photo_urls, title: op.title, duration: op.duration, location: op.location,
          company: this.company, date:op.earliest_start_date, type: op.programme.short_name_display, logo: op.profile_photo_urls.thumb,sdg: this.sdg}
      )
    }

  }  

  ngOnInit() {
  	  this.userService.userPresent.subscribe(data => {
      if(data){
        this.sessionPresent = true;
      }
    }, err=> {
      // this.errorService.error.next(true);
      // this.errorService.errorDetails.next(err);
      this.disabled = false;
    });
    this.addNewSlide();
  }

}
