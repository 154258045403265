import { ModelService } from './../../services/model.service';
import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-general-partners',
  templateUrl: './general-partners.component.html',
  styleUrls: ['./general-partners.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class GeneralPartnersComponent implements OnInit {

  organisationsData: any;
  error: boolean = false;
  errorDetails:any;
  aiesecUrl:any;


  constructor(private modelService: ModelService, private parentRouter: Router) { }

  ngOnInit() {
    this.aiesecUrl = environment.aiesec;
   // this.organisationsData = this.storage.retrieve('organisations');
    if(!this.organisationsData || this.daydiff(this.organisationsData['date'], Date.now()) > 30){
      this.modelService.get({name: 'organisations', qs: '&filters[is_gep]=true&per_page=50&sort=-openings'}).subscribe(organisations => {
        this.organisationsData = {
          data: organisations.data,
          date: Date.now()
        };
       // this.storage.store('organisations', this.organisationsData);

        // var organisationsSet = 0;
        // var groups = [[]];
        // for (var i=0;  i < this.organisationsData.length; i++) {
        //   groups[groups.length - 1].push(this.organisationsData[i]);
        //   ++organisationsSet;
        //   if (organisationsSet >= 5 && i !== (this.organisationsData.length -1)) {
        //     groups.push([]);
        //     organisationsSet = 0;
        //   }
        // }
        // return groups,
        // console.log(groups);
      });
    }
  }

  daydiff(first, second) {
      return Math.round((second - first) / (1000 * 60 * 60 * 24));
  }


  goToOrganisation(id) {
    this.parentRouter.navigateByUrl(`/companies/${id}`);
  }

}  

