import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'getAge'
})
export class GetAgePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    
    if(!value || value === NaN) return null
    
    var date = new Date(value);

    var text;

    var now = moment(new Date())
    var comparisionDate = moment(date)

    var diff = now.diff(comparisionDate, 'days')
    
    if(diff === 1) text = 'a day'
    else text = `${diff} days`
    if(diff > 30) {
      diff = now.diff(comparisionDate, 'months')
      if(diff === 1) text = 'a month'
      else text = `${diff} months`
      if(diff > 12) {
        diff = now.diff(comparisionDate, 'years')
        if(diff === 1) text = 'a year'
        else text = `${diff} years`
      }
    }

    return text;
          
  }

}
