import { Directive, ElementRef, Renderer2, Output, EventEmitter } from '@angular/core';

@Directive({ selector: '[infiniteScroll]' })
export class infiniteScrollDirective {
    @Output() scrollEnd = new EventEmitter();
    constructor(elem: ElementRef, renderer: Renderer2) {
        let div = elem.nativeElement;
        div.addEventListener('scroll', () => {
            if (div.scrollTop >= (div.scrollHeight - div.offsetHeight)) {
                this.scrollEnd.emit(true);
            }
        });
    }
}
