
import {takeWhile,  map } from 'rxjs/operators';
import { ConfirmationService } from 'app/services/confirmation.service';
import { ApplicationsService } from './../../../../services/applications.service';
import { GetRejectionReasons, RejectApplication } from './../opportunity-application/op-ap.graphql';
import { Apollo } from 'apollo-angular';
import { ErrorService } from 'app/services/error.service';
import { GetOpApplication } from './../applications.graphql';
import { AppApolloService } from './../../../../services/app.apollo.service';
import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { RejectApplications } from '../applications-list/applications-list.graphql';

@Component({
  selector: 'app-reject-application',
  templateUrl: './reject-application.component.html',
  styleUrls: ['./reject-application.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class RejectApplicationComponent implements OnInit {

  @ViewChild('rejectApplicationModal', { static: true }) rejectApplicationModal;
  @Output() close: EventEmitter<any> = new EventEmitter(null);
  @Input() applicationId: number;
  @Input() applicationStatus: any;
  @Input() bulkReject = false;
  @Input() isGV: boolean;
  rejectionReasons = [];
  loading = false;
  rejectionLoading = false;
  rejectionReason = {
    id: null,
    index: null
  }
  @Input() bulkRejectApplicationIds: any;

  constructor(
    private appApollo: AppApolloService,
    private apollo: Apollo,
    private errorService: ErrorService,
    private applicationService: ApplicationsService,
    private confirmationService: ConfirmationService) {
  }

  ngOnInit() {
    this.getRejectionReasons();
  }

  getRejectionReasons() {
    this.loading = true;
    this.appApollo.query(GetRejectionReasons, {type_id: 'rejection_reason'})
    .then(response => {
      this.rejectionReasons = response['allConstants'];
      this.filterOutReason('expa_only');
      if (!this.isGV) {
        this.filterOutReason('gv_only');
      }
      this.loading = false;
    }, err => {
      this.errorService.showErrorModal(err.networkError);
      this.loading = false;
    })
  }

  filterOutReason(reasonType) {
    this.rejectionReasons = this.rejectionReasons.filter(reason => reason.meta.tag !== reasonType);
  }

  selectReasonForRejection(rejectionId, index) {
    this.rejectionReason = {
      id: +rejectionId,
      index: index
    }
  }

  rejectCandidate() {
    this.rejectionLoading = true;
    this.apollo.mutate({
      mutation: RejectApplication,
      variables: {
        id: +this.applicationId,
        rejection_reason_id: this.rejectionReason.id
      },
      update: (store, {data: response}) => {
        const data: any = store.readQuery({query: GetOpApplication, variables: {id: this.applicationId}});
        if (+data['getApplication'].id === +this.applicationId) {
          data['getApplication'].status = response['rejectApplication'].status;
          data['getApplication'].current_status = response['rejectApplication'].current_status;
          data['getApplication'].rejection_reason = response['rejectApplication'].rejection_reason;
        }
        store.writeQuery({ query: GetOpApplication, variables: {id: this.applicationId}, data });
      }
    })
    .pipe(map((res: any) => res.data))
    .subscribe(response => {
      const responseObj = response['rejectApplication'];
      responseObj['previous_status'] = this.applicationStatus.custom_status
                                      ? this.applicationStatus.custom_status.name
                                      : this.applicationStatus.status;
      this.applicationService.reject_application.next(responseObj);
      this.dismissModal('close');
      this.rejectionLoading = false;
    },
    err => {
      this.errorService.showErrorModal(err.networkError);
      this.rejectionLoading = false;
    })
  }

  rejectionConfirmation(rejectionType) {
    let text = '';
    if (this.bulkRejectApplicationIds && this.bulkRejectApplicationIds.length > 1) {
      text = `Are you sure you want to reject these candidates for the same reason? 
      Once rejected, these candidates cannot be accepted later.`;
    } else {
      text = `Are you sure you want to reject this candidate? 
      Once rejected, this candidate cannot be accepted later.`;
    }
    const title = `Reject Applications` ;
    const details = text;
    let alive = true;

    this.confirmationService.showConfirmationModal(title, details);

    this.confirmationService.isConfirmed.pipe(
      takeWhile(() => alive))
      .subscribe(isConfirm => {
        if (isConfirm) {
            alive = false;
            if (rejectionType === 'bulkReject') {
              this.bulkRejectApplications();
            } else {
              this.rejectCandidate();
            }
        } else if (isConfirm === false) {
            alive = false;
            return;
        }
      })
  }

  bulkRejectApplications() {
    this.rejectionLoading = true;
    const variables = {
      application_ids: this.bulkRejectApplicationIds,
      rejection_reason_id: this.rejectionReason.id
    }
    this.appApollo.mutate(RejectApplications, variables)
    .then((data: any) => {
      const unrejectedApplications = data.bulkRejectApplications.filter(application => application.status !== 'rejected');
      if (unrejectedApplications && unrejectedApplications.length) {
        const customMessage = `${unrejectedApplications.length} applications could not be rejected,
         mainly because it's not permissible to reject them at this stage`;
        this.errorService.showErrorModal(null, customMessage);
      }
      this.dismissModal('close');
      this.rejectionLoading = false;
    }, err => {
      this.errorService.showErrorModal(err.networkError);
      this.rejectionLoading = false;
    })
  }

  dismissModal(dismissType) {
    this.rejectApplicationModal.hide();
    this.close.emit({dismissType: dismissType});
  }

}
