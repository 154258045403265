import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OpArchiveModalComponent } from './op-archive-modal.component';
import { ModalModule } from 'ngx-bootstrap';
import { TranslateModule } from 'app/pipes/translate.module';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    TranslateModule,
  ],
  declarations: [
    OpArchiveModalComponent,
  ],
  exports: [
    OpArchiveModalComponent
  ]
})
export class OpArchiveModalModule { }
