import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ConfirmationService } from 'app/services/confirmation.service';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  @ViewChild('confirmationModal', { static: false }) confirmationModal: ModalDirective;
  showModal: boolean;
  title: string;
  details: string;

  constructor(private confirmationService: ConfirmationService) {
    this.confirmationService.showModal.subscribe(data => {
      if (data) {
        this.showModal = true;
        this.title = this.confirmationService.confirmationTitle;
        this.details = this.confirmationService.confirmationDetails;
      } else {
        this.showModal = false;
        this.title = null;
        this.details = null;
      }
    })
   }

  ngOnInit() {
  }

  close(isConfirmed) {
    this.confirmationModal.hide()
    this.confirmationService.isConfirmed.next(isConfirmed)
  }

  onHidden() {
    this.confirmationService.hideConfirmationModal()
  }

}
