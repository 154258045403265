
import { throwError as observableThrowError, Observable, AsyncSubject } from 'rxjs';

import { catchError } from 'rxjs/operators';
import { ModelService } from './model.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { COUNTRY_CODES, ALL_CONSTANTS_FILTER } from '../constants';
import { AppApolloService } from './app.apollo.service';
import { MCQuery, AllConstantsQuery } from 'app/graphql/list.queries';

@Injectable()
export class ListService {


    list: {};
    fetchFailed: any;
    token: any;
    isListView = false;
    currentVersion: any;

    constructor(
        private model: ModelService,
        private http: HttpClient,
        private appApollo: AppApolloService
        ) {
        this.getVersion().subscribe((data: any) => {
            this.currentVersion = data.lists
        })
    }

    getData(listName) {

        const data = JSON.parse(window.localStorage.getItem(listName));
        const storedVersion = +window.localStorage.getItem('lists-version')
        return new Promise((resolve, reject) => {
            if (data && storedVersion === this.currentVersion) {
                return resolve(data);
            } else {
                if (listName === 'countryCodes') {
                    return resolve(this.getCountryCodes());
                }
                this.model.get({ name: `lists/${listName}` })
                    .subscribe(listData => {
                        let modified_data;
                        if (listName === 'sdg_goals') {
                            modified_data = listData.map(t => ({ id: t.id, text: t.meta.short_name, goal_index: t.goal_index }));
                        } else if (listName === 'sdg_targets') {
                            modified_data = listData.map(t => ({ id: t.id, target: t.target, parent_id: t.parent_id }));
                        } else if (listName === 'currencies') {
                            modified_data = listData.map(t => ({ id: t.id, text: t.alphabetic_code }));
                        } else {
                            modified_data = listData.map(t => ({ id: t.id, text: t.name }));
                        }
                        this.setListLocal({ name: listName, data: modified_data });
                        return resolve(modified_data);
                    }, err => {
                        return reject(err);
                    });
            }
        });
    }

    getVersion() {
        return this.http.get(`https://s3-eu-west-1.amazonaws.com/cdn.expa.aiesec.org/translations/versions.json`).pipe(
            catchError((error: any) => observableThrowError(error.json() || 'Server error')));
    }

    fetchFromApi(listArray) {

        return new Observable((observer) => {
            const modifies_list_filter = [];
            listArray.map(item => modifies_list_filter.push(ALL_CONSTANTS_FILTER[item]));
            this.appApollo.query(AllConstantsQuery, { type_ids: modifies_list_filter }, 'network-only')
                .then((res: any) => {
                    const list = res.allConstants;

                    const modified_data = {};

                    listArray.map((item) => {
                        if (item === 'sdg_goals') {
                            modified_data[item] = list.filter(constant => constant.type_name === 'sdg_goal');
                            modified_data[item] = modified_data[item]
                                                    .map(t => ({
                                                        id: t.id,
                                                        text: t.meta.short_name,
                                                        position: t.position,
                                                        goal_index: t.goal_index || t.position, type: t.type_name,
                                                        parent_id: t.parent_id
                                                    }));
                        } else {
                            modified_data[item] = list.filter(constant => constant.type_name === ALL_CONSTANTS_FILTER[item]);
                            modified_data[item] = modified_data[item].map(t => ({ id: t.id, text: t.name, type: t.type_name }));
                        }
                        this.setListLocal({ name: item, data: modified_data[item] });
                    });

                    observer.next(true);
                    observer.complete();

                }, err => {
                    this.fetchFailed = err;
                    observer.next(false);
                    observer.error();

                });
        });

    }

    private notFoundInLS(listArray: Array<string>): Array<any> {

        const listData = {};

        const notFound = [];

        const storedVersion = +window.localStorage.getItem('lists-version');


        if (this.currentVersion !== storedVersion) {
            window.localStorage.setItem('lists-version', this.currentVersion);

            for (const item of ['home_mcs', 'mcs', 'nationalities', 'work_types', 'countryCodes',
            'backgrounds', 'languages', 'regions', 'sdg_goals', 'skills', 'study_levls']) {
                window.localStorage.removeItem(item);
            }
            return listArray;
        }


        for (const item of listArray) {
            listData[item] = JSON.parse(window.localStorage.getItem(item));

            if (!listData[item]) {
                notFound.push(item);
            } else if (!listData[item][0] || !listData[item][0].text) {
                window.localStorage.removeItem(item);
                notFound.push(item);
            }
        }

        return notFound;

    }

    getCommiteeList(tag_name, store_name) {
        return new Promise((resolve, reject) => {
            const variables = { per_page: 200, filters: { tag: tag_name } };
            this.appApollo.query(MCQuery, variables)
                .then((res) => {
                    let mcList = res['committeesListBasic']['data'];
                    mcList = mcList.map(t => {
                        return { id: t.id, text: t.name, sub_office_count: t.sub_office_count };
                    });
                    mcList = this.sortList(mcList);
                    resolve(mcList);
                    this.setListLocal({ name: store_name, data: mcList });
                }, err => {
                    reject(err);
                });
        });
    }

    sortList(list) {
        list.sort((a, b) => {
            if (a.text < b.text) { return -1; }
            if (a.text > b.text) { return 1; }
            return 0;
        });
        return list;
    }

    getListArray(listArray) {

        const o = Observable.create((observer) => {

            const listData = {};
            const allDataStoredInLS = new AsyncSubject();

            // checking if all the requested lists are in local storage
            let notFound = this.notFoundInLS(listArray);

            // the ones that were not found are requested from api.
            if (notFound.length) {

                if (notFound.indexOf('countryCodes') !== -1) {
                    listData['countryCodes'] = this.getCountryCodes();
                    notFound.splice(notFound.indexOf('countryCodes'), 1);
                }

                this.fetchFromApi(notFound)
                    .subscribe(data => {
                        if (data) {
                            notFound = [];
                            for (const item of listArray) {

                                listData[item] = JSON.parse(window.localStorage.getItem(item));

                                if (!listData[item]) {
                                    // whatever is not there is made note of
                                    notFound.push(item);
                                }

                            }
                            if (notFound.length === 0) {
                                allDataStoredInLS.next(true);
                                allDataStoredInLS.complete();
                            } else {
                                this.fetchFailed['message'] = 'Sorry, something went wrong.';
                                allDataStoredInLS.next(false);
                                allDataStoredInLS.complete();
                            }

                        }
                    }, error => {
                            allDataStoredInLS.next(false);
                            allDataStoredInLS.complete();
                        });
            } else {
                // all available in ls
                for (const item of listArray) {
                    listData[item] = JSON.parse(window.localStorage.getItem(item));
                }
                allDataStoredInLS.next(true);
                allDataStoredInLS.complete();
            }

            // now we have everything. Time to translate them

            allDataStoredInLS.subscribe(data => {
                // Translation code for future
                if (data) {
                    observer.next(listData);
                    observer.complete();
                } else {
                    if (this.fetchFailed) {
                        observer.error(this.fetchFailed);
                        observer.complete();
                    }

                }

            });

        });

        return o;
    }

    setListLocal(obj) {
        window.localStorage.setItem(obj.name, JSON.stringify(obj.data));
    }

    getCountryCodes() {
        const modified_data = COUNTRY_CODES.map(t => ({ id: t.key, text: t.value }));
        this.setListLocal({ name: 'countryCodes', data: modified_data });
        return modified_data;
    }
}
