import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ErrorService } from 'app/services/error.service';
import { TokenService } from 'app/services/token.service';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {

  @ViewChild('errorModal', { static: false }) errorModal: ModalDirective;
  showModal: boolean;
  error: any;
  customMessage: string;
  customAction: string;

  constructor(
    private errorService: ErrorService,
    private tokenService: TokenService,
    ) {
    this.errorService.showModal.subscribe(data => {
      if(data) {
        this.showModal = true
        this.error = this.errorService.error
        this.customMessage = this.errorService.customMessage
        this.customAction = this.errorService.customAction
      } else {
        this.showModal = false
        this.error = null
        this.customMessage = null
        this.customAction = null
      }
    })
   }

  ngOnInit() {
  }

  close() {
    this.checkInvalidity(this.error);
    this.errorModal.hide();
    this.checkCustomAction();
  }

  checkInvalidity(error) {
    if (!error) {
      return;
    }
    if (error.statusCode === 401 && 
      (error.message === 'Invalid, missing or expired token' || error.customMessage === 'Invalid, missing or expired token')) {
      this.tokenService.getRefreshToken(true);
    }
  }

  checkCustomAction() {
    switch (this.customAction) {
      case 'logout':
        this.logout();
        break;
    }
  }

  logout() {
    window.location.pathname = '/auth/log_out';
  }

  onHidden() {
    this.errorService.hideErrorModal()
  }

}
