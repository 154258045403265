import {Component, OnInit, OnDestroy, Input, HostBinding } from '@angular/core';

import {CarouselSetting, Direction} from  './carousel-setting.component';

@Component({
    selector: 'slide-t',
    template: `
    <div [class.active]="active" [class.slidingLeft]="slidingLeft" [class.slidingRight]="slidingRight"class="item">
      <ng-content></ng-content>
    </div>
  `
})
export class Slide implements OnInit, OnDestroy {
    @Input() public index:number;
    @Input() public direction:Direction;

    @HostBinding('class.active')
    @Input() public active:boolean;

    @HostBinding('class.slidingLeft')
    @Input() public slidingLeft:boolean;

    @HostBinding('class.slidingRight')
    @Input() public slidingRight:boolean;

    @HostBinding('class.item')
    @HostBinding('class.carousel-item')
     addClass:boolean = true;

    constructor(public carousel:CarouselSetting) {
    }

    public ngOnInit() {
        this.carousel.addSlide(this);
    }

    public ngOnDestroy() {
        this.carousel.removeSlide(this);
    }
}
