 
import { NgModule } from '@angular/core';

import { AuthGuard } from 'app/guards/auth.guard';
import { OnboardingGuard } from './content/onboarding/onboarding.guard';
import { OnboardedGuard } from 'app/guards/onboarded.guard';
import { ApprovalPendingGuard } from 'app/guards/approval-pending.guard'; 
import { ApprovedUserGuard } from './guards/approved-user.guard';

@NgModule({
    providers: [
        AuthGuard,
        OnboardingGuard,
        OnboardedGuard,
        ApprovalPendingGuard,
        ApprovedUserGuard,
    ]
})
export class GuardsModule { }
