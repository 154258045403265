import { Router, NavigationExtras } from '@angular/router';
import { ListService } from './../../../services/list.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { AppApolloService } from 'app/services/app.apollo.service';
import gql from 'graphql-tag';

const AllPeopleQuery = gql`
  query allPeople($only: String, $filters: PeopleFilter) {
    allPeople(only: $only, filters: $filters) {
      paging {
        total_items
      }
    }
  }
`


@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ListService]
})

export class VideoComponent implements OnInit {


  nationalitiesArray: any;
  backgroundsArray: any;
  skillsArray: any;
  loading: boolean = false;
  selectedNationality: any = null;
  selectedBackground: any = null;
  selectedSkill: any = null;
  placeholder: any;
  showOnboardForm: boolean = false;
  showOnboarding: boolean = false;
  showFilter: boolean = false;
  featured: boolean = false;
  stats: number = null;
  requestLoading: boolean = false;


  constructor(private lists: ListService,
    private router: Router,
    private _cookieService: CookieService,
    private appApollo: AppApolloService) {
    // this.featureService.isFeatured.subscribe(value => {
    //   if(value) {
    //     let object = value.find(item => item.feature.toLowerCase() === "onboarding");
    //       this.featured = object.active;
    //   }
    // })

    // this.currentLanguage = this.translation.currentLanguage;

    let showOnboard = this._cookieService.get('showOnboard');
    let isUserSignedIn = this._cookieService.get('isUserSignedIn');
    if (!isUserSignedIn && !showOnboard) {
      this.showOnboardForm = true;
      this._cookieService.put('showOnboard', 'true');
    }
    // this.placeholder = this.placeholders.translated;
    this.lists.getListArray(['nationalities', 'backgrounds', 'skills']).subscribe(data => {
      this.loading = true;
      if (data) {
        this.nationalitiesArray = data.nationalities;
        this.backgroundsArray = data.backgrounds;
        this.skillsArray = data.skills;
        this.loading = false;
      }
    },
      err => {
        console.log(err);
        this.loading = false;
      });
    this.makeRequest();
  }


  ngOnInit() {
  }


  goToAboutUs() {
    this.router.navigateByUrl('/about-us');
  }

  getFromDate() {
    var today = new Date();
    var day = today.getDate();
    var month= today.getMonth()+1; 
    var year= today.getFullYear();
    var dd = day.toString();
    var mm = month.toString();
    if(day<10){
        dd='0'+dd;
      } 
    if(month<10){
        mm='0'+mm;
      } 
    var fromYear = year - 1;
    fromYear.toString();
    var from_date = dd+'-'+mm+'-'+fromYear;
    return from_date;
  }

  makeRequest() {
    this.requestLoading = true;

    const variables = {
      only: 'facets',
      filters: {
        last_interaction: {
          from: this.getFromDate()
        },
        pop_enabled: true
      }
    }

    if (this.selectedSkill) {
      variables.filters['skill_ids'] = [this.selectedSkill];
    }

    if (this.selectedBackground) {
      variables.filters['background_ids'] = [this.selectedBackground];
    }

    if (this.selectedNationality) {
      variables.filters['nationalities'] = [this.selectedNationality];
    }

    this.appApollo.query(AllPeopleQuery, variables)
    .then((res: any) => {
      const peopleStats = res.allPeople;
      if (peopleStats) {
        this.stats = peopleStats.paging.total_items;
        this.requestLoading = false;
      }
    });
  }

  selectNationality(data) {
    this.selectedNationality = data.id;
    this.makeRequest();
  }

  removeNationality(data) {
    this.selectedNationality = null;
    this.makeRequest();
  }

  selectBackground(data) {
    this.selectedBackground = data.id;
    this.makeRequest();
  }

  removeBackground(data) {
    this.selectedBackground = null;
    this.makeRequest();
  }

  selectSkill(data) {
    this.selectedSkill = data.id;
    this.makeRequest();
  }

  removeSkill(data) {
    this.selectedSkill = null;
    this.makeRequest();
  }


  toSearchPage() {
    let qs: NavigationExtras = {
      queryParams: {}
    };
    if (this.selectedNationality !== null) {
      qs.queryParams['nationalities'] = this.selectedNationality;
    }

    if (this.selectedBackground !== null) {
      qs.queryParams['backgrounds'] = this.selectedBackground;
    }

    if (this.selectedSkill !== null) {
      qs.queryParams['skills'] = this.selectedSkill;
    }

    this.router.navigate(['/search'], qs);
  }

}
