import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { SharedModule } from '../../shared.module';
import { OpportunityApplicationModule } from 'app/content/opportunity/applications/opportunity-application/opportunity-application.module';
import { MyDashboardComponent } from './myDashboard.component';
import { InfiniteScrollModule } from 'app/directives/infinite-scroll/infiniteScroll.module';
import { SelectModule } from 'ng2-select';
import { LinearProgressBarModule } from 'app/blocks/linear-progress-bar/linear-progress-bar.module';
import { OpArchiveModalModule } from 'app/blocks/op-archive-modal/op-archive-modal.module';
import { OpDuplicateModalModule } from 'app/blocks/op-duplicate-modal/op-duplicate-modal.module';

@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    RouterModule,
    SelectModule,
    FormsModule,
    ReactiveFormsModule,
    OpportunityApplicationModule,
    InfiniteScrollModule,
    LinearProgressBarModule,
    OpArchiveModalModule,
    OpDuplicateModalModule,
  ],
  declarations: [MyDashboardComponent],
  exports: [MyDashboardComponent]
})
export class MyDashboardModule { }
