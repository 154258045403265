export class Error {
  message: string;
  code: string;
  subCode: string;
  statusCode: number;
  customMessage: string;
  customTitle: string;

  constructor(error) {
    if (error && error.networkError) {
      error = error.networkError;
    }
    if (error && error.networkErr) {
      error = error.networkErr;
    }

    if (
      error.graphQLErrors &&
      error.graphQLErrors.length &&
      error.graphQLErrors[0].extensions
    ) {
      this.setGraphqlErrorDetails(error);
    } else {
      this.setErrorDetails(error);
    }
  }

  setGraphqlErrorDetails(error) {
    const err = error.graphQLErrors[0];

    this.code = err.extensions.code;
    this.message = err.extensions.message;
    this.subCode = err.extensions.sub_code
      ? err.extensions.sub_code.replaceAll('_', ' ')
      : null;

    if (!this.message || !this.subCode) {
      // If no proper message, run the error through standard error details
      this.setErrorDetails(error);
    }
  }

  setErrorDetails(error) {
    if (error.error) {
      error = error.error;
    }
    if (error.status && typeof error.status === 'object') {
      error = error.status;
    }
    this.statusCode = error.code;

    const custom = this.getCustomValues(error);
    this.customTitle = custom.title;
    this.customMessage = custom.message;
  }

  getCustomValues(error) {
    const customError = {
      title: null,
      message: null
    };

    const { code, message } = error;

    switch (code) {
      case 401:
        customError.title = 'Unauthorised';
        if (message === 'Invalid, missing or expired token') {
          customError.message = message;
        } else {
          customError.message =
            'Invalid account or you are not authorised to perform this action.';
        }
        break;
      case 406:
				customError.title = 'Invalid account';
				customError.message =
					`Your account does not have sufficient privileges to access this platform.
					Please login/signup with a partners.aiesec.org account`;
				break;
      case 500:
        customError.title = 'Internal server error';
        customError.message =
          'There was some problem handling the request. Please contact support.';
        break;
      case 503:
        customError.title = 'Backend server at capacity';
        customError.message = `The backend server is in a unhealthy state and is not responding to requests.
				\nPlease check back in a couple of hours.`;
        break;
      case 504:
        customError.title = 'Network timeout';
        customError.message =
          'We are currently experiencing a downtime or major problems in our system. ' +
          'Our developers are working on it, please check back with us after a couple of hours.';
        break;
      default:
        customError.title = 'Error';
        customError.message = `Something went wrong.`;
        break;
    }

    return customError;
  }
}
