import gql from 'graphql-tag';

const fragments = {
    opApplicationForList: gql`
      fragment opApplicationForList on OpportunityApplication {
        id
        average_score
        created_at
        current_status
        custom_status {
            id
            name
            position
        }
        managers {
            full_name
            id
            profile_photo
        }
        opportunity {
            id
        }
        permissions {
            can_be_rejected
        }
        person {
            id
            dob
            email
            first_name
            last_name
            full_name
            home_lc {
                id
                name
            }
            interviewed
            last_name
            person_profile {
                id
                nationalities {
                    id
                    name
                }
            }
            profile_photo
            status
        }
        required_backgrounds_percentage
        required_skills_percentage
        status
        updated_at
      }
    `,
    opApplication: gql`
      fragment opApplication on OpportunityApplication {
        average_score
        created_at
        current_status
        custom_status {
            id
            name
            position
        }
        id
        managers {
            full_name
            id
            profile_photo
        }
        permissions {
            can_be_rejected
        }
        person {
            first_name
            full_name
            id
            person_profile {
                id
                nationalities {
                    id
                    name
                }
            }
            profile_photo
        }
        required_backgrounds_percentage
        required_skills_percentage
        status
      }
    `,
    person: gql`
    fragment person on Person {
        academic_experiences {
            country
            description
            end_date
            experience_level {
                id
                name
            }
            id
            organisation_name
            start_date
        }
        first_name
        full_name
        id
        person_profile {
            backgrounds {
                id
                name
            }
            id
            languages {
                id
                constant_name
            }
            nationalities {
                id
                name
            }
            skills {
                id
                constant_name
                option
            }
        }
        professional_experiences {
            country
            description
            end_date
            experience_level {
                id
                name
            }
            id
            organisation_name
            start_date
            title
        }
        profile_photo
      }
    `
};

export const GetOpportunity = gql`
    query GetOpportunity($id: ID) {
      getOpportunity(id: $id) {
        custom_statuses {
          edges {
            node {
              id
              name
              position
            }
          }
        }
        id
        opportunity_duration_type {
          id
          duration_min
          duration_max
          duration_type
        }
        scorecard {
          id
          name
        }
        slots {
          id
          title
          status
          openings
          applications_close_date
          original_applications_close_date
          start_date
          end_date
          available_openings
          created_at
          permissions {
            can_delete
            can_move_to_inactive
            can_update
          }
        }
        title
        programme {
          id
          short_name_display
        }
      }
    }
`

export const GetOpportunityApplications = gql`
    query GetOpportunityApplicationsQuery($page: Int, $per_page: Int, $q: String,  $filters: ApplicationFilter, $sort: String) {
        allOpportunityApplication(page: $page, per_page: $per_page, q: $q, filters: $filters, sort: $sort) {
            paging {
                total_items,
                total_pages,
                current_page
            }
            data {
                ... opApplicationForList
            }
        }
    }
${fragments.opApplicationForList}
`

export const GetOpApplication = gql`
    query GetApplicationQuery($id: ID) {
        getApplication(id: $id) {
            ... opApplication
        }
    }
${fragments.opApplication}
`

export const CreateCustomStatus = gql`
    mutation CreateCustomStatus($opportunity_id: ID!, $custom_status: CustomStatusInput) {
        createCustomStatusOpportunity(opportunity_id: $opportunity_id, custom_status: $custom_status) {
            id
            name
            position
        }
    }
`

export const UpdateCustomStatus = gql`
    mutation UpdateCustomStatus($id: ID!, $opportunity_id: ID!, $custom_status: CustomStatusInput) {
        updateCustomStatusOpportunity(id: $id, opportunity_id: $opportunity_id, custom_status: $custom_status) {
            id
            name
            position
        }
    }
`

export const DeleteCustomStatus = gql`
    mutation DeleteCustomStatusOpportunity($id: ID!, $opportunity_id: ID!) {
        deleteCustomStatusOpportunity(id: $id, opportunity_id: $opportunity_id) {
            id
            name
            position
        }
    }
`

export const UpdateOpApplication = gql`
    mutation UpdateOpApplication($id: ID!, $filters: OpportunityApplicationInput) {
        updateApplication(id: $id, opportunity_application: $filters) {
            ... opApplication
        }
    }
${fragments.opApplication}
`


export const AppStatusMutations = {
    reopen: gql`
        mutation ReopenApplication($id: ID!) {
            reopenApplication(id: $id) {
                ... opApplication
            }
        }
    ${fragments.opApplication}
    `,
    match: gql`
        mutation MatchApplication($id: ID!) {
            matchApplication(id: $id) {
                ... opApplication
            }
        }
    ${fragments.opApplication}
    `,
    realize: gql`
        mutation RealizeApplication($id: ID!) {
            realizeApplication(id: $id) {
                ... opApplication
            }
        }
    ${fragments.opApplication}
    `,
    approve: gql`
        mutation ApproveApplication($id: ID!) {
            approveApplication(id: $id) {
                ... opApplication
            }
        }
    ${fragments.opApplication}
    `,
    complete: gql`
        mutation CompleteApplication($id: ID!) {
            completeApplication(id: $id) {
                ... opApplication
            }
        }
    ${fragments.opApplication}
    `,
    withdraw: gql`
        mutation WithdrawApplication($id: ID!) {
            withdrawApplication(id: $id) {
                ... opApplication
            }
        }
    ${fragments.opApplication}
    `,
    reject: gql`
        mutation RejectApplication($id: ID!) {
            rejectApplication(id: $id) {
                ... opApplication
            }
        }
    ${fragments.opApplication}
    `
}

export const ChangeCustomStatus = gql`
    mutation ChangeCustomStatus($id: ID!, $new_status_id: Int) {
        changeCustomStatusApplication(id: $id, new_status_id: $new_status_id) {
            ... opApplication
        }
    }
${fragments.opApplication}
`

export const GetPerson = gql`
    query GetPerson($id: ID) {
        getPerson(id: $id) {
            ... person
        }
    }
${fragments.person}
`

export const GetOpportunityApplicationQuestionnaires = gql`
    query GetOpportunityApplicationQuestionnaires($id: ID) {
      getApplication(id: $id) {
        id
        application_questionnaires {
          edges {
            node {
              answered_at
              answers
              questionnaire {
                id
                name
                questions {
                  id
                  question_text
                  question_type
                }
              }
            }
          }
        }
        opportunity {
          id
          opportunity_questions {
            edges {
              node {
                id
                is_mandatory
                position
                parent {
                  id
                }
                question_text
                question_type
                sub_questions {
                  edges {
                    node {
                      id
                      question_text
                    }
                  }
                }
              }
            }
          }
          questionnaire {
            id
            name
          }
        }
        questionnaire_answers
        questionnaire_attachment {
            filename
            id
            url
        }
      }
    }
`

export const AssignApplicationQuestionnaires = gql`
    mutation AssignApplicationQuestionnaires($id: ID!, $opportunity_application: OpportunityApplicationInput) {
      updateApplication(id: $id, opportunity_application: $opportunity_application) {
        id
        application_questionnaires {
          edges {
            node {
              answered_at
              answers
              questionnaire {
                id
                name
                questions {
                  id
                  question_text
                  question_type
                }
              }
            }
          }
        }
      }
    }
`
