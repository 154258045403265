
import {filter, takeWhile, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TokenService } from './token.service';
import { AsyncSubject ,  Subject ,  BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {CookieService} from 'ngx-cookie';
import { environment } from '../../environments/environment';
import * as Raven from 'raven-js';
import { ErrorService } from './error.service';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { AppApolloService } from './app.apollo.service';


export const CurrentPersonFragment = gql`
    fragment CurrentPerson on CurrentPerson {
        created_at
        id
        email
        full_name
        home_lc {
            id
        }
        is_pop_user
        managed_branches {
            branch_employees {
                edges {
                    node {
                        id
                        person {
                            id
                            contact_detail {
                                email
                                phone
                            }
                            first_name
                            last_name
                            profile_photo
                        }
                        status
                    }
                }
            }
            home_lc {
                id
            }
            id
            name
            status
        }
        managed_companies {
            id
            partner_type
        }
        permissions {
            has_completed_platform_nps
        }
        profile_photo
    }
`;

const CurrentPersonQuery = gql`
    query CurrentPersonQuery {
        currentPerson {
          ... CurrentPerson
        }
    }
    ${CurrentPersonFragment}
`;

const GetShowWelcome = gql`
    query GetShowWelcome($name: String!, $applicable_id: ID!, $applicable_type: String!) {
        clientDataGetByNameAndApplicable(name: $name, applicable_id: $applicable_id, applicable_type: $applicable_type) {
            id
            value
        }
    }
`;

@Injectable()
export class UserService {
    userDataLoading = new BehaviorSubject(null);
    tokenRefreshed = new Subject();
    userPresent: AsyncSubject<boolean> = new AsyncSubject();
    currentUser: any;
    currentUserId = new BehaviorSubject(null);
    token = '';
    apiUrl = '';
    public publicToken: string;
    showUpdateLc = new BehaviorSubject(null);

    expiryDate: any;
    userGeolocation: any;

    constructor(
        private http: HttpClient,
        private cookies: CookieService,
        private tokenService: TokenService,
        private router: Router,
        private errorService: ErrorService,
        private apollo: Apollo,
        private appApollo: AppApolloService,
    ) {
      this.publicToken = this.appApollo.environment.publicToken;
      this.apiUrl = environment.apiUrl;
      this.tokenManager();
      this.getUserGeolocation();
    }

    tokenManager() {

        let listen = true
        this.tokenService.tokenPresent.pipe(
            takeWhile(() => listen),
            filter(value => value !== null),)
            .subscribe((tokenPresent: boolean) => {
                if (!tokenPresent) {
                    this.token = '';
                    this.userDataLoading.next(false);
                    this.userPresent.next(false);
                    this.userPresent.complete();
                    listen = false;
                    return
                }

                if (tokenPresent) {
                    this.token = this.tokenService.token;
                    this.userPresent.next(true);
                    this.userPresent.complete();
                    this.getCurrentPerson();
                    listen = false
                }
            });

       this.tokenService.refreshedToken.subscribe((token: string) => {
           if (!token) {
            return
           }
           this.token = token
       })
    }

    setExpiry() {
        const date = new Date();
         return this.expiryDate = new Date(date.getFullYear() + 1, date.getMonth(), date.getDate());
    }

    getCurrentPerson(fetchPolicy?) {

        this.apollo.query({
            query: CurrentPersonQuery,
            fetchPolicy: fetchPolicy || 'cache-first'
        }).pipe(
        map((data: any) => data.data.currentPerson))
        .subscribe(currentPerson => {
            if (!environment.development) {
                this.setUserForRaven(currentPerson)
            }
            if (currentPerson.is_pop_user) {
                this.currentUser = currentPerson;
                if (
                    !this.currentUser.managed_companies ||
                    (this.currentUser.managed_companies && this.currentUser.managed_companies.length === 0)
                ) {
                    this.noManagedCompaniesError();
                    this.userPresent.next(false);
                    this.userPresent.complete();
                    this.userDataLoading.next(false);
                    return;
                }

                const isRegistered = this.cookies.get('isRegistered');

                if (!(isRegistered && isRegistered === 'true')) {
                    this.cookies.put('isRegistered', 'true', {'expires': this.setExpiry()});
                }

                this.cookies.put('isLoggedIn', 'true');

                this.userPresent.next(true);
                this.userPresent.complete();

                this.userDataLoading.next(true);

                this.checkShowWelcome(this.currentUser);

            } else {
                this.userDataLoading.next(false);
                this.nonPopUserError();
            }
        },
        err => {
            if (this.isHomeLcMissingError(err.networkError)) {
                this.userDataLoading.next(false);
                this.showUpdateLc.next(true);
                return;
            }
            this.userDataLoading.next(false);
            if (err.networkError.status === 406) {
                this.nonPopUserError(err);
            } else {
                this.errorService.showErrorModal(err);
            }
        });
    }

    checkShowWelcome(currentUser) {
        this.apollo.query({
            query: GetShowWelcome,
            variables: {
                name: 'ShowWelcome',
                applicable_id: currentUser.id,
                applicable_type: 'Person',
            }
        }).pipe(
        map((res: any) => res.data.clientDataGetByNameAndApplicable))
        .subscribe(data => {
            if (data.value !== 'true') {
                this.router.navigateByUrl('/welcome');
            }
        });
    }

    isHomeLcMissingError(error) {
        if (!error) {
            return false;
        }
        if (error.status === 422 && error.error.message === 'Home lc is missing') {
            return true;
        }
        return false;
    }

    noManagedCompaniesError() {
        const customMessage = `There's some problem with your account, please contact support`;
        this.errorService.showErrorModal(null, customMessage);
    }

    nonPopUserError(err?) {
        if (err && err.networkError) {
            err = err.networkError;
        }
        if (err && err.networkErr) {
            err = err.networkErr;
        }
        const customMessage = `Your account does not have sufficient privileges to access this platform. Please login/signup with a partners.aiesec.org account`;
        const customAction = 'logout';
        this.errorService.showErrorModal(err, customMessage, customAction);
    }

    updateUser(data?: any) {
        if (data) {
            this.currentUser = data
            this.userDataLoading.next(true);
        } else {
            this.getCurrentPerson('network-only');
        }
    }

    programmeFees() {
        return this.http.get(`${this.apiUrl}/current_person/programme_fees?access_token=${this.token}`);
    }

    getPerson(id) {
        return this.http.get(`${this.apiUrl}/people/${id}?access_token=${this.token || this.publicToken}`);
    }

    setUserForRaven(user) {
        Raven.setUserContext({
            id: user.id.toString(),
            email: user.email
        })
    }

    getUserGeolocation() {
        this.http.get(`https://aiesec-geocoder-2.herokuapp.com/json/`)
            .subscribe(geolocation => {
                this.userGeolocation = geolocation
            })
    }

    atLeastOneApprovedBranch() {
        if (!this.currentUser) {
            return false;
        }
        for (const b of this.currentUser.managed_branches) {
            if (b.branch_employees && b.branch_employees.edges) {
                const approvedCurrentEmployee =  b.branch_employees.edges
                .find(eEdge => +eEdge.node.person.id === +this.currentUser.id && eEdge.node.status === 'approved');
                if (approvedCurrentEmployee) {
                    return true;
                }
            }
        }
        return false;
    }

    getApprovedEmployeeBranches() {
        if (!this.currentUser) {
            return [];
        }

        const approvedBranches = [];
        for (const b of this.currentUser.managed_branches) {
            if (b.branch_employees && b.branch_employees.edges) {
                const approvedEmployee = b.branch_employees.edges
                .find(employeeEdge => +employeeEdge.node.person.id === +this.currentUser.id && employeeEdge.node.status === 'approved');
                if (approvedEmployee) {
                    approvedBranches.push(b);
                }
            }
        }
        return approvedBranches;
    }

    getApprovedBranchesWithApprovedEmployee() {
        if (!this.currentUser) {
            return [];
        }

        const validBranches = [];
        for (const b of this.currentUser.managed_branches) {
            if (b.status === 'approved') {
              if (b.branch_employees && b.branch_employees.edges) {
                const approvedEmployee = b.branch_employees.edges
                .find(employeeEdge => +employeeEdge.node.person.id === +this.currentUser.id && employeeEdge.node.status === 'approved');
                if (approvedEmployee) {
                  validBranches.push(b);
                }
              }
            }
          }
        return validBranches;
    }

    isVerifiedUserInBranch(branch) {
        if (branch && branch.branch_employees && branch.branch_employees.edges) {
            return branch.branch_employees.edges.find(eEdge => {
              if (+eEdge.node.person.id === +this.currentUser.id && eEdge.node.status === 'approved') {
                return true;
              }
            })
        }
        return false;
    }

}
