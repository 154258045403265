import gql from 'graphql-tag';
import { CurrentPersonFragment } from 'app/services/user.service';

export const CurrentPersonUpdate = gql`
  mutation CurrentPersonUpdate($person: CurrentPersonInput) {
    currentPersonUpdate(person: $person) {
      ... CurrentPerson
    }
  }
  ${CurrentPersonFragment}
`

export const GetCitiesByMc = gql`
    query getCitiesByMc($mc_id: Int!, $allocated_to_lc: Boolean!) {
        cityByMc(mc_id: $mc_id, allocated_to_lc: $allocated_to_lc) {
            google_place_id
            id
            mc {
                id
                name
            }
            name
        }
    }
`;

export const FindLcQuery = gql`
    query findLcQuery($city_id: ID, $city_name: String) {
      findLc(city_id: $city_id, city_name: $city_name) {
        id
      }
    }
`;