import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  error: boolean = false;
  errorDetails: any;
  errorModal: boolean = true;

  ngOnInit() {
    this.error = true;
    this.errorDetails = 'Page not found. Please check the link you typed in!';
  }
  okay() {
  	  window.location.href = "/";
    }
}
