import gql from 'graphql-tag';

export const AllOpportunity = gql`
query AllOpportunity($q: String, $filters: OpportunityFilter) {
    allOpportunity(q: $q, filters: $filters) {
        data {
            id
            title
        }
    }
}
`
