
import {filter,  map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { AppApolloService } from 'app/services/app.apollo.service';
import { GetQuestionnairesQuery } from './../content/organisation/organisation.graphql';
import { Injectable } from '@angular/core';
import { ModelService } from './model.service';
import { BehaviorSubject } from 'rxjs';
import { UserService } from './user.service';
import { ErrorService } from 'app/services/error.service';
import { GetBranchesQuery } from 'app/graphql/queries';
import gql from 'graphql-tag';

export const GetOrganisationQuery = gql`
    query getOrganisation($id: ID!) {
        getOrganisation(id: $id) {
          id
          name
        }
    }
`;

@Injectable()
export class OrganisationService {
  organisationEndpoint: string;
  organisationId: number;
  organisation = new BehaviorSubject(null);
  allBranches = new BehaviorSubject(null);
  myRejectedBranches = new BehaviorSubject(null);
  loaded = new BehaviorSubject(null);
  questionnaires = new BehaviorSubject(null);
  myOrganisationId = new BehaviorSubject(null);

  constructor(
    private model: ModelService,
    private userService: UserService,
    private appApollo: AppApolloService,
    private errorService: ErrorService,
    private apollo: Apollo
  ) {

    this.userService.userDataLoading.pipe(
    filter(item => item !== null))
    .subscribe(data => {
      if (data && this.userService.currentUser && this.userService.currentUser.managed_companies.length > 0) {
        this.organisationId = this.userService.currentUser.managed_companies[0].id;
        this.myOrganisationId.next(this.organisationId);
        this.organisationEndpoint = `organisations/${this.organisationId}`
        this.loaded.next(true)
        this.getOrganisation()
        this.getQuestionnaires()
      } else {
        console.log("User not present or no company associated with the user")
      }
    }, error => {
      console.log("Error fetching user: ", error)
    })
  }

  getOrganisation() {
    this.appApollo.query(GetOrganisationQuery, {id: this.organisationId})
    .then((res: any) => {
        const organisation = res.getOrganisation;
        if (!organisation) {
          return
        }
        this.organisation.next(organisation)
    }, err => {
        this.errorService.showErrorModal(err.networkError);
    });
  }

  getAllBranches() {
    this.appApollo.query(GetBranchesQuery, {id: this.organisationId})
    .then((res: any) => {
      const allBranches = res.getOrganisation.branches.edges.map((branch) => {
        return branch.node;
      })
      if(!(allBranches || allBranches.length)) return
      this.allBranches.next(allBranches);
    })
  }

  getEmployees(branchId) {
    return this.model.get({name: `${this.organisationEndpoint}/branches/${branchId}/employees`})
  }

  getQuestionnaires() {
    this.apollo.watchQuery({
      query: GetQuestionnairesQuery, 
      variables: { id: this.organisationId }
    })
    .valueChanges
    .pipe(
      map((res: any) => res.data)
    )
    .subscribe(({ getOrganisation, loading }) => {
      if (getOrganisation) {
        const questionnaireEdges = getOrganisation.questionnaires.edges;
        this.questionnaires.next(questionnaireEdges.map((item: any) => item.node))
      }
    }, err => {
      console.log('error', err)
      this.errorService.showErrorModal(err.networkError);
    })
  }

  autoCompleteQuery(q) {

    let qs = `&q=${q}`

    return this.model.get({name: `organisations/autocomplete`, qs: qs})
  }

  branchAutocomplete(q?) {
    return this.model.get({name: `branches/autocomplete`, qs: q})
  }

  inviteEmployee(branchId, data) {
    return this.model.post(`${this.organisationEndpoint}/branches/${branchId}/invite`, data)
  }

}
