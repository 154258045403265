import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'opStatusText'
})
export class OpStatusTextPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
        return null;
    }

    switch (value) {
        case 'open':
          return 'live';
        case 'under_review':
          return 'under review';
        case 'removed': 
          return 'archived';
        default:
          return value;
    }
  }

}
