import gql from 'graphql-tag';

const fragments = {
    personForWatchlist: gql`
      fragment personForWatchlist on Person {
        first_name
        id
        person_profile {
            id
            nationalities {
                id
                name
            }
        }
        watchlist_for_opportunity {
            created_at
            id
            required_backgrounds_percentage
            required_skills_percentage
        }
      }
    `,
};


export const GetWatchlist = gql`
    query GetWatchlist($opportunity_id: Int) {
        getWatchlist(opportunity_id: $opportunity_id) {
            ... personForWatchlist
        }
    }
    ${fragments.personForWatchlist}
`

export const GetPerson = gql`
    query GetPersonForWatchlist($id: ID, $matchesWithTypeId: Int, $showMatchesWith: Boolean!) {
        getPerson(id: $id) {
            academic_experiences {
                country
                description
                end_date
                experience_level {
                    id
                    name
                }
                id
                organisation_name
                start_date
            }
            first_name
            id
            person_profile {
                id
                backgrounds {
                    id
                    matches_with(type: opportunity, type_id: $matchesWithTypeId) @include(if: $showMatchesWith)
                    name
                }
                languages {
                    id
                    matches_with(type: opportunity, type_id: $matchesWithTypeId) @include(if: $showMatchesWith)
                    constant_name
                }
                nationalities {
                    id
                    matches_with(type: opportunity, type_id: $matchesWithTypeId) @include(if: $showMatchesWith)
                    name
                }
                skills {
                    id
                    matches_with(type: opportunity, type_id: $matchesWithTypeId) @include(if: $showMatchesWith)
                    constant_name
                }
            }
            professional_experiences {
                description
                end_date
                organisation_name
                id
                start_date
                title
            }
            profile_photo
        }
    }
`


export const GetOpportunity = gql`
    query GetOpportunity($id: ID) {
        getOpportunity(id: $id) {
            custom_statuses {
                edges {
                    node {
                        id
                        name
                        position
                    }
                }
            }
            id
            slots {
                id
            }
        }
    }
`