import { ValidatorService as V } from 'app/services/validators';
import {
  RemoveQuestionMutation,
  questionOptionBulkCreateMutation,
  questionOptionBulkUpdateMutation,
  GetQuestionnairesQuery
} from '../../content/organisation/organisation.graphql';
import { map } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { makeErrorText } from 'app/constants';
@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class EditQuestionComponent implements OnInit {

  @ViewChild('editQuestionModal', { static: true }) editQuestionModal;
  @ViewChild('confirmationModal', { static: true }) confirmationModal;
  @Input() questionnaireId: number;
  @Input() organisationId: number;
  @Input() question: any;
  @Output() close: EventEmitter<any> = new EventEmitter(null);
  loading = false;
  object = Object;
  questionForm: any;
  questionType = [{id: 'multiple_choice', text: 'Multiple choice', iconClass: 'fa-circle-dot fa-regular'},
                  {id: 'paragraph', text: 'Paragraph', iconClass: 'fa-align-left fa-regular'},
                  {id: 'text', text: 'Text', iconClass: 'fa-bars-sort fa-regular'},
                  {id: 'attachment', text: 'Attachment', iconClass: 'fa-arrow-up-from-bracket fa-regular'},
                  {id: 'checkbox', text: 'Checkbox', iconClass: 'fa-square-check fa-solid'}
                ];
  hasAnyError = false;
  displayError = false;
  questionSelected: any;
  questionTypeSelected: any;
  closeModal = false;
  updatedQuestionType = { id: '', text: '' };
  makeError = makeErrorText;
  removeOptionLoading = false;
  optionIndexSelected: number;
  optionNotProvided = false;

  constructor(
    private _fb: FormBuilder,
    private apollo: Apollo,
    private errorService: ErrorService
  ) { }

  ngOnInit() {
    if (this.question) {
      this.makeQuestionForm(this.question);
    }
  }

  makeQuestionForm(question?) {
    this.questionForm = this._fb.group({
      'id': new FormControl(question ? question.id : null),
      'question_text': new FormControl(question ? question.question_text : null,
        [
          V.forbiddenTextValidator(
            new RegExp(
              'https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}'
              )
            ),
          Validators.required
        ]),
      'question_type': new FormControl(question ? question.question_type : null, Validators.required),
      'is_mandatory': new FormControl(question ? question.is_mandatory : null),
      'sub_questions': new FormArray([])
    })
    if (question) {
      this.questionSelected = question;
      this.questionTypeSelected = this.questionType.find(item => item.id === this.questionSelected.question_type);
      question.sub_questions.forEach(subQuestion => {
        this.questionForm.get('sub_questions').push(this.createSubQuestion(subQuestion));
      });
    }
  }

  createSubQuestion(subQuestion?) {
    return new FormGroup({
      'id': new FormControl(subQuestion ? subQuestion.id : null),
      'question_text': new FormControl(subQuestion ? subQuestion.question_text : null,
        [
          V.forbiddenTextValidator(
            new RegExp(
              'https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}'
              )
            ),
          Validators.required
        ]),
      'parent_id': new FormControl(subQuestion ? subQuestion.parent.id : this.questionSelected.id),
    });
  }

  validateForm() {
    const currentLength = this.questionForm.value.sub_questions.length;

    if (this.questionForm.valid) {
      this.displayError = false;
      if (this.questionForm.value.question_type === 'multiple_choice' || this.questionForm.value.question_type === 'checkbox') {
        if (this.questionForm.value.sub_questions.length && !this.questionForm.value.sub_questions[currentLength - 1].id) {
          // get a copy of the new options and update the question. Once question is updated, send the add new options request.
          this.sendBulkUpdateRequest(true);
        } else if (this.questionForm.value.sub_questions.length && this.questionForm.dirty) {
          this.sendBulkUpdateRequest();
        } else if (this.questionForm.value.sub_questions.length && !this.questionForm.dirty) {
          this.dismissModal();
        } else {
          // this.sendBulkUpdateRequest();
          this.optionNotProvided = true;
        }
      } else {
        // send the update request alone
        this.sendBulkUpdateRequest();
      }
    } else {
      this.displayError = true;
    }
  }

  addSubQuestion() {
    if (this.questionForm.valid
      && (this.questionForm.value.question_type === 'multiple_choice' || this.questionForm.value.question_type === 'checkbox')) {
      this.displayError = false;
      this.addSubQuestionAsOption();
    } else {
      this.displayError = true;
    }
  }

  addSubQuestionAsOption(): void {
    this.questionForm.get('sub_questions').push(this.createSubQuestion());
  }

  sendAddOptionRequest(questionsArray) {
    questionsArray.forEach(question => {
      if (!question.id) {
        delete question.id  // remove null id fields before sending request
      }
    })
    const variables = {
      questions: questionsArray
    }
    this.apollo.mutate({
      mutation: questionOptionBulkCreateMutation,
      variables: variables,
      update: (store, { data: response }) => {
        const data: any = store.readQuery({ query: GetQuestionnairesQuery, variables: { id: this.organisationId } });
        if (+data['getOrganisation'].id === +this.organisationId) {
          for (const questionnaire of data['getOrganisation'].questionnaires.edges) {
            if (+questionnaire.node.id === +this.questionnaireId) {
              for (const question of questionnaire.node.questions) {
                if (+question.id === +this.questionSelected.id) {
                  question.sub_questions = [...question.sub_questions, ...response['questionBulkCreate']];
                  break;
                }
              }
              break;
            }
          }
        }
        store.writeQuery({ query: GetQuestionnairesQuery, variables: { id: this.organisationId }, data });
      }
    })
      .pipe(
        map((res: any) => res.data)
      )
      .subscribe(response => {
        let responseIndex = 0;
        this.questionForm.get('sub_questions').controls.forEach((element) => {
          if (!element.value.id) {
            element.get('id').patchValue(response['questionBulkCreate'][responseIndex].id);
            responseIndex++;
          }
        });
        this.loading = false;
        this.dismissModal();
      }, err => {
        this.errorService.showErrorModal(err.networkError);
      })
  }

  makeUpdateVariables() {
    let questionsArray = [];
    const question = this.questionForm.value;
    questionsArray = [...questionsArray, {
      id: +question.id,
      question: {
        question_text: question.question_text,
        is_mandatory: question.is_mandatory,
        question_type: question.question_type
      }
    }]
    if (question.sub_questions && question.sub_questions.length) {
      question.sub_questions.forEach(subQuestion => {
        if (subQuestion.id) {
          questionsArray = [...questionsArray, {
            id: +subQuestion.id,
            question: {
              question_text: subQuestion.question_text,
            }
          }];
        }
      });
    }
    return questionsArray;
  }

  sendBulkUpdateRequest(sendAddOptionRequest?) {
    this.loading = true;
    let newlyAddedOptions;
    if (sendAddOptionRequest) {
      newlyAddedOptions = this.questionForm.value.sub_questions.filter(question => !question.id);
    }
    const variables = { questions: this.makeUpdateVariables() };
    this.apollo.mutate({
      mutation: questionOptionBulkUpdateMutation,
      variables: variables,
      refetchQueries: [{ query: GetQuestionnairesQuery, variables: { id: this.organisationId } }]
    })
      .pipe(
        map((res: any) => res.data)
      )
      .subscribe(response => {
        if (sendAddOptionRequest) {
          this.sendAddOptionRequest(newlyAddedOptions);
        } else {
          this.loading = false;
          this.dismissModal();
        }
      }, err => {
        this.errorService.showErrorModal(err.networkError);
      })
  }

  removeOption(optionId, index) {
    if (optionId) {
      this.optionIndexSelected = index;
      this.sendRemoveOptionRequest(optionId, index);
    } else {
      this.questionForm.get('sub_questions').removeAt(index);
    }
  }

  sendRemoveOptionRequest(optionId, index) {
    this.removeOptionLoading = true;
    this.apollo.mutate({
      mutation: RemoveQuestionMutation,
      variables: { id: optionId },
      update: (store, { data: response }) => {
        const data: any = store.readQuery({ query: GetQuestionnairesQuery, variables: { id: this.organisationId } });
        if (+data['getOrganisation'].id === +this.organisationId) {
          for (const questionnaire of data['getOrganisation'].questionnaires.edges) {
            if (+questionnaire.node.id === +this.questionnaireId) {
              for (const question of questionnaire.node.questions) {
                if (+question.id === +this.questionSelected.id) {
                  question.sub_questions = question.sub_questions.filter(item => +item.id !== +response['removeQuestion'].id);
                  break;
                }
              }
              break;
            }
          }
        }
        store.writeQuery({ query: GetQuestionnairesQuery, variables: { id: this.organisationId }, data });
      }
    })
      .pipe(
        map((res: any) => res.data)
      )
      .subscribe(response => {
        this.removeOptionLoading = false;
        this.optionIndexSelected = null;
        this.questionForm.get('sub_questions').removeAt(index);
        this.questionForm.markAsDirty();
      }, err => {
        this.errorService.showErrorModal(err.networkError);
      })
  }

  checkUpdatedQuestionType(type) {
    if ((this.questionTypeSelected.id === 'multiple_choice' || this.questionTypeSelected.id === 'checkbox')
      && (type.id !== 'multiple_choice' && type.id !== 'checkbox')) {
      this.updatedQuestionType = type;
      this.confirmationModal.show();
    } else {
      if (type.id === 'multiple_choice' || type.id === 'checkbox') {
        const subQuestionControls = this.questionForm.controls.sub_questions.controls;
        if (!subQuestionControls.length) {
          this.addSubQuestionAsOption();
        }
      }
      this.selectQuestionType(type);
    }
  }

  selectQuestionType(type, confirmModalOrigin?) {
    this.questionForm.controls['question_type'].patchValue(type.id);
    this.questionForm.markAsDirty();
    this.questionTypeSelected = this.questionType.find(item => item.id === type.id);
    this.confirmationModal.hide();
    if (confirmModalOrigin) {
      this.removeBlankSubQuestions();
    }
  }

  removeBlankSubQuestions() {
    const subQuestionControls = this.questionForm.controls.sub_questions.controls;
    for (let i = 0; i < subQuestionControls.length; i++) {
      if (!subQuestionControls[i].value.id && !subQuestionControls[i].value.question_text) {
        this.questionForm.get('sub_questions').removeAt(i);
      }
    }
  }

  dismissModal() {
    this.editQuestionModal.hide();
    this.close.emit(true);
  }

  onClose() {
    this.questionTypeSelected = { id: 'multiple_choice', text: 'Multiple choice' };
    this.confirmationModal.hide();
  }

}
