import { ApplicationsService } from './../../../../../services/applications.service';
import { AppApolloService } from 'app/services/app.apollo.service';
import { Component, OnInit, ViewEncapsulation, Input, OnDestroy } from '@angular/core';
import { ErrorService } from 'app/services/error.service';
import { GetOpportunityApplicationQuestionnaires } from '../../applications.graphql';
import {takeWhile} from 'rxjs/operators';
import { GetOpApplication } from './questionnaire-response.graphql';

@Component({
  selector: 'app-questionnaire-response',
  templateUrl: './questionnaire-response.component.html',
  styleUrls: ['./questionnaire-response.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class QuestionnaireResponseComponent implements OnInit, OnDestroy {

  @Input() opportunity;
  assignedQuestionnaire: any;
  opportunityQuestionnaireAnswers: any;
  opportunityQuestionnaire: any;
  questionnaireAttachment: any;
  toggleQuestionnaire = false;
  parsedResponse: any;
  loading: boolean;
  showQuestionnaires: boolean;
  applicationQuestionnaires = [];
  selectedIndex: number;
  componentAlive = true;
  applicationDetails: any;

  constructor(
    private appApollo: AppApolloService,
    private errorService: ErrorService,
    private applicationsService: ApplicationsService,
  ) {
  }

  ngOnInit() {
    this.getApplicationDetails();
  }

  getApplicationDetails() {
    this.applicationsService.application_id.pipe(
      takeWhile( () => this.componentAlive))
      .subscribe((id) => {
        if (id) {
          this.loadApplication(id);
        }
      });
  }

  loadApplication(id) {
    this.loading = true;

    const variables = {
        id: id
    }
    this.appApollo.watchQuery(GetOpApplication, variables).pipe(
        takeWhile(() => this.componentAlive))
        .subscribe((data: any) => {
            this.loading = false;
            this.applicationDetails = data.getApplication;
            this.getApplicationQuestionnaire();
          }, err => {
            this.errorService.showErrorModal(err.networkErr);
        });
}

  getApplicationQuestionnaire() {
    this.loading = true;
    this.appApollo.watchQuery(GetOpportunityApplicationQuestionnaires, {id: this.applicationDetails.id})
    .subscribe(response => {
      if (response) {
        this.assignedQuestionnaire = response['getApplication'].opportunity.questionnaire;
        this.opportunityQuestionnaire = response['getApplication'].opportunity.opportunity_questions.edges
                              .map(item => item.node);
        this.opportunityQuestionnaireAnswers = this.filterOutAttachments(response['getApplication'].questionnaire_answers);
        this.questionnaireAttachment = response['getApplication'].questionnaire_attachment;
        this.parsedResponse = this.parseQuestionnaireResponse(this.opportunityQuestionnaire, this.opportunityQuestionnaireAnswers);
        this.getAttachmentResponse();
        if (this.assignedQuestionnaire) {
          this.applicationQuestionnaires = [{
            questionnaire_id: +this.assignedQuestionnaire.id,
            questionnaire_name: this.assignedQuestionnaire.name,
            submitted_at: this.applicationDetails.created_at,
            parsed_response: this.parsedResponse
          }];
        }
        this.parseApplicationQuestionnaireResponse(response['getApplication']);
        // this.applicationQuestionnaires = this.processQuestionnaires(response['getApplication'].application_questionnaires.edges);
        // console.log('applicationQuestionnaires', this.applicationQuestionnaires);
        this.loading = false;
      }
    }, err => {
      this.loading = false;
      this.errorService.showErrorModal(err.networkError);
    })
  }

  filterOutAttachments(questionnaireAnswers) {
    if (questionnaireAnswers) {
      const { ['attachment']: throwAwayData, ...newData } = questionnaireAnswers;  // filter out attachment object.
      return newData;
    }
  }

  parseQuestionnaireResponse(questions, answers) {
    if (answers) {
      return Object.keys(answers).map(key => {
        const question = questions.find(ques => +ques.id === +key)
        if (+question.id === +key) {
          const obj = {};
          obj['question'] = question.question_text;
          obj['question_type'] = question.question_type;
          if (this.questionResponseMapper(question, answers, key) && this.questionResponseMapper(question, answers, key) !== '') {
            obj['response'] = this.questionResponseMapper(question, answers, key);
          } else {
            obj['response'] = 'Candidate has not answered this question';
          }
          return obj;
        }
      });
    } else {
      return [];
    }
  }

  questionResponseMapper(question, answers, key) {
    switch (question.question_type) {
      case 'multiple_choice':
        return answers[key];
      case 'paragraph':
        return answers[key];
      case 'checkbox':
        return answers[key];
      case 'text':
        return answers[key];
      case 'attachment':
          return answers[key];
      default:
        return answers[key];
    }
  }

  getAttachmentResponse() {
    const attachmentQuestion = this.opportunityQuestionnaire.find(question => question.question_type === 'attachment');
    if (this.questionnaireAttachment) {
      const obj = {};
      obj['question'] = attachmentQuestion.question_text;
      obj['question_type'] = 'attachment';
      obj['response'] = {
        filename: this.questionnaireAttachment.filename,
        url: this.questionnaireAttachment.url
      }
      this.parsedResponse.push(obj);
    }
  }

  parseApplicationQuestionnaireResponse(response) {
    const questionnaireResponses = this.processQuestionnaires(response.application_questionnaires.edges);
    questionnaireResponses.forEach(questionnaireResponse => {
      this.applicationQuestionnaires = [...this.applicationQuestionnaires, {
        questionnaire_id: +questionnaireResponse.questionnaire.id,
        questionnaire_name: questionnaireResponse.questionnaire.name,
        submitted_at: questionnaireResponse.answered_at,
        parsed_response: this.parseQuestionnaireResponse(questionnaireResponse.questionnaire.questions, questionnaireResponse.answers)
      }]
    });
  }

  processQuestionnaires(applicationQuestionnaires) {
    return applicationQuestionnaires.map(item => item.node);
  }

  closeQuestionnaireModal() {
    this.showQuestionnaires = false;
  }

  toggle(index) {
    if (this.selectedIndex === index) {
      this.toggleQuestionnaire = !this.toggleQuestionnaire;
    } else {
      this.toggleQuestionnaire = true;
    }
    this.selectedIndex = index;
  }

  ngOnDestroy() {
    this.componentAlive = false;
  }
}
