import { AppApolloService } from './../../services/app.apollo.service';
import { GenerateCv } from './../../graphql/queries';
import { Component, OnChanges, SimpleChanges, EventEmitter, Output, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, Input } from '@angular/core';
import { UserService } from './../../services/user.service';
import { flagName } from '../../modular.functions';
import { Opportunity } from 'app/services/opportunity.service';
import { ErrorService } from 'app/services/error.service';
import { CandidateDetailsQuery } from './candidata-profile.graphql';
import { ModalDirective } from 'ngx-bootstrap';


@Component({
  selector: 'app-candidate-profile',
  templateUrl: './candidate-profile.component.html',
  styleUrls: ['./candidate-profile.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})
export class CandidateProfileComponent implements OnInit, OnChanges {

  @Input() candidate;
  @Input() opportunityId;
  @Input() loggedInUser = false;
  @Input() watchlistToggleLoading;
  @Input() opportunityCountStats: any;
  @Output() addToWatchlist: EventEmitter<any> = new EventEmitter();
  @Output() removeFromWatchlist: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();

  showCandidateProfileModal = false;
  loading: boolean;
  selectedCandidate = new Object();
  flagName = flagName;
  matchingWithOpportunity: boolean;
  // config = {
  //   backdrop: true,
  //   ignoreBackdropClick: false
  // };

  @ViewChild('candidateProfileModal', { static: true }) candidateProfileModal: ModalDirective;
  @ViewChild('DownloadErrorModal', { static: false }) downloadErrorModal: ModalDirective;
  currentUser: any;
  managed_companies: any;
  organizationId: any;
  totalOpportunities: any;
  downloadErrorMsg: any = {};

  constructor(private userService: UserService,
    private opportunityService: Opportunity,
    private appApollo: AppApolloService,
    private errorService: ErrorService) {
    this.userService.userDataLoading.subscribe(data => {
      if (data) {
        this.currentUser = this.userService.currentUser;
        this.managed_companies = this.currentUser.managed_companies;
        if (this.managed_companies) this.organizationId = parseInt(this.managed_companies[0].id);
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges(data: SimpleChanges) {
    this.openCandidateProfileModal();
  }

  openCandidateProfileModal() {
    this.loading = true;
    this.showCandidateProfileModal = true;
    this.appApollo.query(CandidateDetailsQuery, {id: this.candidate.id})
    .then((res) => {
      this.selectedCandidate = res['getPerson'];
      this.loading = false;
    })
    .catch((err) => {
      this.showCandidateProfileModal = false;
      this.loading = false;
    })
  }

  closeCandidateProfileModal() {
    this.candidateProfileModal.hide();
    // setTimeout(() => {
    //   this.selectedCandidate = {};
    //   this.showCandidateProfileModal = false;
    //   this.close.emit(null);
    // }, 600);
    this.close.emit(null);
    this.showCandidateProfileModal = false;
  }

  triggerAddToWatchlist() {
    if (this.loggedInUser) {
      this.addToWatchlist.emit();
    } else {
      this.downloadErrorMsg = {
        id: 0,
        title: 'To show interest, please create an account.',
      };
      this.downloadErrorModal.show();
    }
  }

  triggerRemoveFromWatchlist() {
    this.removeFromWatchlist.emit()
  }

  callGenerateCvMethod() {
    if (this.currentUser) {
      if (this.opportunityCountStats.noOpportunities) {
        this.downloadErrorMsg.id = 1;
        this.downloadErrorMsg.title = 'No Opportunities';
        this.downloadErrorMsg.msg = 'You have to create an opportunity to download the candidate’s CV.';
        this.downloadErrorModal.show();
        return;
      } else if (this.opportunityCountStats.noOpenOpportunities) {
        this.downloadErrorMsg.id = 2;
        this.downloadErrorMsg.title = 'No Open Opportunities';
        this.downloadErrorMsg.msg = `You need to have an open opportunity to download the candidate’s CV.
        Please open an opportunity`;
        this.downloadErrorModal.show();
        return;
      } else if (this.opportunityCountStats.oneOpportunityUnderReview) {
        this.downloadErrorMsg.id = 3;
        this.downloadErrorMsg.title = 'Opportunity Under Review';
        this.downloadErrorMsg.msg = `Your opportunity need to be reviewed by AIESEC, so you can download the candidate’s CV.
        Please contact your Account Manager.`;
        this.downloadErrorModal.show();
        return;
      }

      const variables = {
        id: this.candidate.id
      }
      const importantStuff = window.open('', '_blank');
      importantStuff.document.write('Loading preview...');
      this.appApollo.mutate(GenerateCv, variables)
        .then(response => {
          importantStuff.location.href = response['generateCv']['cv_url'];
        }, err => {
          this.errorService.showErrorModal(err.networkError);
        });
    } else {
      this.downloadErrorMsg = {
        id: 0,
        title: 'To download the CV, please create an account.',
      };
      this.downloadErrorModal.show();
    }
  }

  dismissErrorModal() {
    this.downloadErrorModal.hide();
  }
}
