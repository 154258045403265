import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UpdateHomelcComponent } from './update-homelc.component';
import { ModalModule } from 'ngx-bootstrap';
import { SharedModule } from 'app/shared.module';
import { LinearProgressBarModule } from '../linear-progress-bar/linear-progress-bar.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ModalModule,
        SharedModule,
        LinearProgressBarModule,
    ],
    declarations: [
      UpdateHomelcComponent,
    ],
    exports: [
      UpdateHomelcComponent,
    ]
})
export class UpdateHomelcModule {
}
