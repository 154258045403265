import gql from 'graphql-tag';


const fragments = {
    interviewDetails: gql`
    fragment interviewDetails on InterviewDetailsType {
        aiesec_to_conduct_interview
        deadline
        description
        interview_platform_url
        maximum_video_duration
        mode
        type
        video_assessment_answer
      }
   `,
   interviewSlot: gql`
    fragment interviewSlot on InterviewSlot {
        accepted
        id
        timeslot
      }
   `,
   offlineAssessment: gql`
    fragment offlineAssessment on OfflineAssessment {
        id
        question
      }
   `,
};

export const GetInterviewDetails = gql`
    query GetApplicationQuery($id: ID) {
        getApplication(id: $id) {
            id
            interview_details {
                ... interviewDetails
            }
            interview_slots {
                ... interviewSlot
            }
            offline_assessments {
                ... offlineAssessment
            }
            opportunity {
                id
                offline_assessments {
                    ... offlineAssessment
                }
            }
        }
    }
    ${fragments.interviewDetails}
    ${fragments.interviewSlot}
    ${fragments.offlineAssessment}
`

export const UpdateInterviewDetails = gql`
    mutation updateInterviewDetailsApplication($id: ID!, $opportunity_application: OpportunityApplicationInput, $attendee_emails: [String]) {
        updateInterviewDetailsApplication(id: $id, opportunity_application: $opportunity_application, attendee_emails: $attendee_emails) {
            id
            interview_details {
                ... interviewDetails
            }
            interview_slots {
                ... interviewSlot
            }
            offline_assessments {
                ... offlineAssessment
            }
            opportunity {
                id
            }
        }
    }
    ${fragments.interviewDetails}
    ${fragments.interviewSlot}
    ${fragments.offlineAssessment}
`

export const InterviewSlotApplicationMutation = gql`
    mutation InterviewSlotApplication($id: ID!, $interview_slots: [ InterviewSlotInput ]) {
        interviewSlotApplication(id: $id, interview_slots: $interview_slots) {
            ... interviewSlot
        }
    }
    ${fragments.interviewSlot}
`

export const PeopleAutocompleteEmployeesQuery = gql`
    query PeopleAutocompleteQuery($q: String) {
        peopleAutocompleteEmployees(q: $q) {
            full_name
            id
        }
    }
`

export const OfflineAssessmentApplicationMutation = gql`
    mutation OfflineAssessmentApplication($id: ID!, $offline_assessments: [ OfflineAssessmentInput ]) {
        offlineAssessmentApplication(id: $id, offline_assessments: $offline_assessments) {
            ... offlineAssessment
         }
    }
    ${fragments.offlineAssessment}
`

export const OfflineAssessmentOpportunityMutation = gql`
    mutation OfflineAssessmentOpportunity($offline_assessments: [ OfflineAssessmentInput ], $opportunity_id: ID!) {
        offlineAssessmentOpportunity(offline_assessments: $offline_assessments, opportunity_id: $opportunity_id) {
            ... offlineAssessment
         }
    }
    ${fragments.offlineAssessment}
`

