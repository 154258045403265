import gql from 'graphql-tag';

export const CandidateDetailsQuery =  gql `
    query PeopleQuery($id: ID) {
        getPerson(id: $id) {
            id
            first_name
            profile_photo
            watchlist_for_opportunity {
                opportunity {
                    id
                    title
                }
            }
            person_profile {
                backgrounds {
                  id
                  name
                  matches_with
                }
                skills {
                  constant_id
                  constant_name
                  matches_with
                }
                languages {
                  constant_id
                  constant_name
                  matches_with
                }
                nationalities {
                    id
                    name
                }
                id
            }
            professional_experiences {
                organisation_name
                title
                start_date
                end_date
                description
            }
            academic_experiences {
                organisation_name
                title
                experience_level {
                    id
                    name
                }
                start_date
                end_date
                description
            }
        }
    }
`
