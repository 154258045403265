import gql from 'graphql-tag';

const personFragment = gql`
fragment Person on Person {
    academic_experiences{
        experience_level{
                name
            }
    }
    first_name
    id
    last_active_at
    latest_academic_level {
        id
        name
    }
    person_profile {
        backgrounds {
            id
            name
            matches_with(type: "opportunity", type_id: $matchesWithTypeId) @include(if: $showMatchesWith)
        }
        id
        skills {
            constant_id
            constant_name
            matches_with(type: "opportunity", type_id: $matchesWithTypeId) @include(if: $showMatchesWith)
            option
        }
        languages {
            constant_id
            constant_name
            matches_with(type: "opportunity", type_id: $matchesWithTypeId) @include(if: $showMatchesWith)
        }
        nationalities {
            id
            name
        }
    }
    professional_experience_in_years
    profile_photo
    watchlist_for_opportunity {
        id
        opportunity {
            id
        }
    }
}
`

export const GetOpportunityApplications = gql`
query GetOpportunityApplications($only: String, $page: Int, $q: String, $with: String, $filters: ApplicationFilter, $sort: String) {
    allOpportunityApplication(only: $only, page: $page, per_page: 500, q: $q, with: $with, filters: $filters, sort: $sort) {
          paging {
            total_items,
            total_pages,
            current_page
          }
          data {
              id
              average_score
              created_at
              current_status
              custom_status {
          				id
          				name
          				position
      			  }
              permissions {
                  can_be_matched
                  can_be_rejected
              }
              person {
                  id
                  first_name
                  nationalities_ids
                  profile_photo
                  status
                  updated_at
              }
              status
              required_backgrounds_percentage
              required_skills_percentage
          }
      }
  }

`

export const OpportunityListQuery = gql`
    query OpportunityQuery($page: Int, $per_page: Int, $filters: OpportunityFilter) {
        allOpportunity(page: $page, per_page:$per_page, filters: $filters){
            data{
                id
                title
                status
            }
        }

    }
`

export const OpportunityQuery = gql`
    query OpportunityQuery($id:ID){
        getOpportunity(id:$id) {
            backgrounds {
                constant_id
                constant_name
            }
            created_at
            id
            location
            languages {
                constant_id
                constant_name
            }
            managers {
                full_name
                id
                profile_photo
            }
            nationalities {
                constant_id
                constant_name
            }
            scorecard{
                id
                name
            }
            skills {
                constant_id
                constant_name
            }
            study_levels {
                id
                name
            }
        }
    }
`

export const WatchlistQuery = gql`
    query OpportunityPotentialCandidateQuery($opportunity_id: Int){
        getWatchlist( opportunity_id: $opportunity_id){
            id
        }
    }
`;

export const PeopleQuery = gql`
    query PeopleQuery ($page:Int, $per_page:Int, $q: String, $with: String, $opportunity_id: Int, $sort: String, $filters: PeopleFilter,
        $showMatchability: Boolean!, $matchesWithTypeId: Int, $showMatchesWith: Boolean!) {
        allPeople (page: $page, per_page: $per_page, q: $q, with: $with, opportunity_id: $opportunity_id, sort: $sort, filters: $filters){
            data{
                ... Person
                required_backgrounds_percentage (opportunity_id: $opportunity_id) @include (if: $showMatchability)
                required_languages_percentage (opportunity_id: $opportunity_id) @include (if: $showMatchability)
                required_skills_percentage (opportunity_id: $opportunity_id) @include (if: $showMatchability)
            }
            paging {
                current_page
                total_items
                total_pages
            }
        }
    }
${personFragment}
`;


export const GenerateCv = gql`
    mutation generateCvQuery($id: ID) {
        generateCv(id: $id) {
            cv_url
        }
    }
`
export const CurrentPersonQuery = gql`
    query CurrentPersonQuery {
        currentPerson{
            id
            managed_branches {
                account_manager {
					id
					profile_photo
					full_name
					home_lc {
						id
						name
					}
					email
					contact_detail {
						phone
					}
				}
                branch_employees {
                    edges {
                        node {
                            id
                            status
                        }
                    }
                }
            home_lc {
                    country
                    email
                    full_name
                    id
                    name
                    parent {
                        id
                    }
                    signup_link
                    tag
                }
                id
                name
                status
            }
            managed_companies{
                id
            }
            managed_sub_branch_ids
        }
    }
`

export const GetOrganisationForScorecards = gql`
    query GetOrganisationForScorecards($id: ID!) {
        getOrganisation(id: $id) {
            id
            scorecards {
                edges {
                    node {
                        id
                        name
                        permissions{
                            can_delete
                        }
                        scorecard_attributes {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`

export const OpportunitiesStats2Query = gql`
    query stats2 {
        stats2
    }
`;

const branchFragment = gql`
    fragment branch on Branch {
        address_detail {
            address_1
            address_2
            city
            country
            id
            postcode
        }
        id
        name
        home_lc {
            id
            name
            parent {
                id
                name
            }
        }
        status
        scope
    }
`

export const GetBranchesQuery  = gql`
    query getBranchesQuery($id: ID!) {
        getOrganisation(id: $id) {
            id
            branches {
                edges {
                    node {
                     ... branch
                     parent {
                         ... branch
                         parent {
                             ...branch
                         }
                     }
                    }
                }
            }
        }
    } ${branchFragment}
`;
