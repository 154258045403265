import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { Router } from '@angular/router';
import { CreateOrganisationScorecardQuery} from './add-scorecard.graphql';
import { GetOrganisationForScorecards } from './../../graphql/queries';
import { AppApolloService } from 'app/services/app.apollo.service';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { ErrorService } from 'app/services/error.service';


@Component({
  selector: 'app-add-scorecard',
  templateUrl: './add-scorecard.component.html',
  styleUrls: ['./add-scorecard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddScorecardComponent implements OnInit {

  @ViewChild('addScorecardModal', { static: true }) addScorecardModal;
  newScorecard = {
    name: '',
    scorecard_attributes: []
  };
  createScorecardCalled: boolean;
  createScorecardLoading: boolean;
  organisationId: any;


  constructor(
  private userService: UserService,
  private router: Router,
  private apollo: Apollo,
  private appApollo: AppApolloService,
  private errorService: ErrorService) {}

  ngOnInit() {
    this.organisationId = this.userService.currentUser.managed_companies[0].id;
  }

  addScoringPoint() {
      this.newScorecard['scorecard_attributes'].push({ 'name': '' });
  }

  createScorecard() {
    this.createScorecardCalled = true;
    this.createScorecardLoading = true;
    if (this.newScorecard && this.newScorecard['name'] ) {
      if (this.newScorecard['scorecard_attributes'].length) {
        const emptyAttribute = this.newScorecard['scorecard_attributes'].find(item => !item.name);
          if (emptyAttribute) {
            this.createScorecardLoading = false;
            return;
          }
      } else {
        this.createScorecardLoading = false;
        return;
      }
    } else  {
        this.createScorecardLoading = false;
        return;
    }

    const variables = {
      company_id: +this.organisationId,
      name: this.newScorecard['name'],
      organisation_scorecard_attribute: this.newScorecard['scorecard_attributes']
    }

    this.apollo.mutate({
      mutation: CreateOrganisationScorecardQuery,
      variables: variables,
      update: (store, {data: response}) => {
        const data: any = store.readQuery({query: GetOrganisationForScorecards, variables: {id: this.organisationId}});
        data['getOrganisation'].scorecards.edges.push(
          {
            node: response.organisationScorecardCreate,
            __typename: 'ScorecardEdge'
          }
        );
        store.writeQuery({ query: GetOrganisationForScorecards, variables: {id: this.organisationId}, data });
      }
    })
    .pipe(
      map((res: any) => res.data)
    )
    .subscribe((res) => {
      this.createScorecardLoading = false;
      this.createScorecardCalled = false;
      this.dismissModal()
    }, err => {
      this.createScorecardLoading = false;
      this.createScorecardCalled = false;
      this.errorService.showErrorModal(err.networkError);
    })

  }

  dismissModal() {
    this.addScorecardModal.hide();
    this.router.navigate(['/organisation/process/scorecards']);
  }

}
