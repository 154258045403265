import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import gql from 'graphql-tag';
import { AppApolloService } from 'app/services/app.apollo.service';

const OrganisationQuery = gql`
  query allOrganisation($per_page: Int, $sort: String, $filters: CompanyFilter) {
    allOrganisation(per_page: $per_page, sort: $sort, filters: $filters) {
      data {
        id
        profile_photo
      }
    }
  }
`

@Component({
  selector: 'app-general-partners-expanded',
  templateUrl: './general-partners-expanded.component.html',
  styleUrls: ['./general-partners-expanded.component.scss']
})
export class GeneralPartnersExpandedComponent implements OnInit {

  globalPartners: Array<any>;
  regionalPartners: Array<any>;
  expand: any = {};

  constructor(private router: Router, private appApollo: AppApolloService) {}

  ngOnInit() {
    this.fetchGlobalPartners();
    this.fetchRegionalPartners();
  }

  fetchGlobalPartners() {
    const variables = {
      per_page: 50,
      sort: 'openings',
      filters: {
        partner_type: ['global']
      }
    }
    this.appApollo.query(OrganisationQuery, variables)
    .then((res: any) => {
      this.globalPartners = res.allOrganisation.data;
    });
  }

  fetchRegionalPartners() {
    const variables = {
      per_page: 50,
      sort: 'openings',
      filters: {
        partner_type: ['regional']
      }
    }
    this.appApollo.query(OrganisationQuery, variables)
    .then((res: any) => {
      this.regionalPartners = res.allOrganisation.data;
    });
  }

  goToOrganisation(id) {
    this.router.navigateByUrl(`/companies/${id}`);
  }

}
