
import {takeWhile,  map } from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GetScorecardQuery,
  UpdateOrganisationScorecardName,
  UpdateOrganisationScorecardAttribute,
  OrganisationScorecardAttributeCreate,
  ScorecardAttributeDeleteMutation } from './edit-scorecard.graphql';
import { GetOrganisationForScorecards } from './../../graphql/queries';
import { AppApolloService } from 'app/services/app.apollo.service';
import { Apollo } from 'apollo-angular';
import { ErrorService } from 'app/services/error.service';
import { UserService } from 'app/services/user.service';
import { ConfirmationService } from 'app/services/confirmation.service';
@Component({
  selector: 'app-edit-scorecard',
  templateUrl: './edit-scorecard.component.html',
  styleUrls: ['./edit-scorecard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditScorecardComponent implements OnInit {
  @ViewChild('editScorecardModal', { static: true }) editScorecardModal;
  componentAlive: boolean = true;
  selectedScorecard;
  attributeUpdating = {}
  scorecardNameUpdating: boolean;
  organisationId: any;
  scorecards: any;
  deleteLoading: boolean;
  selectedAttributeId: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private appApollo: AppApolloService,
    private apollo: Apollo,
    private errorService: ErrorService,
    private userService: UserService,
    private confirmationService: ConfirmationService) {
      this.organisationId = this.userService.currentUser.managed_companies[0].id;
      if (this.organisationId) {
        const variables = {
          id: this.organisationId
        }
        this.appApollo.watchQuery(GetOrganisationForScorecards, variables).pipe(
        takeWhile(() => this.componentAlive))
        .subscribe((data: any) => {
          const organisation = data.getOrganisation;
          const scorecardsConnection = organisation.scorecards;
          if (scorecardsConnection && scorecardsConnection.edges && scorecardsConnection.edges.length) {
            this.scorecards = scorecardsConnection.edges;
          }
        }, err => {
          this.errorService.showErrorModal(err.networkErr);
        })
      }
     }

  ngOnInit() {
    this.route.params.pipe(
    takeWhile(() => this.componentAlive))
    .subscribe(params => {
        if (params.id) {
          const variables = {
            id: params.id
          }
          this.appApollo.query(GetScorecardQuery, variables)
          .then((res) => {
            this.selectedScorecard = JSON.parse(JSON.stringify(res['scorecardGet']));
          })
        }
    });
  }

  dismissModal() {
    this.editScorecardModal.hide();
    this.router.navigate(['/organisation/process/scorecards']);
  }

  addScoringPoint() {
    if (!this.selectedScorecard.scorecard_attributes.length) {
      this.selectedScorecard.scorecard_attributes.push({ 'name': '' });
    } else {
      if (this.selectedScorecard.scorecard_attributes[this.selectedScorecard.scorecard_attributes.length - 1].name) {
        this.selectedScorecard.scorecard_attributes.push({ 'name': '' });
      }
    }
  }

  isPointName() {
    const nameArr = this.selectedScorecard.scorecard_attributes.map((item) => { return item.name });
    const result = this.selectedScorecard.scorecard_attributes.filter((value, index) => {
      return nameArr.indexOf(value.name) !== index && (value.isDuplicate = true) || (value.isDuplicate = false);
    });
    return result.length === 0 ? false : true;
  }

  saveScorecardName(event) {
    const variables = {
        id: this.selectedScorecard.id,
        name: this.selectedScorecard.name
    }

    if (this.selectedScorecard.name !== '') {
      this.scorecardNameUpdating = true;
      this.apollo.mutate({
        mutation: UpdateOrganisationScorecardName,
        variables: variables,
        update: (store, {data: response}) => {
          response = JSON.parse(JSON.stringify(response['organisationScorecardUpdate']));
          const data: any = store.readQuery({query: GetOrganisationForScorecards, variables: {id: this.organisationId}});
          for (const scorecard of data['getOrganisation'].scorecards.edges) {
            if (scorecard.node.id ===  response['id']) {
              scorecard.node = response;
              break;
            }
          }
          store.writeQuery({ query: GetOrganisationForScorecards, variables: {id: this.organisationId}, data });
        }
      })
      .pipe(
        map((res: any) => res.data)
      )
      .subscribe((scorecard) => {
        this.selectedScorecard = JSON.parse(JSON.stringify(scorecard['organisationScorecardUpdate']))
        this.scorecardNameUpdating = false;
      }, err => {
        this.errorService.showErrorModal(err.networkError);
      })
    } else {
       const scorecard = JSON.parse(JSON.stringify(this.scorecards.find(item => item.node.id === this.selectedScorecard.id)));
       this.selectedScorecard = scorecard.node;
    }
  }

  createAttribute(attribute) {
    const variables = {
      scorecard_id: this.selectedScorecard.id,
      organisation_scorecard_attribute: {
        name: attribute.name
      }
    }
    this.attributeUpdating[this.selectedScorecard.scorecard_attributes.length - 1] = true;
    this.apollo.mutate({
      mutation: OrganisationScorecardAttributeCreate,
      variables: variables,
      update: (store, {data: response}) => {
        response = JSON.parse(JSON.stringify(response['organisationScorecardAttributeCreate']));
        const data: any = store.readQuery({query: GetOrganisationForScorecards, variables: {id: this.organisationId}});
        for (const scorecard of data['getOrganisation'].scorecards.edges) {
          if (scorecard.node.id ===  response['id']) {
            scorecard.node = response;
            break;
          }
        }
        store.writeQuery({ query: GetOrganisationForScorecards, variables: {id: this.organisationId}, data });
      }
    })
    .pipe(
      map((res: any) => res.data)
    )
    .subscribe((scorecard) => {
      scorecard = scorecard['organisationScorecardAttributeCreate'];
      this.selectedScorecard = scorecard;
      this.attributeUpdating[this.selectedScorecard.scorecard_attributes.length - 1] = false;
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })

  }

  saveAttribute(id) {
    if (this.isPointName()) {
      return;
    }
    let attribute;
    if (id) {
      attribute = this.selectedScorecard.scorecard_attributes.find(item => item.id === id);
    } else {
      attribute = this.selectedScorecard.scorecard_attributes[this.selectedScorecard.scorecard_attributes.length - 1];
      this.createAttribute(attribute);
      return;
    }
    if (!attribute.name) {
      const scorecard = JSON.parse(JSON.stringify(this.scorecards.find(item => item.node.id === this.selectedScorecard.id)));
      this.selectedScorecard = scorecard.node;
    } else {
      this.attributeUpdating[attribute.id] = true;
      this.apollo.mutate({
        mutation: UpdateOrganisationScorecardAttribute,
        variables: attribute,
        update: (store, {data: response}) => {
          response = response['organisationScorecardAttributeUpdate'];
          const data: any = store.readQuery({query: GetOrganisationForScorecards, variables: {id: this.organisationId}});
          for (const scorecard of data['getOrganisation'].scorecards.edges) {
            if (scorecard.node.id ===  this.selectedScorecard.id) {
              for (const element of scorecard.node.scorecard_attributes) {
                if (element.id === response['id']) {
                  element.name = response.name;
                  break;
                }
              }
              break;
            }
          }
          store.writeQuery({ query: GetOrganisationForScorecards, variables: {id: this.organisationId}, data });
        }
      })
      .pipe(
        map((res: any) => res.data)
      )
      .subscribe((res) => {
        res = res['organisationScorecardAttributeUpdate'];
        this.attributeUpdating[res['id']] = false;
        for (const element of this.selectedScorecard.scorecard_attributes) {
          if (element.id === res['id']) {
            element.name = res.name;
            break;
          }
        }
      }, err => {
        this.errorService.showErrorModal(err.networkError);
      })
    }
  }


  removeScorecardAttribute(attributeId) {
    this.deleteLoading = true;
    this.selectedAttributeId = attributeId;
    this.apollo.mutate({
      mutation: ScorecardAttributeDeleteMutation,
      variables: {id: +attributeId},
      update: (store, {data: response}) => {
        const data: any = store.readQuery({query: GetOrganisationForScorecards, variables: {id: this.organisationId}})
        if (+data['getOrganisation'].id === +this.organisationId) {

          for (const scorecard of data['getOrganisation'].scorecards.edges) {
            if (scorecard.node.id ===  this.selectedScorecard.id) {
              this.selectedScorecard.scorecard_attributes = this.selectedScorecard.scorecard_attributes
                                                            .filter(attribute =>
                                                              attribute.id !== this.selectedAttributeId
                                                            );
              scorecard.node.scorecard_attributes = this.selectedScorecard.scorecard_attributes;
              break;
            }
          }
        }
        store.writeQuery({ query: GetOrganisationForScorecards, variables: {id: this.organisationId}, data});
      }
    })
    .subscribe(response => {
      this.deleteLoading = false;
      this.selectedAttributeId = null;
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  confirmRemoval(attributeId) {
    if (attributeId) {
      const title = `Delete this criterion?`;
      const details = `You are about to delete a scoring criterion.
      Once deleted, criterion attached to this scorecard will not appear as part of the scoring criteria.`;
      this.confirmationService.showConfirmationModal(title, details);

      let isAlive = true;
      this.confirmationService.isConfirmed
      .takeWhile(() => isAlive)
      .subscribe(isConfirm => {
        if (isConfirm) {
          isAlive = false;
          this.removeScorecardAttribute(attributeId);
        } else if (isConfirm === false) {
            isAlive = false;
        }
      })
    }
  }

}
