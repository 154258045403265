
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output, ViewChild } from '@angular/core';
import { ErrorService } from 'app/services/error.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

const RemoveOpportunity = gql`
mutation removeOpportunity($id: ID!) {
    removeOpportunity(id: $id) {
        id
        status
    }
}
`;

const OpportunityUnarchive = gql`
mutation opportunityUnarchive($id: ID!) {
    opportunityUnarchive(id: $id) {
        id
        status
    }
}
`;

@Component({
  selector: 'app-op-archive-modal',
  templateUrl: './op-archive-modal.component.html',
  styleUrls: ['./op-archive-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class OpArchiveModalComponent implements OnInit {

  @ViewChild('archiveModal', { static: true }) archiveModal: ModalDirective;
  @Input() archive = true;
  @Input() opId: number;
  @Input() opListQuery: any;
  @Input() opListvariables: any;
  @Input() listName: string;
  @Output() archived: EventEmitter<any> = new EventEmitter();
  @Output() unarchived: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  loading = false;

  constructor(
    private apollo: Apollo,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
  }

  archiveOpportunity() {
    this.loading = true;
    this.apollo.mutate({
      mutation: RemoveOpportunity,
      variables: { id: this.opId },
      update: (store, {data: response}) => {
        const data: any = this.getDataFromStore(store);
        const index = data[this.listName].data.findIndex(o => +o.id === +response.removeOpportunity.id);
        if (index > -1) {
          data[this.listName].data.splice(index, 1);
        }
        this.writeDataToStore(store, data);
      }
    }).pipe(
    map(data => data.data.removeOpportunity))
    .subscribe(opportunity => {
      this.loading = false;
      // this.archived.emit(opportunity);
      this.onClose();
    }, error => {
      this.loading = false;
      this.errorService.showErrorModal(error.networkError);
    })
  }

  unarchiveOpportunity() {
    this.loading = true;
    this.apollo.mutate({
      mutation: OpportunityUnarchive,
      variables: { id: this.opId },
    }).pipe(
    map(data => data.data.removeOpportunity))
    .subscribe(opportunity => {
      this.loading = false;
      this.unarchived.emit(opportunity);
      this.onClose();
    }, error => {
      this.loading = false;
      this.errorService.showErrorModal(error.networkError);
    })
  }

  onClose() {
    this.archiveModal.hide();
    this.close.emit();
  }

  getDataFromStore(store) {
    return store.readQuery({query: this.opListQuery, variables: this.opListvariables});
  }

  writeDataToStore(store, data) {
    store.writeQuery({query: this.opListQuery, variables: this.opListvariables, data});
  }

}
