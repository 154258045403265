import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterField'
})
export class FilterFieldPipe implements PipeTransform {

  transform(items: any, filter: any): any {
    if (!items || !filter || !filter.value) {
      return items;
    }
    return items.filter(item => item[filter.field] && item[filter.field].toLowerCase() === filter.value.toLowerCase());
  }

}
