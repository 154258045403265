import gql from 'graphql-tag';

const fragments = {
    opApplication: gql`
      fragment opApplication on OpportunityApplication {
        application_scorecard {
            application_scorecard_attributes {
                id
                score
                scorecard_attribute {
                    id
                    name
                }
            }
            created_at
            id
            person {
                id
                first_name
                full_name
            }
            updated_at
        }
        average_score
        created_at
        current_status
        custom_status {
            id
            name
            position
        }
        cv {
            id
            url
        }
        id
        interview_details {
            aiesec_to_conduct_interview
            deadline
            type
        }
        interview_slots {
            accepted
            id
            timeslot
        }
        managers {
            full_name
            id
            profile_photo
        }
        meta {
            gip_answer
        }
        opportunity {
            id
            backgrounds {
                constant_id
                constant_name
                id
                option
            }
            languages {
                constant_id
                constant_name
                id
                option
            }
            scorecard {
                id
                name
                scorecard_attributes {
                    id
                    name
                }
            }
            skills {
                constant_id
                constant_name
                id
                option
            }
            specifics_info {
                ask_gip_question
            }
            programme {
              id
              short_name_display
            }
        }
        permissions {
            can_be_rejected
        }
        person {
            academic_experiences {
                country
                description
                end_date
                experience_level {
                    id
                    name
                }
                id
                organisation_name
                start_date
            }
            address_detail {
                city
                country
                id
            }
            cv {
                id
                url
            }
            first_name
            full_name
            id
            person_profile {
                backgrounds {
                    id
                    name
                }
                id
                languages {
                    constant_id
                    constant_name
                    id
                }
                nationalities {
                    id
                    name
                }
                skills {
                    constant_id
                    constant_name
                    id
                    option
                }
            }
            professional_experiences {
                country
                description
                end_date
                experience_level {
                    id
                    name
                }
                id
                organisation_name
                start_date
                title
            }
            profile_photo
        }
        required_backgrounds_percentage
        required_skills_percentage
        rejection_reason {
            id
            name
        }
        status
      }
    `,
    rejectionFields: gql`
      fragment rejectionFields on OpportunityApplication {
        average_score
        created_at
        current_status
        custom_status {
            id
            name
            position
        }
        id
        managers {
            full_name
            id
            profile_photo
        }
        permissions {
            can_be_rejected
        }
        person {
            first_name
            full_name
            id
            person_profile {
                id
                nationalities {
                    id
                    name
                }
            }
            profile_photo
        }
        required_backgrounds_percentage
        required_skills_percentage
        rejection_reason {
            id
            name
        }
        status
      }
  `
};

export const GetOpApplication = gql`
    query GetApplicationQuery($id: ID) {
        getApplication(id: $id) {
            ... opApplication
        }
    }
${fragments.opApplication}
`

export const UpdateOpApplication = gql`
    mutation UpdateOpApplication($id: ID!, $filters: OpportunityApplicationInput) {
        updateApplication(id: $id, opportunity_application: $filters) {
            ... opApplication
        }
    }
${fragments.opApplication}
`


export const AppStatusMutations = {
    RejectApplication: gql`
        mutation RejectApplication($id: ID!) {
            rejectApplication(id: $id) {
                ... opApplication
            }
        }
    ${fragments.opApplication}
    `,
    RejectApplications: gql`
        mutation RejectApplications($application_ids: [ Int ]) {
            bulkRejectApplications(application_ids: $application_ids) {
                ... opApplication
            }
        }
    ${fragments.opApplication}
    `
}

export const CreateWatchlist = gql`
    mutation CreateWatchlist($potential_candidate: OpportunityPotentialCandidate, $opportunity_id: Int) {
        createWatchlist(potential_candidate: $potential_candidate, opportunity_id: $opportunity_id) {
            id
            person {
                id
            }
        }
    }
`

export const CreateScorecardsApplication = gql`
    mutation CreateScorecardsApplication($id: ID!, $application_scorecard: [ ScorecardInput ]) {
        createScorecardsApplication(id: $id, application_scorecard: $application_scorecard) {
            ... opApplication
        }
    }
    ${fragments.opApplication}
`

export const UpdateScorecardAttribute = gql`
    mutation UpdateApplicationScorecardAttribute($id: ID!, $application_scorecard: ScorecardInput) {
        updateApplicationScorecardAttribute(id: $id, application_scorecard: $application_scorecard) {
            ... opApplication
        }
    }
    ${fragments.opApplication}
`

export const GetRejectionReasons = gql`
    query allConstants($type_id: String) {
      allConstants(type_id: $type_id) {
        id
        name
        meta
        type_id
        type_name
        parent_id
      }
    }
`;

export const RejectApplication = gql`
    mutation rejectApplication($id: ID!, $rejection_reason_id: Int) {
      rejectApplication(id: $id, rejection_reason_id: $rejection_reason_id) {
        ... rejectionFields
      }
    }
    ${fragments.rejectionFields}
`
