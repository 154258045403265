import { OnDestroy } from '@angular/core';
import * as _ from 'lodash';

export class GhostModelService implements OnDestroy {

  ngOnDestroy() {
    this.store = null;
  }

  store: any = null;
  setData(data: any) {
    this.store = data;
  }

  getData() {
    return this.store;
  }

  superCompare(objectA, objectB) {
    let context = {};

    let allKeysObject = _.assignIn({}, objectA, objectB);

    for (let k in objectA) {
      delete allKeysObject[k];
      // console.log(k);
      let valueA = objectA[k];
      let valueB = objectB[k];
      if (valueA !== undefined && valueB !== undefined && !(_.isEqual(valueA, valueB))) {
        if ((typeof valueA == 'object' || typeof valueB == 'object') && !Array.isArray(valueA)) {
          if (valueA) {
            let temp = this.superCompare(valueA, valueB);
            if (Object.keys(temp).length != 0) context[k] = temp;
          } else {
            context[k] = valueB;
          }
        } else if (valueA != valueB) {
          context[k] = valueB;
        }
      }
    }

    for (let p in allKeysObject) {
      if (objectB[p] !== 'undefined') {
        context[p] = objectB[p];
      }
    }
    return context;
  }
}
