import gql from 'graphql-tag';

const fragments = {
  typeDetails: gql`
    fragment ConstantMap on ConstantMap {
      constant_id
      constant_name
      option
      level
    }
  `,
  branchDetails: gql`
    fragment BranchDetails on Branch {
      id
      company {
        id
        name
        profile_photo
      }
      address_detail {
        city
        country
      }
      name
      status
    }
  `
}

export const GetOpportunityDetails = gql`
query getOpportunityDetails($id: ID) {
  getOpportunity(id: $id) {
    id
    applicants_count
    applications_close_date
    application_processing_time
    available_openings
    cover_photo
    duration
    earliest_start_date
    latest_end_date
    programme {
      id
      short_name_display
    }
    measure_of_impacts {
      id
      constant_id
      option
      constant_name
    }
    home_lc {
      id
      full_name
      email
    }
    location
    title
    reviews
    role_info {
      learning_points_list
      learning_points
    }
    description
    work_hours
    specifics_info {
      salary
      salary_currency {
          id
          alphabetic_code
          name
      }
      salary_periodicity
    }
    lat
    lng
    logistics_info {
      accommodation_covered
      accommodation_provided
      computer_provided
      food_covered
      food_provided
      no_of_meals
      transportation_covered
      transportation_provided
    }
    permissions {
      can_update
    }
    google_place_id
    office_footfall_for_exchange
    sdg_info {
      id
      sdg_target {
        id
        goal_index
        description
      }
    }
    sub_product {
      id
      name
    }
    selection_processes {
      edges {
        node {
          id
          title
          no_of_days
          position
        }
      }
    }
    status
    current_status
    backgrounds {
      ... ConstantMap
    }
    languages {
      ... ConstantMap
    }
    nationalities {
      ... ConstantMap
    }
    skills {
      ... ConstantMap
    }
    study_levels {
      id
      name
    }
    branch {
      ... BranchDetails
    }
    questionnaire {
      id
      name
    }
    company_description 
    organisation {
      id
      name
    }
  }
}
${fragments.typeDetails}
${fragments.branchDetails}
`;


// MUTATION

export const UpdateOpportunityCoreInfo = gql`
mutation updateOpportunity($id: ID!, $opportunity: OpportunityInput) {
    updateOpportunity(id: $id, opportunity: $opportunity) {
        id
        title
        cover_photo
        status
    }
}
`

export const RemoveOpportunity = gql`
mutation removeOpportunity($id: ID!) {
    removeOpportunity(id: $id) {
        id
        status
    }
}
`

export const UnarchiveOpportunity = gql`
mutation opportunityUnarchive($id: ID!) {
    opportunityUnarchive(id: $id) {
        id
        status
    }
}
`
