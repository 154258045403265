import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'app/guards/auth.guard';
import { OnboardedGuard } from 'app/guards/onboarded.guard';

import { PageNotFoundComponent } from 'app/blocks/page-not-found/page-not-found.component';
import { MyDashboardComponent } from './content/myDashboard/myDashboard.component';
import { ApprovedUserGuard } from './guards/approved-user.guard';

const APP_ROUTES: Routes = [
    { path: '', component: MyDashboardComponent, pathMatch: 'full', canActivate: [AuthGuard]},
    { path: 'search', loadChildren: () => import('./content/search/search.module').then(m => m.SearchModule) },
    // { path: 'onboarding', loadChildren: './content/onboarding/onboarding.module#OnboardingModule'},
    { path: 'opportunity', loadChildren: './content/opportunity/opportunity.module#OpportunityModule',
      canActivate: [AuthGuard, OnboardedGuard]
    },
    { path: 'profile', loadChildren: './content/profile/profile.module#ProfileModule', canActivate: [AuthGuard] },
    { path: 'organisation', loadChildren: './content/organisation/organisation.module#OrganisationModule',
      canActivate: [AuthGuard, OnboardedGuard, ApprovedUserGuard]
    },
    { path: 'partner', loadChildren: './content/partners/partners.module#PartnersModule' },
    { path: 'company', loadChildren: './content/company/company.module#CompanyModule',  canActivate: [AuthGuard] },
    { path: 'welcome', loadChildren: './content/welcome/welcome.module#WelcomeModule',  canActivate: [AuthGuard] },
    { path: 'page-not-found', component: PageNotFoundComponent},
    {
      path: 'blog',
      redirectTo: '/',
    },
    { path: '**', redirectTo: '/page-not-found' }

];

export const appRouting = RouterModule.forRoot(APP_ROUTES, {enableTracing: false});
