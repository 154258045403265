import {
  GetOpportunityApplicationQuestionnaires,
  AssignApplicationQuestionnaires
} from './../../content/opportunity/applications/applications.graphql';
import {map} from 'rxjs/operators';
import { AppApolloService } from 'app/services/app.apollo.service';
import { GetOpportunity } from './../opportunity-navbar/opportunity-navbar.graphql';
import { Apollo } from 'apollo-angular';
import { ErrorService } from './../../services/error.service';
import { AssignQuestionnaire } from './../../content/organisation/organisation.graphql';
import { GetQuestionnaireNamesQuery } from './../../content/organisation/organisation.graphql';
import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { OrganisationService } from 'app/services/organisation.service';
import { GetOpportunityDetails } from 'app/content/opportunity/profile/opportunity-profile.graphql';

@Component({
  selector: 'app-assign-questionnaire',
  templateUrl: './assign-questionnaire.component.html',
  styleUrls: ['./assign-questionnaire.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AssignQuestionnaireComponent implements OnInit {

  @Input() opportunityId;
  @Input() applicationId;
  @Input() organisationId;
  @Input() assignRequestOrigin;
  @Input() applicationQuestionnaires;
  loading: boolean;
  questionnaireArray = [];
  @Output() closeQuestionnaireModal: EventEmitter<any> = new EventEmitter();
  activeQuestionnaire = {
    id: null,
    text: null
  };
  selectedQuestionnaire: any;
  deadline: any;
  storeQueryToUpdate: any = GetOpportunity;
  bsDatePickerConfig = {
    minDate: new Date()
  };
  showError: boolean;
  showDeadline: boolean;

  constructor(
    private apollo: Apollo,
    private appApollo: AppApolloService,
    private organisationService: OrganisationService,
    private errorService: ErrorService
    ) { }

  ngOnInit() {
    // this.getQuestionnaires();
    this.getOrganisationId();
    switch (this.assignRequestOrigin) {
      case 'op-status-navbar':
        this.storeQueryToUpdate = GetOpportunityDetails;
        break;
      case 'questionnaire-response':
        this.storeQueryToUpdate = GetOpportunityApplicationQuestionnaires;
        break;
    }
  }

  getOrganisationId() {
    this.organisationService.myOrganisationId.subscribe( async (id) => {
      if (id) {
        this.organisationId = id;
        await this.getQuestionnaires();
      }
    })
  }

  getQuestionnaires() {
    this.loading = true;

    this.appApollo.watchQuery(GetQuestionnaireNamesQuery, { id: this.organisationId })
    .subscribe( async (response) => {
      const questionnaireEdges = response['getOrganisation'].questionnaires.edges;
      this.questionnaireArray = questionnaireEdges
                                .map((item: any) => (item.node))
                                .map((item: any) => ({id: item.id, text: item.name}));
      await this.getOpportunityQuestionnaire();
      this.loading = false;
    }, err => {
      console.log('error', err)
      this.errorService.showErrorModal(err.networkError);
    })
  }

  getOpportunityQuestionnaire() {
    this.appApollo.query(GetOpportunity, {id: this.opportunityId})
    .then(response => {
      const questionnaire = response['getOpportunity'].questionnaire;
      if (questionnaire) {
        this.activeQuestionnaire = {
          id: questionnaire.id,
          text: questionnaire.name
        };
        // this.selectedQuestionnaire = this.activeQuestionnaire;
      }
      this.filterOutAssignedQuestionnaires();
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  validate() {
    if (this.applicationId && this.assignRequestOrigin === 'questionnaire-response') {
      if (this.showDeadline && !this.deadline) {
        this.showError = true;
        return;
      }
      if (this.selectedQuestionnaire) {
        this.showError = false;
        this.assignApplicationQuestionnaire();
      } else {
        this.showError = true;
      }
    } else {
      this.assignQuestionnaire();
    }
  }

  assignApplicationQuestionnaire() {
    const variables = {
      id: +this.applicationId,
      opportunity_application: {
        questionnaires_to_add: [{
          questionnaire_id: +this.selectedQuestionnaire.id,
          deadline: this.deadline ? this.deadline : null
        }]
      }
    }
    const storeVariables = {id: this.applicationId}
    this.apollo.mutate({
      mutation: AssignApplicationQuestionnaires,
      variables: variables,
      update: (store, {data: response}) => {
        const data: any = this.readDataFromStore(store, storeVariables);
        if (data && (+data['getApplication'].id === +this.applicationId)) {
          data['getApplication'].application_questionnaires = response['updateApplication'].application_questionnaires;
        }
        this.writeDataToStore(store, storeVariables, data);
      }
    }).pipe(
    map((res: any) => res.data))
    .subscribe(response => {
      const questionnaire = response['updateApplication'].application_questionnaires;
      this.activeQuestionnaire = {id: questionnaire.id, text: questionnaire.name};
      this.filterOutAssignedQuestionnaires();
      this.closeModal();
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  assignQuestionnaire() {
    const storeVariables = {id: this.opportunityId};
    this.apollo.mutate({
      mutation: AssignQuestionnaire,
      variables: {id: +this.opportunityId, questionnaire_id: +this.selectedQuestionnaire.id},
      update: (store, {data: response}) => {
        const data: any = this.readDataFromStore(store, storeVariables);
        if (+data['getOpportunity'].id === +this.opportunityId) {
          data['getOpportunity'].questionnaire = response['assignQuestionnaire'].questionnaire;
          this.writeDataToStore(store, storeVariables, data);
        }
      }
    }).pipe(
    map((res: any) => res.data))
    .subscribe(response => {
      const questionnaire = response['assignQuestionnaire'].questionnaire;
      this.activeQuestionnaire = {id: questionnaire.id, text: questionnaire.name};
      this.filterOutAssignedQuestionnaires();
      this.closeModal();
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  readDataFromStore(store, variables) {
    return store.readQuery({query: this.storeQueryToUpdate, variables: variables});
  }

  writeDataToStore(store, variables, data) {
    store.writeQuery({query: this.storeQueryToUpdate, variables: variables, data});
  }

  filterOutAssignedQuestionnaires() {
    let assignedQuestionnaires = [];
    if (this.applicationQuestionnaires) {
      assignedQuestionnaires = this.applicationQuestionnaires.map(item => item.questionnaire_id);  // Filter out application questionnaire
    } else {
      assignedQuestionnaires = this.activeQuestionnaire ? [+this.activeQuestionnaire.id] : [];  // Filter out opportunity questionnaire
    }
    this.questionnaireArray = this.questionnaireArray.filter(item => !assignedQuestionnaires.includes(+item.id));
  }

  questionnaireSelected(questionnaire) {
    this.selectedQuestionnaire = questionnaire;
  }

  selectDeadline(preference) {
    if (!preference) {
      this.deadline = null;
    }
    this.showDeadline = preference;
  }

  closeModal() {
    this.closeQuestionnaireModal.emit();
  }

}
