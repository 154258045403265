import gql from 'graphql-tag';

export const GetOpApplication = gql`
    query GetApplicationQuery($id: ID) {
        getApplication(id: $id) {
            id
            created_at
        }
    }
`
