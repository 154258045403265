import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Error} from 'app/blocks/error-modal/error.constants';

@Injectable()
export class ErrorService {

  showModal = new BehaviorSubject(null);
  error: any;
  customMessage: string;
  customAction: string;

  constructor() {
  }

  showErrorModal(error, customMessage?, customAction?) {
    if (error) {
      this.error = new Error(error);
    }
    if (customMessage) {
      this.customMessage = customMessage;
    }
    if (customAction) {
      this.customAction = customAction;
    }
    this.showModal.next(true);
  }

  hideErrorModal() {
    this.error = null;
    this.customMessage = null;
    this.customAction = null;
    this.showModal.next(false);
  }

}
