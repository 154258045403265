import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../environments/environment';
import { ActionBannerService } from './action-banner.service';

@Component({
  selector: 'app-action-banner',
  templateUrl: './action-banner.component.html',
  styleUrls: ['./action-banner.component.scss']
})
export class ActionBannerComponent implements OnInit, OnDestroy {
  hideActionBanner: boolean;
  cdnPath = environment.cdnUrl;
  componentAlive = true;

  constructor(
    private actionBannerService: ActionBannerService
  ) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.componentAlive = false;
  }

  closeActionBanner() {
    this.hideActionBanner = true;
    this.actionBannerService.hideActionBanner();
  }

}
