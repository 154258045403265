export function convertFieldToText(field) {
    switch (field) {
        case 'title':
            return 'Title'
        case 'description':
            return 'Description'
        case 'location':
            return 'Location'
        case 'earliest_start_date':
            return 'Earliest Start Date'
        case 'google_place_id':
            return 'Google Place Id'
        case 'latest_end_date':
            return 'Latest End Date'
        case 'openings':
            return 'Number of Openings'
        case 'skills':
            return 'Atleast One Skill'
        case 'backgrounds':
            return 'Atleast One Background'
        case 'languages':
            return 'Atleast One Language'
        case 'measure_of_impacts':
            return 'Measure of Impact for SDG'
        case 'food_covered':
            return 'Logistics Info - Food Covered'
        case 'food_weekends':
            return 'Logistics Info - Food on Weekends'
        case 'accommodation_covered':
            return 'Logistics Info - Accomodation Covered'
        case 'accommodation_provided':
            return 'Logistics Info - Accomodation Provided'
        case 'visa_link':
            return 'Legal Info - Visa Link'
        case 'visa_type':
            return 'Legal Info - Visa Type'
        case 'visa_duration':
            return 'Legal Info - Visa Duration'
        case 'health_insurance_info':
            return 'Legal Info - Health Insurance Info'
        case 'learning_points_list':
            return 'Learning Points'
        case 'selection_process':
            return 'Selection Process'
        case 'salary':
            return 'Specifics Info - Salary'
        case 'salary_currency':
            return 'Specifics Info - Salary Currency'
        case 'salary_periodicity':
            return 'Specifics Info - Salary Periodicity'
        case 'saturday_work':
            return 'Specifics Info - Saturday Work'
        case 'expected_work_schedule':
            return 'Specifics Info - Expected Work Schedule'
        case 'sdg_target':
            return 'SDG Target'
        default:
            return field.replace('_', '');
    }
}

export function getAppStatusClass(status) {
    switch(status) {
        case 'open':
            return 'applied'
        case 'matched':
            return 'selected'
        case 'accepted':
            return 'selected'
        case 'approved_ep_manager':
            return 'selected'
        case 'approved_tn_manager':
            return 'selected'
        case 'approved':
            return 'approved'
        case 'realized':
            return 'hired'
        case 'completed':
            return 'hired'
        case 'rejected':
            return 'rejected'
        case 'withdrawn':
            return 'withdrawn'
        case 'approval_broken':
            return 'withdrawn'
        case 'realization_broken':
        case 'remote_realization_broken':
            return 'withdrawn'
        case 'declined':
            return 'withdrawn'     
        default:
            return ''
    }
  }