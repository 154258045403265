import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { AppApolloService } from 'app/services/app.apollo.service';
import { ErrorService } from 'app/services/error.service';
import gql from 'graphql-tag';
import { Router } from '@angular/router';

export const DuplicateOpportunity = gql`
    mutation DuplicateOpportunity($id: ID!) {
    duplicateOpportunity(id: $id) {
            id
            title
        }
    }
`;

@Component({
  selector: 'app-op-duplicate-modal',
  templateUrl: './op-duplicate-modal.component.html',
  styleUrls: ['./op-duplicate-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class OpDuplicateModalComponent implements OnInit {

  @ViewChild('duplicateModal', { static: true }) duplicateModal: ModalDirective;
  @Input() archive = true;
  @Input() opId: number;
  @Output() duplicated: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<boolean> = new EventEmitter();

  loading = false;
  shouldRedirect = false;

  constructor(
    private appApollo: AppApolloService,
    private errorService: ErrorService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  duplicateOpportunity() {
    this.loading = true;
    const variables = {
      id: this.opId
    }
    this.appApollo.mutate(DuplicateOpportunity, variables)
      .then((data: any) => {
        this.loading = false;
        this.duplicated.emit(data.duplicateOpportunity);
        this.onClose();
        if (this.shouldRedirect) {
          this.router.navigate(['/', 'opportunity', data.duplicateOpportunity.id]);
        }
      }, err => {
        this.loading = false;
        this.errorService.showErrorModal(err.networkErr);
      })
  }

  onClose() {
    this.duplicateModal.hide();
    this.close.emit();
  }

}
