import gql from 'graphql-tag';

export const  SurveyModalQuery = gql`

query PlatformNpsResponseQueryType{
    getPlatformNpsQuestions {
        id
        survey_questions {
            id
            question_text
        }
        title
    }
}
` 
export const PlatformNpsResponseMutationQuery = gql`
    mutation PlatformNpsResponseMutationQueryType($responses:[ PlatformNpsInput ] , $path: String){
        createPlatformNps(responses: $responses, path: $path) {
            id
            title
        }
    }
`

