import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class ConfirmationDialogComponent implements OnInit {

  @Input() title: string;
	@Input() message: string;

  @Output() value: EventEmitter<boolean> = new EventEmitter(null);
  
  constructor() { }

  ngOnInit() {
  }

  confirm(value) {
		this.value.emit(value);
  }
  
}
