import { Component, OnInit, OnChanges, Input, Inject, HostListener, ViewEncapsulation } from '@angular/core';
import  Player from '@vimeo/player';
import { DOCUMENT } from "@angular/common";

@Component({
  selector: 'app-vimeo-video',
  templateUrl: './vimeo-video.component.html',
  styleUrls: ['./vimeo-video.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class VimeoVideoComponent implements OnInit, OnChanges {
  currentLanguage: string;
  @Input() videoId: any;
  @Input() startHeightToPlay: any;
  @Input() endHeightToPlay: any;
  @Input() fullscreen: any;
  @Input() language: any;
  bypassNgOnChanges: boolean = false;
  element: any;
  iframe: any;
  player: any;
  count: number = 0;

  constructor(@Inject(DOCUMENT) private document) { 
    // this.currentLanguage = this.translation.currentLanguage;
  }

  ngOnInit() {
    this.loadVimeoVideo(this.videoId);
  }

  ngOnChanges(changes) {
    if (this.bypassNgOnChanges == true) {
      this.toggleFullscreen();
    }
    this.bypassNgOnChanges = true;
  }

  loadVimeoVideo(videoId) {
    this.player = new Player('vimeo-iframe', {
        id: videoId,
        height: 320
    });
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if (number > this.startHeightToPlay && number < this.endHeightToPlay && window.screen.width > 700) {
      if(this.count === 0 ) {
        this.count++;
        this.playVimeoVideo();
        this.enableTextTrack();
      }
      //this.getAutopause();
      //this.setAutopause();
    } else {
      this.pauseVimeoVideo();
    }
  }

  playVimeoVideo() {
    this.player.play().then(function() {
    }).catch(function(error) {
        switch (error.name) {
            case 'PasswordError':
                console.log('the video is password-protected and the viewer needs to enter the password first');
                break;

            case 'PrivacyError':
                console.log('the video is private');
                break;

            default:
                console.log('some other error occurred');
                break;
        }
    });
    this.player.setVolume(0).then(function(volume) {
    }).catch(function(error) {
        if (error && error.name === 'RangeError') {
          console.log('the volume was less than 0 or greater than 1');
        } else {
          console.log('some other error occurred');
        }
      });
  }

  pauseVimeoVideo() {
    this.player.pause().then(function() {
    }).catch(function(error) {
        switch (error.name) {
            case 'PasswordError':
                console.log('the video is password-protected and the viewer needs to enter the password first');
                break;

            case 'PrivacyError':
                console.log('the video is private');
                break;

            default:
                console.log('some other error occurred');
                break;
        }
    });
  }

  getAutopause(){
    this.player.getAutopause().then(function(autopause) {
    // autopause = whether autopause is turned on or off
    }).catch(function(error) {
        if (error && error.name === 'UnsupportedError') {
          console.log('Autopause is not supported with the current player or browser');
        } else {
          console.log('some other error occurred');
        }
    });
  }
  enableTextTrack() {
     this.player.enableTextTrack('en').then(function() {
    // track.language = the iso code for the language
    // track.kind = 'captions' or 'subtitles'
    // track.label = the human-readable label
    }).catch(function(error) {
        switch (error.name) {
            case 'InvalidTrackLanguageError':
                // no track was available with the specified language
                break;

            case 'InvalidTrackError':
                // no track was available with the specified language and kind
                break;

            default:
                // some other error occurred
                break;
        }
    });

  }
  
  setAutopause() {
    this.player.setAutopause(true).then(function(autopause) {
        // autopause was turned off
    }).catch(function(error) {
      if (error && error.name === 'UnsupportedError') {
        console.log('Autopause is not supported with the current player or browser');
      } else {
        console.log('some other error occurred');
      }
    });
  }

  toggleFullscreen() {
    this.element = document.getElementById('vimeo-iframe');
    this.iframe = this.element.getElementsByTagName('iframe')[0]
    this.element.className = 'video';
    this.iframe.className = 'vimeo-iframe'

    if(this.element.requestFullScreen) {
      this.element.requestFullScreen();
      // this.iframe.setAttribute('allowFullScreen', '');
    } else if(this.element.webkitRequestFullScreen) {
      this.element.webkitRequestFullScreen();
      // this.iframe.setAttribute('allowFullScreen', '');
    } else if(this.element.mozRequestFullScreen) {
      this.element.mozRequestFullScreen();
      // this.iframe.setAttribute('allowFullScreen', '');
    }
  }

}
