import { ErrorService } from './../../services/error.service';
import { AppApolloService } from 'app/services/app.apollo.service';
import gql from 'graphql-tag';
import { Input, Output } from '@angular/core';
import { Component, OnInit, ViewEncapsulation, ViewChild, EventEmitter } from '@angular/core';
import { SearchService } from 'app/services/search.service';
import { deepCopyObject } from 'app/modular.functions';

const saveFilter = gql`
  mutation saveFilter($saved_filter: SavedFilterInput) {
    currentPersonSavedFiltersCreate(saved_filter: $saved_filter) {
      id
      body
      model
      name
    }
  }
`

const deleteFilter = gql`
mutation deleteFilter($saved_filter_id: ID) {
  currentPersonSavedFiltersDelete(saved_filter_id: $saved_filter_id) {
    id
    body
    model
    name
  }
}
`

@Component({
  selector: 'app-save-filter',
  templateUrl: './save-filter.component.html',
  styleUrls: ['./save-filter.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class SaveFilterComponent implements OnInit {

  @ViewChild('saveModal', { static: false }) saveModal;
  @Input() currentFilterSetSelected: number;
  @Input() selectionsObject: any = null;
  @Input() model: string = null;
  @Input() convertModelToObject = false;
  @Output() resetFilter = new EventEmitter();
  @Output() addSavedFilter = new EventEmitter();
  @Output() deleteSavedFilter = new EventEmitter();
  showModal: boolean;
  name: string;
  modalType: string;
  deepCopyObject = deepCopyObject;

  constructor(
    private appApollo: AppApolloService,
    private errorService: ErrorService,
    private searchService: SearchService,
    ) { }

  ngOnInit() {
  }

  openModal(modalType) {
    this.modalType = modalType;
    this.showModal = true;
  }

  close() {
    this.showModal = false;
  }

  filterHandler(modalType) {
    if (modalType === 'save') {
      let selectionsObject;
      if (this.convertModelToObject) {
        selectionsObject = this.searchService.makeFilterObjects(deepCopyObject(this.selectionsObject));
        if (this.selectionsObject['graduation_date']) {
          selectionsObject['graduation_date'] = this.selectionsObject['graduation_date'];
        }
        if (this.selectionsObject['created_at']) {
          selectionsObject['created_at'] = this.selectionsObject['created_at'];
        }
        if (this.selectionsObject['is_aiesecer']) {
          selectionsObject['is_aiesecer'] = this.selectionsObject['is_aiesecer'];
        }
      } else {
        selectionsObject = this.selectionsObject;
      }
      const variables = {
        'saved_filter': {
          'name': this.name,
          'model': this.model,
          'body': selectionsObject
        }
      }
      this.appApollo.mutate(saveFilter, variables)
        .then((response: any) => {
          this.currentFilterSetSelected = response.currentPersonSavedFiltersCreate.id;
          this.addSavedFilter.emit(response.currentPersonSavedFiltersCreate)
          this.showModal = false;
        },
          err => {
            console.log(err)
            this.errorService.showErrorModal(err.networkErr);
          })
    } else {
      const variables = {
        'saved_filter_id': this.currentFilterSetSelected
      }
      this.appApollo.mutate(deleteFilter, variables)
        .then((response: any) => {
          this.deleteSavedFilter.emit(response.currentPersonSavedFiltersDelete.id);
          this.reset();
          this.showModal = false;
        },
          err => {
            console.log(err)
            this.errorService.showErrorModal(err.networkErr);
          })
    }
  }

  reset() {
    this.resetFilter.emit(true);
  }
}
