import {
  animate,
  keyframes,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Component, ViewEncapsulation } from '@angular/core';

import { Toast, ToastrService, ToastPackage } from 'ngx-toastr';

@Component({
  selector: 'app-aiesec-toast-component',
  styles: [`
      .toast {
          background-color: #fff !important;
          color: #555 !important;
          box-shadow: none !important;
      }

      .toast-progress {
          background-color: #037ef3 !important;
          opacity: 1 !important;
      }

      .btn-pink {
          -webkit-backface-visibility: hidden;
          -webkit-transform: translateZ(0);
      }
	`],
  template: `
      <div class="row">
          <div class="col-9">
              <div *ngIf="title" [class]="options.titleClass" [attr.aria-label]="title">
                  {{ title | translate }}
              </div>
              <div *ngIf="message && options.enableHtml" role="alert" aria-live="polite"
                   [class]="options.messageClass" [innerHTML]="message | translate">
              </div>
              <div *ngIf="message && !options.enableHtml" role="alert" aria-live="polite"
                   [class]="options.messageClass" [attr.aria-label]="message">
                  {{ message | translate }}
              </div>
          </div>
          <div class="col-3 text-right">
              <a *ngIf="!options.closeButton" class="btn btn-pink btn-sm" (click)="action($event)">
                  {{ undoString | translate }}
              </a>
              <a *ngIf="options.closeButton" (click)="remove()" class="btn btn-pink btn-sm">
                  {{'close' | translate}}
              </a>
          </div>
      </div>
      <div *ngIf="options.progressBar">
          <div class="toast-progress" [style.width]="width + '%'"></div>
      </div>
	`,
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        display: 'none',
        opacity: 0
      })),
      transition('inactive => active', animate('400ms ease-out', keyframes([
        style({
          transform: 'translate3d(100%, 0, 0) skewX(-30deg)',
          opacity: 0,
        }),
        style({
          transform: 'skewX(20deg)',
          opacity: 1,
        }),
        style({
          transform: 'skewX(-5deg)',
          opacity: 1,
        }),
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('400ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
        style({
          transform: 'translate3d(100%, 0, 0) skewX(30deg)',
          opacity: 0,
        }),
      ]))),
    ]),
  ],
  preserveWhitespaces: false,
  encapsulation: ViewEncapsulation.None
})
export class AiesecToastComponent extends Toast {
  // used for demo purposes
  undoString = 'undo';

  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
  ) {
    super(toastrService, toastPackage);
  }

  action(event: Event) {
    event.stopPropagation();
    this.undoString = 'Close';
    this.toastPackage.triggerAction();
    return false;
  }
}
