
import {takeWhile,  map } from 'rxjs/operators';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import {  QuestionnaireCreateMutation,
          GetQuestionnairesQuery,
          RemoveQuestionMutation,
          UpdateQuestionMutation,
          QuestionnaireUpdateMutation,
          QuestionnaireDeleteMutation
        } from './../../../organisation.graphql';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation, ViewChild, Output, EventEmitter } from '@angular/core';
import { OrganisationService } from 'app/services/organisation.service';
import { DragulaService } from 'ng2-dragula';
import { ErrorService } from 'app/services/error.service';
import { makeErrorText } from 'app/constants';

@Component({
  selector: 'app-add-questionnaire',
  templateUrl: './add-questionnaire.component.html',
  styleUrls: ['./add-questionnaire.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AddQuestionnaireComponent implements OnInit {

  @ViewChild('addQuestionModal', { static: false }) addQuestionModal;
  @Output() createdQuestionnaire: EventEmitter<any> = new EventEmitter();

  loading: boolean;
  questionnaireId: number = null;
  questionnairesArray = [];
  organisationId: number;
  object = Object;
  selectedQuestionnaire = {
    id: null,
    name: null,
    questions: []
  };
  makeError = makeErrorText;
  questionnaireName: any;
  questionSelected: any;
  showAddModal = false;
  showEditModal = false;
  isAlive = true;
  removeQuestionLoading = false;
  questionSelectedForRemoval: number;
  displayError = false;
  originUrl: string;
  dragActionActive = false;
  questionsRequiredError = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private organisationService: OrganisationService,
    private dragulaService: DragulaService,
    private apollo: Apollo,
    private router: Router,
    private errorService: ErrorService,
  ) {
    this.getRouteParams();
    this.getOrganisationId();
  }

  ngOnInit() {
    this.initializeDragula();
    if (this.router.url) {
      this.originUrl = this.router.url;
    }
  }

  initializeDragula() {
    this.dragulaService.drop().pipe(
    takeWhile(() => this.isAlive))
    .subscribe((value) => {
      const draggedQuestionId = value.el.id;

      if (!draggedQuestionId) {
        return
      }

      const draggedQuestion = this.selectedQuestionnaire.questions.find(question => +question.id === +draggedQuestionId);
      let newPosition;

      const questions = this.selectedQuestionnaire.questions;
      for (let i = 0; i < questions.length; i++) {
        if (+questions[i].id === +draggedQuestionId) {
          newPosition = i + 1;
          break;
        }
      }

      if (!newPosition || +newPosition === +draggedQuestion.position) {
        return
      }

      draggedQuestion.position = newPosition;

      this.updateQuestionPosition(draggedQuestion);
    });
  }

  updateQuestionPosition(question) {
    this.dragActionActive = true;
    this.apollo.mutate({
      mutation: UpdateQuestionMutation,
      variables: {
        id: question.id,
        question: {
          position: question.position
        }
      },
      refetchQueries: [{query: GetQuestionnairesQuery, variables: {id: this.organisationId}}]
    })
    .pipe(
      map((res: any) => res.data),
    )
    .subscribe(response => {
      this.dragActionActive = false;
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  getOrganisationId() {
    this.organisationService.myOrganisationId.subscribe(id => {
      if (id) {this.organisationId = id; }
    })
  }

  getRouteParams() {
    this.activatedRoute.params.subscribe(params => {
      if (params.id) {
        this.questionnaireId = +params.id;
        this.getQuestionnaires();
      } else {
        this.questionnairesArray = [];
        this.initializeQuestionnaireForm();
      }
    });
  }

  getQuestionnaires() {
    this.loading = true
    this.organisationService.questionnaires
    .subscribe((questionnairesArray) => {
      if (this.dragActionActive) {
        return;
      }
      if (questionnairesArray) {
        this.questionnairesArray = JSON.parse(JSON.stringify(questionnairesArray));
        this.selectedQuestionnaire = this.getSelectedQuestionnaire();
        if (this.selectedQuestionnaire) {
          if (this.selectedQuestionnaire.questions.length) {
            this.questionsRequiredError = false;
          }
          this.initializeQuestionnaireForm(this.selectedQuestionnaire.name);
        }
        this.loading = false;
      }
    })
  }

  initializeQuestionnaireForm(params?) {
    this.questionnaireName = new FormGroup({
      'name': new FormControl(params ? params : null, Validators.required)
    });
  }

  getSelectedQuestionnaire() {
    const selectedQuestionnaire = this.questionnairesArray.find(questionnaire => +questionnaire.id === this.questionnaireId);
    return selectedQuestionnaire;
  }

  validateForm() {
    if (this.questionnaireName.valid) {
      this.displayError = false;
      this.saveQuestionnaire();
    } else {
      this.displayError = true;
    }
  }

  saveQuestionnaire() {
    const variables = {
      company_id: this.organisationId,
      questionnaire: {
        name: this.questionnaireName.value.name
      }
    }
    this.apollo.mutate({
      mutation: QuestionnaireCreateMutation,
      variables: variables,
      update: (store, {data: response}) => {
        const data: any = store.readQuery({query: GetQuestionnairesQuery, variables: {id: this.organisationId}});
        if (+data['getOrganisation'].id === +this.organisationId) {
          data['getOrganisation'].questionnaires.edges = [...data['getOrganisation'].questionnaires.edges,
                                                          {
                                                            node: response['createQuestionnaire'],
                                                            __typename: 'QuestionnaireEdge'
                                                          }];
        }
        store.writeQuery({ query: GetQuestionnairesQuery, variables: {id: this.organisationId}, data});
      }
    })
    .pipe(
      map((res: any) => res.data)
    )
    .subscribe((response: any) => {
      this.selectedQuestionnaire = response['createQuestionnaire'];
      this.questionnaireId = +response['createQuestionnaire'].id;
      if (this.router.url.includes('/opportunity/new')) {
        this.openAddModal();
        this.getQuestionnaires();
      } else {
        this.router.navigate([`/organisation/process/questionnaires/edit-questionnaire/${this.questionnaireId}`]);
      }
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  updateQuestionnaireName() {
    if (this.questionnaireName.valid) {
      this.displayError = false;
      if (this.questionnaireName.dirty) {
        this.sendUpdateRequest();
      } else {
        if (this.selectedQuestionnaire.questions.length) {
          this.questionsRequiredError = false;
          this.navigationHandler();
        } else {
          this.questionsRequiredError = true;
        }
      }
    } else {
      this.displayError = true;
    }
  }

  sendUpdateRequest() {
    this.loading = true;
    this.apollo.mutate({
      mutation: QuestionnaireUpdateMutation,
      variables: {
        id: +this.selectedQuestionnaire.id,
        questionnaire: {
          name: this.questionnaireName.value.name
        }
      },
      update: (store, {data: response}) => {
        const data: any = store.readQuery({query: GetQuestionnairesQuery, variables: {id: this.organisationId}});
        if (+data['getOrganisation'].id === +this.organisationId) {
          for (const questionnaire of data['getOrganisation'].questionnaires.edges) {
            if (+questionnaire.node.id === +this.selectedQuestionnaire.id) {
              questionnaire.node.name = response['updateQuestionnaire'].name;
              if (!questionnaire.node.questions.length) {
                this.questionsRequiredError = true;
              }
              break;
            }
          }
        }
        store.writeQuery({ query: GetQuestionnairesQuery, variables: {id: this.organisationId}, data});
      }
    })
    .subscribe(response => {
      this.loading = false;
      if (!this.questionsRequiredError) {
        this.navigationHandler();
      }
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  removeQuestion(questionId) {
    this.removeQuestionLoading = true;
    this.questionSelectedForRemoval = questionId;
    this.apollo.mutate({
      mutation: RemoveQuestionMutation,
      variables: { id: questionId },
      update: (store, {data: response}) => {
        const data: any = store.readQuery({query: GetQuestionnairesQuery, variables: {id: this.organisationId}});
        if (+data['getOrganisation'].id === +this.organisationId) {
          for (const questionnaire of data['getOrganisation'].questionnaires.edges) {
            if (+questionnaire.node.id === +this.selectedQuestionnaire.id) {
              questionnaire.node.questions = questionnaire.node.questions
                                              .filter(question => +question.id !== +response['removeQuestion'].id);
              break;
            }
          }
        }
        store.writeQuery({ query: GetQuestionnairesQuery, variables: {id: this.organisationId}, data});
      }
    })
    .pipe(
      map((res: any) => res.data)
    )
    .subscribe(response => {
      this.questionSelectedForRemoval = null;
      this.removeQuestionLoading = false;
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  removeQuestionnaire() {
    this.loading = true;
    this.apollo.mutate({
      mutation: QuestionnaireDeleteMutation,
      variables: {id: +this.selectedQuestionnaire.id},
      update: (store, {data: response}) => {
        const data: any = store.readQuery({query: GetQuestionnairesQuery, variables: {id: this.organisationId}})
        if (+data['getOrganisation'].id === +this.organisationId) {
          data['getOrganisation'].questionnaires.edges = data['getOrganisation'].questionnaires.edges
                                                        .filter(questionnaire =>
                                                          questionnaire.node.id !== response['deleteQuestionnaire'].id
                                                        );
        }
        store.writeQuery({ query: GetQuestionnairesQuery, variables: {id: this.organisationId}, data});
      }
    })
    .subscribe(response => {
      this.loading = false;
      this.navigationHandler();
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  navigationHandler(dismissModal?) {
    const routerUrl =  this.router.url.split('?')[0];         // Gets url without queryparams
    if (this.selectedQuestionnaire && this.selectedQuestionnaire.id) {
      if (routerUrl === '/opportunity/new') {
        if (this.selectedQuestionnaire.questions.length) {
          if (!dismissModal) {
            this.createdQuestionnaire.emit({id: this.questionnaireId, text: this.selectedQuestionnaire.name})
          } else {
            this.createdQuestionnaire.emit(false);
          }
        } else {
          this.removeQuestionnaire();
        }
      } else {
        if (this.selectedQuestionnaire.questions.length) {
          this.router.navigate(['/organisation/process/questionnaires']);
        } else {
          this.removeQuestionnaire();
        }
      }
    } else {
      if (routerUrl === '/opportunity/new') {
        this.createdQuestionnaire.emit(false);
      } else {
        this.router.navigate(['/organisation/process/questionnaires']);
      }
    }
  }

  openAddModal() {
    this.showAddModal = true;
  }

  openEditModal(question) {
    this.questionSelected = question;
    this.showEditModal = true;
  }

  dismissModal() {
    this.addQuestionModal.hide();
  }

}
