
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, filter, takeWhile} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserService } from './user.service';
import { TokenService } from './token.service';
import { AppApolloService } from './app.apollo.service';

@Injectable()
export class ModelService {
    token: string = '';
    accessTokenString: string = '';
    apiUrl: string = '';

    constructor(
        private http: HttpClient,
        private user: UserService,
        private tokenService: TokenService,
        private appApollo: AppApolloService,
    ) {
        let listen = true
        this.apiUrl = environment.apiUrl;
        this.tokenService.tokenPresent.pipe(
        takeWhile(() => listen),
        filter(value => value !== null),)
        .subscribe(tokenPresent => {
            this.setToken()
            listen = false
        })

        this.tokenService.refreshedToken
            .subscribe(data => {
                if(data) this.setToken()
            })
    }

    setToken() {
        this.token = this.tokenService.token;
        this.accessTokenString = this.token && this.token.length ? `access_token=${this.token}` :
        `access_token=${this.appApollo.environment.publicToken}`;
    }

    getCount(options: any): Observable<any> {
        let {
            qs
        } = options;
        if (!qs) {
            qs = '';
        }

        return this.http.get(`${this.apiUrl}/people.json?${this.accessTokenString}&${qs}`).pipe(
            catchError((error: any) => observableThrowError(error)));
    }

    get(options: any): Observable<any> {
        let { name, qs } = options;
        if (!qs) {
            qs = '';
        }
        if (name.includes(".csv")) {
          return this.http.get(`${this.apiUrl}/${name}?${this.accessTokenString}${qs}` , {responseType: 'blob'}).pipe(
              catchError((error: any) => observableThrowError(error)));
        }
        else {
          return this.http.get(`${this.apiUrl}/${name}.json?${this.accessTokenString}&${qs}`).pipe(
              catchError((error: any) => observableThrowError(error)));
        }
    }

    patch(name: string, data: any): Observable<any> {
        return this.http.patch(`${this.apiUrl}/${name}.json?${this.accessTokenString}`, data).pipe(
            catchError((error: any) => observableThrowError(error)));
    }

    post(name: string, data: any): Observable<any> {
        return this.http.post(`${this.apiUrl}/${name}.json?${this.accessTokenString}`, data).pipe(
            catchError((error: any) => observableThrowError(error)));
    }

    delete(name: string): Observable<any> {
        return this.http.delete(`${this.apiUrl}/${name}.json?${this.accessTokenString}`).pipe(
            catchError((error: any) => observableThrowError(error)));
    }

}
