import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class ConfirmationService {

  showModal = new BehaviorSubject(null);
  isConfirmed = new Subject();
  confirmationTitle: string;
  confirmationDetails: string;

  constructor() {
  }

  showConfirmationModal(title, details) {
    this.confirmationTitle = title;
    this.confirmationDetails = details;
    this.showModal.next(true)
  }

  hideConfirmationModal() {
    this.confirmationTitle = null;
    this.confirmationDetails = null;
    this.showModal.next(false);
  }

}
