import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotificationsList, NotificationReadMutation, NotificationUnreadCount } from './../navbar.graphql';
import { Component, OnInit, HostListener, ElementRef, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Apollo, QueryRef } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { getMinimalisticRelativeTime } from 'app/modular.functions';
import { ErrorService } from 'app/services/error.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy {

  showNotifications = false;
  showAnimation = false;
  notifications: Array<any> = [];
  paging = null;
  loading = true;
  notificationsDropdown: any;
  notificationsListVariable = {
    page: 1,
    per_page: 8,
    filters: {
      platform_type: 'POP'
    }
  };
  notificationsQuery: QueryRef<any>;
  notificationsSubscription: Subscription;
  getMinimalisticRelativeTime = getMinimalisticRelativeTime;
  unreadCount = 0;

  constructor(
    private apollo: Apollo,
    private error: ErrorService,
    private _elementRef: ElementRef,
    public router: Router,
    public cdr: ChangeDetectorRef
  ) {
    this.notificationsQuery = this.apollo.watchQuery({
      query: NotificationsList,
      variables: this.notificationsListVariable
    });
    this.loadNotifications();
    this.getUnreadCount();
  }

  ngOnInit() {
    this.setPeriodicRefresh(1);
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.fadeoutNotificationTab();
    }
  }

  getUnreadCount() {
    this.apollo.query({
      query: NotificationUnreadCount,
      variables: {
        filters: {
          platform_type: 'POP',
          read: false
        }
      }
    }).pipe(map(data => data.data))
      .subscribe(response => {
        this.unreadCount = response['notificationList'].paging.total_items;
        this.cdr.markForCheck();
      }, err => {
        this.error.showErrorModal(err.networkError);
        console.log(err);
      });
  }

  loadNotifications() {
    this.notificationsSubscription = this.notificationsQuery.valueChanges.pipe(map(data => data.data))
      .subscribe(response => {
        this.notifications = response['notificationList'].data;
        this.paging = response['notificationList'].paging;
        this.loading = false;
      }, err => {
        this.error.showErrorModal(err.networkError);
        console.log(err);
      });
  }

  loadMoreNotifications() {
    this.notificationsQuery.fetchMore({
      variables: {
        page: this.paging.current_page + 1
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        this.loading = false;
        if (!fetchMoreResult) {
          return prev;
        }
        const updatedObject = Object.assign({}, prev, {
          ...prev,
          notificationList: {
            ...prev.notificationList,
            data: [
              ...prev.notificationList.data,
              ...fetchMoreResult.notificationList.data
            ],
            paging: fetchMoreResult.notificationList.paging
          }
        });
        return updatedObject;
      }
    });
  }

  toggleNotificationDropdown() {
    if (this.showNotifications) {
      this.fadeoutNotificationTab();
    } else {
      this.showNotifications = true;
      this.showAnimation = true;
    }
  }

  onScroll() {
    if (this.paging && !this.loading) {
      if (this.paging.current_page < this.paging.total_pages) {
        this.loading = true;
        this.loadMoreNotifications();
      }
    }
  }

  sendRequestAndNavigate(notification) {
    switch (notification.notifiable_type) {
      case 'Opportunity':
        this.router.navigate([`/opportunity/${notification.notifiable.opportunity.id}`]);
        break;
      case 'OpportunityApplication':
        this.router.navigate([`/opportunity/${notification.notifiable.opportunity.id}/applications`]);
        break;
      case 'BranchEmployee':
        this.router.navigate([`/profile/edit`]);
        break;
    }
    if (!notification.read) {
      this.sendMutation(notification.id);
    }
    this.fadeoutNotificationTab();
  }

  sendMutation(notificationId) {
    this.apollo.mutate({
      mutation: NotificationReadMutation,
      variables: { id: notificationId },
      update: (store, { data: response }) => {
        const data: any = store.readQuery({ query: NotificationsList, variables: this.notificationsListVariable });
        for (let i = 0; i < data['notificationList'].data.length; i++) {
          if (data['notificationList'].data[i].id === response['notificationRead'].id) {
            data['notificationList'].data[i].read = response['notificationRead'].read;
            break;
          }
        }
        store.writeQuery({ query: NotificationsList, variables: this.notificationsListVariable, data });
      }
    })
      .pipe(map((res: any) => res.data))
      .subscribe(res => {
        if (this.unreadCount > 0) {
          this.unreadCount -= 1;
        }
        this.cdr.markForCheck();
      },
        err => {
          this.cdr.markForCheck();
          this.error.showErrorModal(err.networkErr);
          console.log(err);
        });
  }

  fadeoutNotificationTab() {
    this.showAnimation = false;
    setTimeout(() => {
      this.showNotifications = false;
      this.cdr.markForCheck();
    }, 250);
  }

  setPeriodicRefresh(minutes) {
    setInterval(() => {
      this.cdr.markForCheck();
    }, minutes * 10 * 6000);
  }

  ngOnDestroy() {
    this.notificationsSubscription.unsubscribe();
  }
}

