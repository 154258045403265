import { ValidatorService as V } from 'app/services/validators';
import {
  AddQuestionMutation,
  questionOptionBulkCreateMutation,
  GetQuestionnairesQuery
} from '../../content/organisation/organisation.graphql';
import { Component, OnInit, ViewEncapsulation, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { ErrorService } from 'app/services/error.service';
import { makeErrorText } from 'app/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-question',
  templateUrl: './add-question.component.html',
  styleUrls: ['./add-question.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AddQuestionComponent implements OnInit {

  @ViewChild('addQuestionModal', { static: true }) addQuestionModal;
  @Input() questionnaireId: number;
  @Input() organisationId: number;
  @Output() close: EventEmitter<any> = new EventEmitter(null);
  loading = false;
  questionnairesArray = [];
  object = Object;
  selectedQuestionnaire = {
    id: null,
    name: null,
    questions: []
  };
  questionnaireName: string;
  questionForm: any;
  questionType = [{id: 'multiple_choice', text: 'Multiple choice', iconClass: 'fa-circle-dot fa-regular'},
                  {id: 'paragraph', text: 'Paragraph', iconClass: 'fa-align-left fa-regular'},
                  {id: 'text', text: 'Text', iconClass: 'fa-bars-sort fa-regular'},
                  {id: 'attachment', text: 'Attachment', iconClass: 'fa-arrow-up-from-bracket fa-regular'},
                  {id: 'checkbox', text: 'Checkbox', iconClass: 'fa-square-check fa-solid'}
                ];
  hasAnyError = false;
  displayError = false;
  questionSelected: any;
  questionTypeSelected: any;
  closeModal = false;
  makeError = makeErrorText;
  optionsNotProvided = false;

  constructor(
    private _fb: FormBuilder,
    private apollo: Apollo,
    private errorService: ErrorService,
    private router: Router
  ) { }

  ngOnInit() {
    this.makeQuestionForm();
  }

  makeQuestionForm() {
      this.questionForm = this._fb.group({
        'id': new FormControl(null),
        'question_text': new FormControl(null,
          [
          V.forbiddenTextValidator(
            new RegExp(
              'https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}'
              )
            ),
          Validators.required]
        ),
        'question_type': new FormControl(null, Validators.required),
        'is_mandatory': new FormControl(true),
        'sub_questions': new FormArray([this.createSubQuestion()])
      })
  }

  validateForm() {
    if (this.questionForm.value.question_type !== 'multiple_choice' && this.questionForm.value.question_type !== 'checkbox') {
      while (this.questionForm.get('sub_questions').length !== 0) {
        this.questionForm.get('sub_questions').removeAt(0);
      }
    }
    if (this.questionForm.valid) {
      if (this.questionForm.value.question_type === 'multiple_choice' || this.questionForm.value.question_type === 'checkbox') {
        if (!this.questionForm.value.sub_questions.length) {
          this.optionsNotProvided = true;
          return;
        }
      }
      this.optionsNotProvided = false;
      this.displayError = false;
      this.loading = true;
      this.saveQuestion();
    } else {
      this.displayError = true;
    }
  }

  saveQuestion() {
    const variables = {
      questionnaire_id: this.questionnaireId,
      questions: [{
        question_text: this.questionForm.value.question_text,
        is_mandatory: this.questionForm.value.is_mandatory,
        question_type: this.questionForm.value.question_type
      }]
    }
    this.apollo.mutate({
      mutation: AddQuestionMutation,
      variables: variables,
      update: (store, {data: response}) => {
        const data: any = store.readQuery({query: GetQuestionnairesQuery, variables: {id: this.organisationId}});
        if (+data['getOrganisation'].id === +this.organisationId) {
          for (const questionnaire of data['getOrganisation'].questionnaires.edges) {
            if (+questionnaire.node.id === +this.questionnaireId) {
              questionnaire.node.questions = [...questionnaire.node.questions, ...response['questionBulkCreate']];
              break;
            }
          }
        }
        store.writeQuery({ query: GetQuestionnairesQuery, variables: {id: this.organisationId}, data });
      }
    })
    .pipe(
      map((res: any) => res.data)
    )
    .subscribe(response => {
      this.questionForm.get('id').patchValue(response['questionBulkCreate'][0].id);
      this.questionSelected = response['questionBulkCreate'][0];
      if (this.questionForm.value.question_type === 'multiple_choice' || this.questionForm.value.question_type === 'checkbox') {
        if (this.questionForm.value.sub_questions.length) {
          this.sendAddOptionRequest()
        } else {
          this.displayError = true;
        }
      } else {
        this.loading = false;
        this.dismissModal();
      }
      this.loading = false
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  createSubQuestion() {
    return new FormGroup({
      'id': new FormControl(null),
      'question_text': new FormControl(null,
        [
          V.forbiddenTextValidator(
            new RegExp(
              'https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,}'
              )
            ),
          Validators.required
        ]
      ),
      'parent_id':  new FormControl(null),
    });
  }

  addSubQuestion() {
    if (this.questionForm.valid) {
        this.displayError = false;
        this.addSubQuestionAsOption();
    } else {
      this.displayError = true;
    }
  }


  addSubQuestionAsOption(): void {
    this.questionForm.get('sub_questions').push(this.createSubQuestion());
  }

  sendAddOptionRequest() {
    let questionsArray = this.questionForm.value.sub_questions.slice();
    questionsArray = questionsArray.filter(question => !question.id) // fetch all new options added
    questionsArray.forEach(question => {
      if (!question.id) {
        delete question.id  // remove null id fields before sending request
        question.parent_id = this.questionSelected.id;
      }
    })
    const variables = {
      questions: questionsArray
    }
    this.apollo.mutate({
      mutation: questionOptionBulkCreateMutation,
      variables: variables,
      update: (store, {data: response}) => {
        const data: any = store.readQuery({query: GetQuestionnairesQuery, variables: {id: this.organisationId}});
        if (+data['getOrganisation'].id === +this.organisationId) {
          for (const questionnaire of data['getOrganisation'].questionnaires.edges) {
            if (+questionnaire.node.id === +this.questionnaireId) {
              for (const question of questionnaire.node.questions) {
                if (+question.id === +this.questionSelected.id) {
                  question.sub_questions = [...question.sub_questions, ...response['questionBulkCreate']];
                  break;
                }
              }
              break;
            }
          }
        }
        store.writeQuery({ query: GetQuestionnairesQuery, variables: {id: this.organisationId}, data });
      }
    })
    .pipe(
      map((res: any) => res.data)
    )
    .subscribe(response => {
      this.questionForm.get('sub_questions').controls.forEach((element, index) => {
        element.get('id').patchValue(response['questionBulkCreate'][index].id);
        element.get('parent_id').patchValue(response['questionBulkCreate'][index].parent_id);
      });
      this.dismissModal();
      this.loading = false;
    }, err => {
      this.errorService.showErrorModal(err.networkError);
    })
  }

  removeOption(index) {
    this.questionForm.get('sub_questions').removeAt(index);
  }

  openModal() {
    this.makeQuestionForm();
    this.addQuestionModal.show();
  }

  dismissModal() {
    this.addQuestionModal.hide();
    this.close.emit(true);
  }

  selectQuestionType(type) {
    this.questionForm.controls['question_type'].patchValue(type.id);
    this.questionTypeSelected = this.questionType.find(item => item.id === type.id);
  }
}
