import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap';
import { OpDuplicateModalComponent } from './op-duplicate-modal.component';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from 'app/pipes/translate.module';

@NgModule({
  imports: [
    CommonModule,
    ModalModule,
    FormsModule,
    TranslateModule,
  ],
  declarations: [OpDuplicateModalComponent],
  exports: [
    OpDuplicateModalComponent
  ]
})
export class OpDuplicateModalModule { }
