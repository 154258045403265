import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmFloatingInfoComponent } from './cm-floating-info.component';
import { TranslateModule } from 'app/pipes/translate.module';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
  ],
  declarations: [CmFloatingInfoComponent],
  exports: [
    CmFloatingInfoComponent
  ]
})
export class CmFloatingInfoModule { }
