
import {map} from 'rxjs/operators';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { flagName, getLevelColor } from '../../modular.functions';
import { UserService } from './../../services/user.service';
import { AddToWatchList, RemoveFromWatchlist, AllOpportunity } from './card.graphql';
import { Apollo } from 'apollo-angular';
import { SearchService } from 'app/services/search.service';
import { ErrorService } from 'app/services/error.service';
import { ToastrService } from 'ngx-toastr';
import {AiesecToastComponent} from 'app/blocks/toast/toast.component';
import { Error } from '../error-modal/error.constants';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent implements OnInit {

  @Input() candidate;
  @Input() opportunityId;
  @Input() opportunityCountStats: any;
  @ViewChild('opSelecteModal', { static: true }) opSelecteModal;
  @ViewChild('showInterestErrorModal', { static: true }) showInterestErrorModal;
  flagName = flagName;
  getLevelColor = getLevelColor;
  loading: boolean = false;
  showCandidateProfileModal: boolean = false;
  interestedErrorModal: boolean = false;
  skillsSliceLength: number = 3;
  languagesSliceLength: number = 3;
  backgroundsSliceLength: number = 3;
  nationalitiesSliceLength: number = 3;
  professionalSliceLength: number = 3;
  academicSliceLength: number = 3;
  public currentUser: any = null;
  userPresent: boolean;
  showErrorDialog: boolean = false;
  matchingWithOpportunity: boolean = false;
  showCandidateModal: boolean;
  interestErrorMsg = {
    id: 0,
    title: 'Please Sign Up/Login',
    msg: 'To add this candidate to your watchlist, you need to be logged in and have an open opportunity on the platform.'
  };
  watchlistToggleLoading: any = {};
  myOpportunities: any;
  featuredSkills: Array<any>;
  loadingElements: any = {};
  opportunities: any;
  selectedOpportunityId: any;
  errors: any = {};

  constructor(private userService: UserService,
    private apollo: Apollo,
    private searchService: SearchService,
    private errorService: ErrorService,
    private toastr: ToastrService,
    ) {
    this.loading = true;
    this.userService.userPresent.subscribe(data => {
      if (data) {
        this.userPresent = true
        this.userService.userDataLoading.subscribe(userPresent => {
          if (userPresent) {
            this.currentUser = this.userService.currentUser;
          }
        })

      } else {
        this.currentUser = null
      }
      this.loading = false;
    });
  }

  ngOnInit() {
    // this.setFeaturedSkills();
  }

  setFeaturedSkills() {
    if (this.candidate && this.candidate.person_profile && this.candidate.person_profile.skills) {
      this.featuredSkills = this.candidate.person_profile.skills.filter(featured_skills => featured_skills.option === 'featured')
      if (!this.featuredSkills || !this.featuredSkills.length) {
        this.featuredSkills = this.candidate.person_profile.skills;
      }
    }
  }

  openCandidateModal() {
    this.showCandidateModal = true;
  }

  closeCandidateModal(value) {
    if (!value) {
      this.showCandidateModal = false;
    }
  }

  closeCandidateProfileModal() {
    this.showCandidateProfileModal = false;
  }

  async showInterest() {
    if (!this.currentUser) {
      return;
    }

    if (this.opportunityCountStats.noOpportunities) {
      this.interestErrorMsg.id = 1;
      this.interestErrorMsg.title = 'No Opportunities';
      this.interestErrorMsg.msg = 'You have to create an opportunity to add the candidate to the watchlist.';
      this.showInterestErrorModal.show();
      return;
    } else if (this.opportunityCountStats.noOpenOpportunities) {
      this.interestErrorMsg.id = 2;
      this.interestErrorMsg.title = 'No Open Opportunities';
      this.interestErrorMsg.msg = `You need to have an open opportunity to add the candidate to the watchlist.
      Please open an opportunity`;
      this.showInterestErrorModal.show();
      return;
    } else if (this.opportunityCountStats.oneOpportunityUnderReview) {
      this.interestErrorMsg.id = 3;
      this.interestErrorMsg.title = 'Opportunity Under Review';
      this.interestErrorMsg.msg = `Your opportunity need to be reviewed by AIESEC, so you can watchlist the candidates.
      Please contact your Account Manager.`;
      this.showInterestErrorModal.show();
      return;
    }

    if (this.opportunityId) {
      this.addToWatchlist(this.opportunityId);
    } else {
      let error;
      this.errors.opportunitiesLoading = false;
      this.opSelecteModal.show();
      this.loadingElements.opportunities = true;
      this.opportunities = await this.getOpportunities().catch(err => error = err);
      this.loadingElements.opportunities = false;
      if (error) {
        this.errors.opportunitiesLoading = true;
        return;
      }
      this.opportunities = [...this.opportunities.map(o => ({...o, ...{text: o.title}}))];
    }
  }

  addToWatchlist(opportunityId) {
    this.loadingElements.watchlist = true;
    this.apollo.mutate({
      mutation: AddToWatchList,
      variables: {
        potential_candidate: {
          person_id: +this.candidate.id,
          opportunity_id: +opportunityId
        },
      },
      update: (store, {data: response}) => {
        if (!this.opportunityId) {
          // Don't update store for non vanilla search (non opportunity search)
          return;
        }
        if (!this.searchService.peopleGraphqlQuery || !this.searchService.peopleVariables) {
          return;
        }
        const data: any = store.readQuery({query: this.searchService.peopleGraphqlQuery, variables: this.searchService.peopleVariables});
        if (!data) {
          return;
        }
        for (let i = 0; i < data['allPeople'].data.length; i++) {
          if (+data['allPeople'].data[i].id === +this.candidate.id) {
            data['allPeople'].data[i].watchlist_for_opportunity = response['createWatchlist'];
            break;
          }
        }
        store.writeQuery({ query: this.searchService.peopleGraphqlQuery, variables: this.searchService.peopleVariables, data });
      }
    }).pipe(
    map((data: any) => data.data.createWatchlist))
    .subscribe(opPotentialCandidate =>  {
      this.loadingElements.watchlist = false;
      this.selectedOpportunityId = null;
      this.dismissOpSelectModal();
      this.showToast('Watchlist', `${opPotentialCandidate.person.first_name} added to watchlist for ${
        opPotentialCandidate.opportunity.title}`);
    }, err => {
      const error = new Error(err);
      if (error && error.message === 'RecordNotUnique') {
        const message = `You have already watchlisted this candidate for this opportunity. 
        Please select a different opportunity.`;
        this.errorService.showErrorModal(null, message);
      } else {
        this.errorService.showErrorModal(err);
      }
      this.loadingElements.watchlist = false;
      this.selectedOpportunityId = null;
    });
  }

  removeFromWatchlist() {
    if (!this.currentUser) {
      return;
    }

    if (this.candidate.watchlist_for_opportunity && this.opportunityId) {
      this.loadingElements.watchlist = true;
      this.apollo.mutate({
        mutation: RemoveFromWatchlist,
        variables: {
          person_id: +this.candidate.id,
          opportunity_id: +this.opportunityId
        },
        update: (store, {data: response}) => {
          if (!this.searchService.peopleGraphqlQuery || !this.searchService.peopleVariables) {
            return;
          }
          const data: any = store.readQuery({query: this.searchService.peopleGraphqlQuery, variables: this.searchService.peopleVariables});
          if (!data) {
            return;
          }
          for (let i = 0; i < data['allPeople'].data.length; i++) {
            if (+data['allPeople'].data[i].id === +this.candidate.id) {
              data['allPeople'].data[i].watchlist_for_opportunity = null;
              break;
            }
          }
          store.writeQuery({ query: this.searchService.peopleGraphqlQuery, variables: this.searchService.peopleVariables, data });
        }
      }).pipe(
      map((data: any) => data.data.deleteWatchlist))
      .subscribe(opPotentialCandidate =>  {
        this.loadingElements.watchlist = false;
        this.dismissOpSelectModal();
        this.showToast('Watchlist', `${opPotentialCandidate.person.first_name} removed from watchlist for ${
          opPotentialCandidate.opportunity.title}`);
      }, err => {
        this.loadingElements.watchlist = false;
        this.errorService.showErrorModal(err);
      });
    }
  }

  opportunitySelected(opId) {
    this.selectedOpportunityId = opId;
  }

  getOpportunities() {
    return new Promise((resolve, reject) => {
      this.apollo.query({
        query: AllOpportunity,
        variables: {
          filters: {
            organisation: +this.currentUser.managed_companies[0].id,
            status: 'open'
          }
        }
      }).pipe(
      map((data: any) => data.data.allOpportunity))
      .subscribe(opportunitiesData => {
        if (opportunitiesData.data) {
          return resolve(opportunitiesData.data);
        }
        return resolve();
      }, err => {
        return reject(err);
      });
    });
  }

  dismissOpSelectModal() {
    this.opSelecteModal.hide();
    this.selectedOpportunityId = null;
  }

  dismissModal() {
    this.showInterestErrorModal.hide();
  }

  showToast(title, message) {
    // Todo: Check Aiesec custom styling by passing options
    this.toastr.show(title, message);
  }

  showMore(length, arrayName) {
    switch (arrayName) {
      case 'skills':
        return this.skillsSliceLength = length;
      case 'languages':
        return this.languagesSliceLength = length;
      case 'backgrounds':
        return this.backgroundsSliceLength = length;
      case 'nationalities':
        return this.nationalitiesSliceLength = length;
      case 'professional':
        return this.professionalSliceLength = length;
      case 'academic':
        return this.academicSliceLength = length;
    }
  }

}
