
import {takeWhile} from 'rxjs/operators';
import { ApplicationsService } from 'app/services/applications.service';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input} from '@angular/core';

import { FormBuilder, FormControl, FormGroup} from "@angular/forms";
import { GetInterviewDetails, UpdateInterviewDetails } from './interview-details.graphql';
import { AppApolloService } from 'app/services/app.apollo.service';
import { ErrorService } from 'app/services/error.service';
@Component({
  selector: 'app-interview-details',
  templateUrl: './interview-details.component.html',
  styleUrls: ['./interview-details.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})
export class InterviewDetailsComponent implements OnInit {
  @Input() applicationData;

  appDetails: any;
  interviewDetails: any;
  loading:boolean = true;
  selectedTimeSlot: any;
  selectedInterviewSlotIndex: number = -1;
  acceptedInterview: boolean = false;
  interviewDetailsPresent: boolean = false;

  //Object varialbes
  application = new Object();

  // Form grop variables
  interviewTypeForm: FormGroup;
  conductInterviewForm: FormGroup;


  openInterviewStep: number = 0;

  
  interviewHeader: string = '';
  componentAlive: boolean = true;

  constructor(
    private applicationService: ApplicationsService,  
    private fb: FormBuilder,
    private appApollo: AppApolloService,
    private errorService: ErrorService) {
    this.createInterviewTypeForm();
    this.createConductInterviewForm();
   }

  ngOnInit() {
      this.loadData();
  }
  
  ngOnDestroy() {
    this.componentAlive = false;
  }

  loadData() {
    this.loading = true;

    const variables = {
      id: this.applicationData.id
    }
    this.appApollo.watchQuery(GetInterviewDetails, variables).pipe(
        takeWhile(() => this.componentAlive))
        .subscribe((data: any) => {
            this.loading = false;
            const appDetails = JSON.parse(JSON.stringify(data.getApplication));
            this.appDetails = appDetails;
            if (appDetails && appDetails.interview_details) {
              this.interviewDetailsPresent = true;
              this.setInterviewDetails();
            } else {
              this.interviewDetailsPresent = false;
              this.openInterviewStep = 2;
            }
        }, err => {
            this.errorService.showErrorModal(err.networkErr);
        });
  }
		
  editInterview(){
	this.interviewHeader = "Interview";
    this.openInterviewStep = 1; 
  }

  onModifyInterviewDetails() {
	this.interviewHeader = "Schedule an interview"; 
   this.openInterviewStep = 2;
  }

  onCloseDialog() {
   this.openInterviewStep = 0;
  }

	stepBack() {
		this.openInterviewStep-=1;
	}

  createInterviewTypeForm() {
    this.interviewTypeForm = this.fb.group({
        type: new FormControl('online')
    })
	}

	createConductInterviewForm() {
			this.conductInterviewForm = this.fb.group({
					flag: new FormControl('yes')
			})
	}

	onSubmitInterviewType(value) {
			if (value.type == "online") {
					this.application["type"] = value.type;
					this.interviewHeader = "Online Interview";
					this.openInterviewStep = 3;
			} else {
					this.application["type"] = value.type;
					this.openInterviewStep = 6;
			}
	}

	onSubmitConductInterviewForm(value) {
			if (value.flag == "yes") {
          this.interviewHeader = "AIESEC Interview";
          const data = {
            "aiesec_to_conduct_interview": true
          };

          const variables = {
            id: this.applicationData.id,
            opportunity_application: data
          }
          this.appApollo.mutate(UpdateInterviewDetails, variables)
          .then((response: any) => {
            const application = JSON.parse(JSON.stringify(response.updateInterviewDetailsApplication));
            this.application = application;
            this.applicationService.applicationUpdated$.next();
            this.openInterviewStep = 4;
          }, err => {
            this.errorService.showErrorModal(err.networkErr);
          })

			} else {
					this.application["aiesec_to_conduct_interview"] = false;
					if (this.application["type"] == "online") {
							this.openInterviewStep = 5;
					} else {
						this.openInterviewStep = 6;
					}
			}
	}
  
  setInterviewDetails() {
    if (this.appDetails.interview_details.type == 'online') {
      this.interviewDetails = {
        data: this.appDetails.interview_slots,
        type: 'timeslots'
      }
      this.interviewDetails.data.forEach((slot, index) => {
        if (slot.accepted) {
          this.selectedTimeSlot = slot;
          this.selectedInterviewSlotIndex = index;
          this.acceptedInterview = true;
        }
      })
    } else {
      this.interviewDetails = {
        data: this.appDetails.offline_assessments,
        type: 'assessments'
      }
    }
  }

	closeModal() {
      this.openInterviewStep = 0;
      this.ngOnInit();
	}

	closeOnlineInterviewModal(data) {
      this.closeModal();
      if(data){
        this.interviewDetailsPresent = true;
        this.interviewDetails.data = data;
      }    
  }

  closeOfflineInterviewModal(data) {
    this.closeModal();
    if(data){
      this.interviewDetailsPresent = true;
    }    
  }
  
}
