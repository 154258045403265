import { Component, OnInit, EventEmitter, ElementRef, HostListener, Input, Output, SimpleChange} from '@angular/core';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss']
})

export class DropdownComponent implements OnInit{
    static count = 0;
    normalCount;
    @Input() data: Array<{ id: any, text: string }>;
    @Input() placeholder: string;
    @Input() type: string;
    @Input() autocomplete: string;
    @Input() editable = true;
    @Input() dropDownIndex: string;
    @Input() showChevron = true;
    @Input() active: Array<{ id: any, text: string }>;
    @Output() selected: EventEmitter<any> = new EventEmitter();
    @Output() removed: EventEmitter<any> = new EventEmitter();
    currentData: any;
    value: Array<{ id: any, text: string }> | { id: any, text: string } = [];
    searchText: string;
    dropdownToggle = false;
    private totalListHeight: any = 0;
    private totalKeyDowns = 0;
    private currentLiElement: any;
    private copyTotalKeyDowns = 0;
    private dropDownHeight: any = 0;

    constructor(private _elementRef: ElementRef) {
        DropdownComponent.count++;
        this.normalCount = DropdownComponent.count;
    }

    ngOnInit() {
        this.currentData = this.buildArray(this.data);
        if (this.type === 'single') {
            try {
                this.value = this.active[0];
                this.searchText = this.active[0].text;
            }
            catch (e) {
                this.searchText = '';
            }
        } else {
            // we are using concat instead of just assigning this.active as in the forms that this component is used and they use the
            // selection event to add it the list of values in their form values, it creates dual entries in the this.value variable
            // and creates certain other problems
            this.value = [].concat(this.active);
        }
    }

    ngOnChanges(changes: SimpleChange) {
        if (changes['data'] && changes['data'].currentValue) {
            this.currentData = this.buildArray(changes['data'].currentValue);
        }
    }

    dropdownOpen(value) {
        if (value === 'open') {
            // if (this.editable = true) {
                this.dropdownToggle = true;
                let i;
                let dropdowns;
                dropdowns = document.getElementsByClassName('at-dropdown-menu-' + this.normalCount);
                for (i = 0; i < dropdowns.length; i++) {
                    dropdowns[i].classList.add('show');
                }
            // }
        } else {
            this.dropdownToggle = !this.dropdownToggle;
            if (this.dropdownToggle) {
                let i;
                let dropdowns;
                dropdowns = document.getElementsByClassName('at-dropdown-menu-' + this.normalCount);
                for (i = 0; i < dropdowns.length; i++) {
                    dropdowns[i].classList.add('show');
                }
            } else {
                let i;
                let dropdowns;
                dropdowns = document.getElementsByClassName('at-dropdown-menu-' + this.normalCount);
                for (i = 0; i < dropdowns.length; i++) {
                    dropdowns[i].classList.remove('show');
                }
            }
        }
        this.currentLiElement = document.getElementById(this.type + this.dropDownIndex).querySelectorAll('li');
        if (this.currentLiElement[this.totalKeyDowns]) {
          this.currentLiElement[this.totalKeyDowns].classList.remove('active');
          this.resetDropDown();
        }
    }

    dropdownClose() {
      const dropdowns = document.getElementsByClassName('at-dropdown-menu-' + this.normalCount);
      for (let i = 0; i < dropdowns.length; i++) {
          dropdowns[i].classList.remove('show');
      }
    }

    @HostListener('document:click', ['$event.target'])
    onClick(targetElement) {
        const clickedInside = this._elementRef.nativeElement.contains(targetElement);
        if (!clickedInside) {
            this.dropdownToggle = false;
            let i;
            let dropdowns;
            dropdowns = document.getElementsByClassName('at-dropdown-menu-' + this.normalCount);
            for (i = 0; i < dropdowns.length; i++) {
                dropdowns[i].classList.remove('show');
            }
        }
    }

    resetDropDown() {
      setTimeout(() => {
        const div = document.getElementById(this.type + this.dropDownIndex);
        if (div) {
          this.currentLiElement = div.querySelectorAll('li');
          this.totalKeyDowns = 0;
          this.copyTotalKeyDowns = 0;
          if (this.currentLiElement[this.totalKeyDowns]) {
            this.currentLiElement[this.totalKeyDowns].classList.add('active');
            this.currentLiElement[this.totalKeyDowns].parentElement.scrollTop = 0;
            this.dropDownHeight = this.currentLiElement[this.totalKeyDowns].parentElement.offsetHeight;
            this.totalListHeight = this.currentLiElement[this.totalKeyDowns].offsetHeight;
          }
        }
      }, 100);
    }


    searchHandler(event) {

        if (!this.data) {
            console.log('No data provided for dropdown')
            return
        }

        const keyCode = event.keyCode;
        if (keyCode === 40 || keyCode === 38 || keyCode === 13 || keyCode === 9) {
          return;
        }

        const temp = this.data;

        this.currentData = [];
        for (const item of temp) {
            if (item.text) {
                if (typeof item.text === 'number') item.text = item.text + '';
                if (item.text.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1) {
                    if (!this.checkIfAlreadyPresent(item)) {
                        // if(!flag) this.currentData = [];
                        this.currentData.push(item);
                    }
                }
            }
        }

        if (this.currentLiElement[this.totalKeyDowns]) {
          this.currentLiElement[this.totalKeyDowns].classList.remove('active');
        }
        setTimeout(() => {
          this.currentLiElement = document.getElementById(this.type + this.dropDownIndex).querySelectorAll('li');
          if (this.currentLiElement[0]) {
            this.totalKeyDowns = 0;
            this.resetDropDown();
          }
        }, 300);

    }
    private checkIfAlreadyPresent(item: { id: number, text: string }) {
        if (Array.isArray(this.value)) {
            for (const obj of this.value) {
                if (obj.id === item.id) {
                    return 1;
                }
            }
        }

        return 0;
    }
    private buildArray(arr: Array<any>) {
        const newArray = [];
        for (const i in arr) {
            if (!this.checkIfAlreadyPresent(arr[i])) {
                newArray.push(arr[i]);
            }
        }
        return newArray;
    }

  arrowDownKey(event) {
    event.stopPropagation();
    if (this.totalKeyDowns > this.currentData.length - 2 || !this.currentLiElement[this.totalKeyDowns]) {
      return;
    }
    this.totalListHeight = this.totalListHeight + this.currentLiElement[this.totalKeyDowns].offsetHeight;
    this.currentLiElement[this.totalKeyDowns].classList.remove('active');
    this.totalKeyDowns++;
    this.currentLiElement[this.totalKeyDowns].classList.add('active');
    if (this.currentLiElement[this.totalKeyDowns].parentElement &&
        (this.dropDownHeight + this.currentLiElement[this.totalKeyDowns].parentElement.scrollTop) < this.totalListHeight) {
      this.copyTotalKeyDowns++;
      if (this.currentLiElement[this.totalKeyDowns + 1]) {
        this.currentLiElement[this.totalKeyDowns].parentElement.scrollTop =
            this.currentLiElement[this.totalKeyDowns].parentElement.scrollTop +
            this.currentLiElement[this.totalKeyDowns + 1].offsetHeight;
      } else {
        this.currentLiElement[this.totalKeyDowns].parentElement.scrollTop =
            this.currentLiElement[this.totalKeyDowns].parentElement.scrollTop +
            this.currentLiElement[this.currentData.length - 1].offsetHeight;
      }
    }
  }

  arrowUpKey(event) {
    event.stopPropagation();
    if (this.totalKeyDowns === 0 || !this.currentLiElement[this.totalKeyDowns]) {
      return;
    }
    this.currentLiElement[this.totalKeyDowns].classList.remove('active');
    this.totalKeyDowns--;
    this.totalListHeight = this.totalListHeight - this.currentLiElement[this.totalKeyDowns].offsetHeight;
    this.currentLiElement[this.totalKeyDowns].classList.add('active');

    if (this.currentLiElement[this.totalKeyDowns].parentElement &&
        (this.currentLiElement[this.totalKeyDowns].parentElement.scrollTop > this.totalListHeight - 40)) {
      this.copyTotalKeyDowns--;
      this.currentLiElement[this.totalKeyDowns].parentElement.scrollTop =
          this.currentLiElement[this.totalKeyDowns].parentElement.scrollTop -
          this.currentLiElement[this.totalKeyDowns + 1].offsetHeight;
    }
  }


    selection(event, selection, index) {
        this.dropdownToggle = false;
        event.stopPropagation();
        if (this.type === 'multi') {
            const temp = [];
            this.value = this.value ? this.value : [];
            if (Array.isArray(this.value)) this.value.push(selection);
            const tempArray = this.currentData.slice(0, index).concat(this.currentData.slice(index + 1));
            this.currentData = tempArray;
            this.searchText = '';
            this.selected.emit(selection);
        }
        else {
            this.searchText = selection.text;
            this.value = selection;
            this.selected.emit(selection);
        }
        this.dropdownClose();
        // this.selections = {};
        // this.selections[listName] = this.searchForm.controls[listName].value;
    }

    removeSelection(event, index) {
        event.stopPropagation();
        const temp = this.currentData;
        temp.splice(index, 1);
        if (Array.isArray(this.value)) this.value.splice(index, 1);
        this.removed.emit(index);
    }
}
