
import {map} from 'rxjs/operators';
import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { ListService } from 'app/services/list.service';
import { Apollo } from 'apollo-angular';
import { UserService } from 'app/services/user.service';
import { ErrorService } from 'app/services/error.service';
import { ModalDirective } from 'ngx-bootstrap';
import { CurrentPersonUpdate, GetCitiesByMc, FindLcQuery } from './update-homelc.graphql';

@Component({
  selector: 'app-update-homelc',
  templateUrl: './update-homelc.component.html',
  styleUrls: ['./update-homelc.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class UpdateHomelcComponent implements OnInit {

  @ViewChild('updateHomelcModal', { static: true }) updateHomelcModal: ModalDirective;
  @ViewChild('homeLcSuccess', { static: true }) homeLcSuccess: ModalDirective;
  mcList: any;
  cities: any;
  errorMessages: any = {};
  homeLc: any;
  loading: boolean;

  constructor(
    private listService: ListService,
    private apollo: Apollo,
    private userService: UserService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
    this.loadConstants();
  }

  submit() {
    if (this.loading) {
      return;
    }
    if (!this.homeLc) {
      return;
    }

    this.loading = true;
    this.apollo.mutate({
      mutation: CurrentPersonUpdate,
      variables: {
        person: {
          home_lc_id: +this.homeLc.id
        }
      }
    }).pipe(
    map((data: any) => data.data.currentPersonUpdate))
    .subscribe(currentPerson => {
      this.loading = false;
      this.userService.currentUser = currentPerson;
      this.userService.userDataLoading.next(true);
      this.updateHomelcModal.hide();
      this.homeLcSuccess.show()
    }, err => {
      this.loading = false;
      this.errorService.showErrorModal(err);
    })
  }

  fetchCities(mc_id) {
    this.errorMessages.fetchLc = null;
    this.homeLc = null;
    this.cities = null;
    this.loading = true;
    this.apollo.query({
      query: GetCitiesByMc,
      variables: {mc_id: parseInt(mc_id, 10), allocated_to_lc: true}
    }).pipe(
    map((data: any) => data.data.cityByMc))
    .subscribe((cities: any) => {
      this.loading = false;
      if (!cities.length) {
        this.errorMessages.fetchLc = 'Sorry we do not have any offices in your country';
        this.cities = [];
        return;
      }
      this.cities = JSON.parse(JSON.stringify(cities));
      this.cities.forEach(c => {
        c.text = c.name;
      });
    }, err => {
      this.loading = false;
      this.errorMessages.fetchLc = 'Error fetching city list, please try again';
      this.cities = [];
    });
  }

  fetchLc(city) {
    if (!city) {
      return
    }

    this.homeLc = null;
    this.loading = true
    this.errorMessages.fetchLc = null

    const variables = {
      city_id: city.id,
      city_name: city.name
    };

    this.apollo.query({query: FindLcQuery, variables: variables})
    .subscribe((res: any) => {
      this.loading = false;
      this.homeLc = res.data.findLc;
    }, error => {
      this.loading = false;
      this.errorMessages.fetchLc = error.json ? error.json().error : error.error.error;
    });

  }

  close() {
    this.updateHomelcModal.hide();
  }

  closeSuccessModal() {
    this.homeLcSuccess.hide();
  }

  loadConstants() {
     this.listService.getCommiteeList('MC', 'mcs').then((mcs: any) => {
      this.mcList = mcs;
    });
  }

}
