import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'app/services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private userService: UserService,
    private router: Router,
    ) { }

  goToLogin() {
    window.location.href = 'auth?action=sign_in';
  }

  canActivate(
    _next: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return new Observable(observer => {
        this.userService.userPresent.subscribe(data => {
          if (data) {
            observer.next(true);
          } else {
            this.goToLogin();
          }
        }, _err => {
          this.goToLogin()
        })
      })
  }
}
