
import {timer as observableTimer} from 'rxjs';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import gql from 'graphql-tag';
import { Apollo } from 'apollo-angular';

const PeopleAutocompleteEmployeesQuery = gql`
  query peopleAutocompleteEmployees($q: String) {
    peopleAutocompleteEmployees(q: $q) {
      id
      full_name
    }
  }
`
@Component({
  selector: 'app-assign-managers',
  templateUrl: './assign-managers.component.html',
  styleUrls: ['./assign-managers.component.scss']
})
export class AssignManagersComponent implements OnInit {

  @Input() managers: any;
  @Input() title: string;
  @Output() close: EventEmitter<boolean> = new EventEmitter(null);
  @Output() assignManager: EventEmitter<any> = new EventEmitter(null);
  @Output() unassignManager: EventEmitter<any> = new EventEmitter(null);
  application: any;
  timerSubscription: any;
  subscription: any;
  disabled = true;
  selectedEmployee: any;
  queriedEmployees: any;

  constructor(private apollo: Apollo) {
   }

  ngOnInit() {
  }


  triggerClose() {
    this.close.emit(true)
  }


  fetchEmployeesForDropdown(searchText) {
    this.disabled = true

    const timer = observableTimer(300)
    if (this.timerSubscription) {
      this.timerSubscription.unsubscribe()
    }
    this.timerSubscription = timer.subscribe(t => fetchEmployees.apply(this))

    if (this.subscription)  {
      this.subscription.unsubscribe();
    }

    const fetchEmployees = () => {
      this.subscription = this.apollo.query({query: PeopleAutocompleteEmployeesQuery, variables: {q: searchText}})
                          .subscribe((res: any) => {
                            this.queriedEmployees = res.data.peopleAutocompleteEmployees.map((employee) => {
                              return {id: employee.id, value: employee.full_name}
                            });
                            if (this.queriedEmployees.length > 0) {
                                this.disabled = false
                            }
                          });
    }

    fetchEmployees()

  }

  setEmployee(employee) {
    this.selectedEmployee = employee;
  }

  triggerAssign() {
    this.assignManager.emit(this.selectedEmployee)
  }

  triggerUnassign(managerId) {
    this.unassignManager.emit(managerId)
  }

}
