 
import { NgModule } from '@angular/core';

import { ModelService } from './services/model.service';
import { UserService } from './services/user.service';
import { ListService } from './services/list.service';
import { OrganisationService } from './services/organisation.service';
import { ApplicationsService } from './services/applications.service';
import { SearchService } from './services/search.service';
import { GhostModelService } from './services/ghostModel.service';
import { ErrorService } from './services/error.service';
import { Opportunity } from './services/opportunity.service';
import { ConfirmationService } from './services/confirmation.service';
import { InfoModalService } from './blocks/info-modal/info-modal.service';
import { LinearProgressBarService } from './blocks/linear-progress-bar/linear-progress-bar.service';
import {LinearProgressBarModule} from './blocks/linear-progress-bar/linear-progress-bar.module';
import { ActionBannerService } from './blocks/action-banner/action-banner.service';

@NgModule({
    imports: [
        LinearProgressBarModule,
    ],
    providers: [
        ModelService,
        UserService,
        ListService,
        OrganisationService,
        ApplicationsService,
        SearchService,
        Opportunity,
        GhostModelService,
        ErrorService,
        ConfirmationService,
        InfoModalService,
        LinearProgressBarService,
        ActionBannerService
    ]
})
export class ServicesModule { }
