import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convert24hTo12h'
})
export class Convert24hTo12hFormatPipe implements PipeTransform {

  transform(time: any): any {		// time format accepted currently: '0000' to '2359'
		if (!time) {
			return 'Not Available';
		}

		let meridian = 'am';
		let hour = time.slice(0, 2);
		let	minutes = time.slice(2, 4);
		if (hour >= 12) {
			hour = (hour % 12) || 12;
			meridian = 'pm';
		}
		if (hour === '00') {
			hour = 12;
		}
		return `${hour}:${minutes}${meridian}`;
		}

}
