import gql from 'graphql-tag';

export const CreateOrganisationScorecardQuery = gql`
    mutation OrganisationMutationQuery($company_id: Int, $name: String, $organisation_scorecard_attribute: [ ScorecardAttributeInput ]) {
        organisationScorecardCreate(company_id: $company_id, name: $name, organisation_scorecard_attribute: $organisation_scorecard_attribute ) {
            id
            name
            permissions{
                can_delete
            }
            scorecard_attributes {
                id
                name
            }
        }
    }
`
