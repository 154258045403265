import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent implements OnInit {

  @ViewChild('termsConditionsModal', { static: false }) termsConditionsModal: ModalDirective;
  @Input() showModal: boolean;
  @Input() title: string
  @Input() purpose: string
  @Input() body: string
  @Output() accepted: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  confirm() {
    this.accepted.emit(true)
  }

  close() {
    this.accepted.emit(false)
    this.termsConditionsModal.hide()
  }

  onHidden() {
    this.showModal = false
  }

}
