import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cm-floating-info',
  templateUrl: './cm-floating-info.component.html',
  styleUrls: ['./cm-floating-info.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class CmFloatingInfoComponent implements OnInit {

  @Input() text: string;
  @Input() dismissable = false;
  @Output() close: EventEmitter<any> = new EventEmitter(null);

  show

  constructor() { }

  ngOnInit() {
  }

  onClose() {
    this.close.emit(true);
  }

}
