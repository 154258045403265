import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from "@angular/core";
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ApplicationsService } from "app/services/applications.service";
import { ValidationMessages } from "app/validation-component/validation-constant";
import { AppApolloService } from "app/services/app.apollo.service";
import { InterviewSlotApplicationMutation, PeopleAutocompleteEmployeesQuery, UpdateInterviewDetails } from '../interview-details/interview-details.graphql';
import { ErrorService } from "app/services/error.service";
import { GetInterviewDetails } from '../interview-details/interview-details.graphql';

export enum Views {
    INTERVIEW_DETAILS, TIME_SLOT
}

@Component({
    selector: 'app-online-interview-modal',
    templateUrl: './online-interview-modal.component.html',
    styleUrls: ['./online-interview-modal.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class OnlineInterviewModalComponent implements OnInit {
    @Input() applicationOpportunityID: any;
    @Input() onlineInterviewObj;
    @Output() close: EventEmitter<any> = new EventEmitter(null);

    // Constant Variables
    enumView = Views;
    activeView: Views = Views.INTERVIEW_DETAILS;
    validationMsg = new ValidationMessages();

    // Objects
    applications = new Object();

    // Array Object
    queriedAttendees: any[] = [];
    attendees: any[] = [];
    interviewModes: any[] = [];

    // Form Group
    onlineInterviewForm: FormGroup;
    interviewTimeFlowForm: FormGroup;

    // Other objects
    modalHeaderLabel: string = "Interview Details";
    isMaxTimeSlot: boolean;
    subscription: any;
    disabled: boolean;
    showTimePicker = [];
    time = [];
    timeChange = [];

    constructor(
        private fb: FormBuilder,
        private applicationsService: ApplicationsService,
        private appApollo: AppApolloService,
        private errorService: ErrorService) {
        this.interviewModes = [{ id: 0, text: 'Call', value: 'call' }, { id: 0, text: 'Skype', value: 'skype' }, { id: 0, text: 'Hangout', value: 'hangout' }]
    }

    ngOnInit() {
        this.createInterviewForm();
        this.createInterviewTimeFlowForm();
        (this.interviewTimeFlowForm.get('interview_slotsArray') as FormArray).valueChanges.subscribe(slots=>{
            for (let i = 0; i < slots.length; i++) {
                let timeFormat = slots[i].newTimeslot; 
                if(timeFormat) {
                    timeFormat = timeFormat.toString();
                    timeFormat = timeFormat.substring(16, 24);
                    this.timeChange[i] = true;
                    this.time[i] = this.setTime(timeFormat)
                }
            }       
        });
    }


    openTimePicker(i) {
        this.showTimePicker[i] = true;
    }

    closeTimePicker(i) {
        this.showTimePicker[i] = false;
    }

    setInterviewMode(mode) {
        this.onlineInterviewForm.get('mode').patchValue(mode.value)
    }

    createInterviewForm() {
        this.onlineInterviewForm = this.fb.group({
            interview_platform_url: new FormControl('', [<any>Validators.required]),
            mode: new FormControl('', [<any>Validators.required]),
            description: new FormControl('', [<any>Validators.required]),
        })
    }

    createInterviewTimeFlowForm() {
        this.interviewTimeFlowForm = this.fb.group({
            interview_slotsArray: new FormArray([this.timeSlot()]),
        })
    }

    timeSlot() {
        return new FormGroup({
            timeslot: new FormControl('', [<any>Validators.required]),
            newTimeslot: new FormControl('', [<any>Validators.required])

        })
    }

    get interview_slotsArray(): FormArray {
        return this.interviewTimeFlowForm.get('interview_slotsArray') as FormArray;
    }

    addSlot() {
        if (this.interview_slotsArray.length < 3) {
            this.interview_slotsArray.push(this.timeSlot());
            if (this.interview_slotsArray.length === 3) {
                this.isMaxTimeSlot = true;
            }
        } else {
            this.isMaxTimeSlot = true;
        }
    }

    removeSlot(index) {
        this.interview_slotsArray.removeAt(index);
        this.isMaxTimeSlot = false;
    }

    onSubmitOnlineInterview(formParam, isValid) {
        if (isValid) {
            let values = Object.assign(this.onlineInterviewObj, formParam);
            let attendeeEmails = []
            this.attendees.forEach(item => {
                attendeeEmails.push(item.email)
            })

            const variables = {
                id: this.applicationOpportunityID,
                opportunity_application: values,
                attendee_emails: attendeeEmails
            }
            this.appApollo.mutate(UpdateInterviewDetails, variables)
                .then((data: any) => {
                    this.applicationsService.applicationUpdated$.next(true);
                    this.activeView = Views.TIME_SLOT;
                    this.modalHeaderLabel = "Please propose up to three time availabilities for the interview"
                }, err => {
                    this.errorService.showErrorModal(err.networkErr);
                })
        }
    }

    setTime(time) {
        time = time.split(':');
        let hours = time[0];
        let minutes = time[1];
        let period;
        if (hours > 12) {
          hours -= 12;
          period = "pm";
        } else if (hours == 0) { 
          hours = 12
          period = "am";
        }
        else if (hours == 12) {
          hours = 12;
          period = "pm";
        }
        else {
          period = "am";
        }     
         return hours +" : "+ minutes +" "+ period;
      }


    onsubmitInterviewSlot(formParam, isValid) {
        if (isValid) {
            let interviewSlots = [];
            for (let i = 0; i < formParam.interview_slotsArray.length; i++) {
                let timeFormat = formParam.interview_slotsArray[i].newTimeslot.toString();
                timeFormat = timeFormat.substring(16, 24);
                let dateTime = formParam.interview_slotsArray[i].timeslot.toString();
                dateTime = dateTime.replace('00:00:00', timeFormat);
                let body = {
                    timeslot: dateTime
                }
                interviewSlots.push(body);
            }

            const variables = {
                id: this.applicationOpportunityID,
                interview_slots: interviewSlots
            }
            const refetchQuery = {
                query: GetInterviewDetails,
                variables: {
                    id: this.applicationOpportunityID
                }
            }
            this.appApollo.mutateWithRefetch(InterviewSlotApplicationMutation, variables, [refetchQuery])
                .then((interviewSlotData: any) => {
                    this.applicationsService.applicationUpdated$.next();
                    this.close.emit(interviewSlotData.interviewSlotApplication);
                })
        }
    }

    onInterviewSloBack() {
        // this.activeView = Views.INTERVIEW_DETAILS;
        // this.modalHeaderLabel = "Interview Details";
        // this.createInterviewForm();
        this.close.emit(null);
    }

    fetchAttendees(searchText) {

        if (this.subscription) this.subscription.unsubscribe()

        const variables = {
            q: searchText
        }
        this.subscription = this.appApollo.watchQuery(PeopleAutocompleteEmployeesQuery, variables, 'cache-first')
            .subscribe((peopleData: any) => {
                const employees = peopleData.peopleAutocompleteEmployees;
                this.queriedAttendees = employees.map(employee => ({ id: employee.id, value: employee.full_name }))
                if (employees.length > 0) {
                    this.disabled = false
                }
            }, err => {
                console.log("error fetching autocomplete employees: ", err);
            })
    }

    setAttendee(employee) {
        let existing = this.attendees.find(attendee => attendee.id === employee.id)
        if (!existing) {
            this.attendees.push(employee)
        }
        else return
    }

    removeAttendee(employee) {
        this.attendees = this.attendees.filter(attendee => attendee.id !== employee.id)
    }

    closeModal() {
        this.close.emit();
    }

    isRequiredValidation(formControlName) {
        return formControlName.hasError('required') && formControlName.touched;
    }

    isValidPattern(formControlName) {
        return formControlName.hasError('pattern')
    }

}
