import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CarouselModule, TooltipModule, ModalModule, BsDatepickerModule } from 'ngx-bootstrap';
import { CKEditorModule } from 'ng2-ckeditor';

import { SharedModule } from 'app/shared.module';
import { OpportunityApplicationComponent } from "app/content/opportunity/applications/opportunity-application/opportunity-application.component";
import { InterviewModalComponent } from "app/content/opportunity/applications/opportunity-application/interview-modal/interview-modal.component";
import { OnlineInterviewModalComponent } from "app/content/opportunity/applications/opportunity-application/online-interview-modal/online-interview-modal.component";
import { OfflineInterviewModalComponent } from 'app/content/opportunity/applications/opportunity-application/offline-interview-modal/offline-interview-modal.component';
import { InterviewDetailsComponent } from 'app/content/opportunity/applications/opportunity-application/interview-details/interview-details.component';
import { CommentsComponent } from "app/content/opportunity/applications/opportunity-application/comments/comments.component";

import { OpportunityDropdownComponent } from 'app/blocks/opportunity-dropdown/opportunity-dropdown.component';
import { TimepickerModule } from 'ngx-bootstrap';
import { QuestionnaireResponseComponent } from './questionnaire-response/questionnaire-response.component'
import { RejectApplicationModule } from '../reject-application/reject-application.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
    CarouselModule.forRoot(),
    ModalModule,
    CKEditorModule,
    SharedModule,
    RejectApplicationModule,
    TimepickerModule.forRoot(),
    BsDatepickerModule.forRoot(),
  ],
  declarations: [
    OpportunityApplicationComponent,
    InterviewModalComponent,
    InterviewModalComponent,
    OnlineInterviewModalComponent,
    OfflineInterviewModalComponent,
    InterviewDetailsComponent,
    CommentsComponent,
    OpportunityDropdownComponent,
    QuestionnaireResponseComponent,
  ],
  exports: [
    TooltipModule,
    CarouselModule,
    ModalModule,
    CKEditorModule,
    OpportunityApplicationComponent,
    InterviewModalComponent,
    InterviewModalComponent,
    OnlineInterviewModalComponent,
    OfflineInterviewModalComponent,
    InterviewDetailsComponent,
    CommentsComponent,
    OpportunityDropdownComponent
  ]
})
export class OpportunityApplicationModule { }
