import { NgModule, ErrorHandler } from '@angular/core';
import { environment } from '../environments/environment';
import * as Raven from 'raven-js';

const isErrorOrErrorEvent =  (wat) => {
    return Object.prototype.toString.call(wat) === '[object Error]' || Object.prototype.toString.call(wat) === '[object ErrorEvent]';
}

const retrieveMessage = err => {
    if (!err) {
        return null;
    }

    const error = err.networkError || err.networkErr || err;

    if (error.error && error.error.error) {
        return error.error.error;
    }

    if (err.error && err.error.status && err.error.status.message) {
        return err.error.status.message;
    }

    if (error.error) {
        return error.error;
    }

    if (error.message) {
        return error.message;
    }

    return null;
}

export class RavenErrorHandler implements ErrorHandler {
    handleError(err: any): void {
        if (!environment.production) {
            console.error(err)
        }

        if (isErrorOrErrorEvent(err)) {
            Raven.captureException(err.stack || err);
        } else {
            const message = retrieveMessage(err);
            if (message) {
                if (typeof message === 'string') {
                    Raven.captureException(new Error(message));
                } else {
                    Raven.captureException(new Error(JSON.stringify(message)));
                }
            } else {
                Raven.captureMessage(JSON.stringify(err));
            }
        }
    }
}

const providers = [];
if (!environment.development) {
    Raven
        .config('https://bb45a15a3fae4d6dabdc92e555416deb@sentry.io/286814', {
            autoBreadcrumbs: {
                'console': false,
            }
        })
        .install();

    providers.push({ provide: ErrorHandler, useClass: RavenErrorHandler })
}

@NgModule({
    declarations: [],
    imports: [],
    providers: providers,
})


export class RavenModule {}
