import { Component, OnInit, Input, Output, EventEmitter, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-autocomplete-dropdown',
  templateUrl: './autocomplete-dropdown.component.html',
  styleUrls: ['./autocomplete-dropdown.component.scss']
})
export class AutocompleteDropdownComponent implements OnInit {

  @Input() dataArray: Array<{ id: any, value: string }>;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() active: Array<{ id: any, text: string }>;
  @Input() initialSearchText: string;
  @Output() keyupEvent: EventEmitter<any> = new EventEmitter(null);
  @Output() selected: EventEmitter<any> = new EventEmitter();
  searchText: string;
  value: Array<{ id: any, text: string }> | { id: any, text: string } = [];
  dropdownValueSelected: boolean;

  static count:number = 0;
  normalCount;

  constructor(private _elementRef : ElementRef) {
    AutocompleteDropdownComponent.count++;
    this.normalCount = AutocompleteDropdownComponent.count;
   }

  ngOnInit() {
    if(this.initialSearchText) {
      this.searchText = this.initialSearchText;
    }
  }

  triggerKeyup() {
    if (this.dropdownValueSelected) {
      this.dropdownValueSelected = false;
      return;
    }
    this.keyupEvent.emit(this.searchText)
  }

  selection(selection, index) {
      this.searchText = selection.value;
      this.value = selection;
      this.selected.emit(selection);
      this.dropdownValueSelected = true;
  }

  dropdownOpen() {
    var i;
    var dropdowns;
    dropdowns = document.getElementsByClassName("auto-dropdown-menu-" + this.normalCount);
    for (i = 0; i < dropdowns.length; i++) {
      dropdowns[i].classList.toggle("show");
    }
  }
  dropdownClose() {
    var i;
    var dropdowns;
    dropdowns = document.getElementsByClassName("auto-dropdown-menu-" + this.normalCount);
    for (i = 0; i < dropdowns.length; i++) {
      dropdowns[i].classList.remove("show");
    }
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {

      const clickedInside = this._elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
          var i;
          var dropdowns;
          dropdowns = document.getElementsByClassName("auto-dropdown-menu-"+this.normalCount);
          for (i = 0; i < dropdowns.length; i++) {
              dropdowns[i].classList.remove("show");
          }
      }
  }

}
