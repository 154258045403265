import { Directive, ElementRef, Renderer2, Input, Output, EventEmitter } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Directive({ selector: '[searchInput]' })
export class SearchInputDirective {

    _text: string;
    searchInputUpdated: Subject<string> = new Subject();
    searchChangeEmitter: Observable<string>;

    @Input() 
    set text(text: string) {
        this._text = text;
        this.searchInputUpdated.next(this._text);
    }
     
    get text(): string { return this._text; }
    @Output() inputChanged = new EventEmitter();

    constructor(elem: ElementRef, renderer: Renderer2) {
        this.subscribeToSearchInput();
    }

    subscribeToSearchInput() {
        this.searchChangeEmitter = this.searchInputUpdated.asObservable()
            .pipe(
                debounceTime(300),
                distinctUntilChanged()
            );

        this.searchChangeEmitter.subscribe(searchInput => {
            this.inputChanged.emit(searchInput);
        })
    }
}
