import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap';
import { RejectApplicationComponent } from './reject-application.component';
import { TranslateModule } from 'app/pipes/translate.module';


@NgModule({
  imports: [
      ModalModule,
      CommonModule,
      TranslateModule,
  ],
  declarations: [
      RejectApplicationComponent
  ],
  exports: [
    RejectApplicationComponent
  ]
})
export class RejectApplicationModule { }
