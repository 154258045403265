import { Component, OnInit, Input, Output, EventEmitter, HostListener } from '@angular/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent implements OnInit {

  @Input() message: string;
  @Input() timeoutLimit: number = 5*1000;
  @Input() errorShown: boolean = false;
  @Output() hide: EventEmitter<any> = new EventEmitter();

  show: boolean = true;

  @HostListener('document:click', ['$event'])
  handlerFunction(e: MouseEvent) {
      if(this.errorShown) this.hide.emit(true);
      this.errorShown = true;
  }

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      this.show = false;
      this.hide.emit(true);
    }, this.timeoutLimit)
  }

}
