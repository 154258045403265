import { Component, OnInit, Input } from '@angular/core';
@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  @Input() array: any;
  @Input() opportunityId: any;
  @Input() type: string;
  @Input() infiniteScroll: boolean;
  @Input() data: any;
  @Input() opportunityCountStats: any;

  constructor() { }

  ngOnInit() {
  }

}
