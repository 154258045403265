
import {filter,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from './../../environments/environment';
import { Apollo } from 'apollo-angular';
import { HttpLink } from 'apollo-angular-link-http';
import { TokenService } from './token.service';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { Observable } from 'rxjs';
import { ApolloLink, concat } from 'apollo-link';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';

@Injectable()
export class AppApolloService {

  schema: any;
  environment: any;

  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private tokenService: TokenService,
    private http: HttpClient,
  ) { }

  async initializeApollo(): Promise<any> {

    this.environment = environment;

    const http = this.httpLink.create({ uri: `${environment.apiBase}/graphql` });

    const fragmentMatcher = await this.buildFragmentMatcher().catch(e => {
      console.log('error getting fragment matcher: ', e);
    });
    const cache = this.buildCache(fragmentMatcher);

    return new Promise((resolve, reject) => {
      this.tokenService.tokenPresent.pipe(
        filter(token => token !== null))
        .subscribe(token => {
          this.apollo.create({
            link: concat(this.authMiddleware(), http),
            cache,
          })
          resolve();
        });
    });
  }


  authMiddleware() {
    return new ApolloLink((operation, forward) => {
      // Check for token
      const token = this.tokenService.token || this.environment.publicToken;
      if (!token) {
        return forward(operation);
      }
      // add the authorization to the headers
      operation.setContext({
        headers: new HttpHeaders().set('Authorization', token)
      });

      return forward(operation);
    });
  }

  buildCache(fragmentMatcher?) {
    // This is to overcome apollo bug which throws error if no query is present to be read
    let cache;
    if (fragmentMatcher) {
      cache = new InMemoryCache({fragmentMatcher});
    } else {
      cache = new InMemoryCache();
    }
    cache.originalReadQuery = cache.readQuery;
    cache.readQuery = (...args) => {
      try {
        return cache.originalReadQuery(...args);
      } catch (err) {
        return null;
      }
    };
    return cache;
  }

  buildFragmentMatcher(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let fragmentMatcher;

      try {
        this.schema = await this.fetchSchema();
        if (!this.schema) {
          return reject(new Error('Schema not found'));
        }
        fragmentMatcher = new IntrospectionFragmentMatcher({
          introspectionQueryResultData: this.schema
        });
        return resolve(fragmentMatcher);
      } catch (e) {
        return reject();
      }
    });
  }

  query(query, variables, fetchPolicy?) {
    return new Promise((resolve, reject) => {
      this.apollo.query({
        query: query,
        variables: variables,
        fetchPolicy: fetchPolicy || 'cache-first'
      }).pipe(map(res => res.data))
        .subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
    })
  }

  watchQuery(query, variables, fetchPolicy?): Observable<any> {
    return Observable.create(observer => {
      this.apollo.watchQuery({
        query: query,
        variables: variables,
        fetchPolicy: fetchPolicy || 'cache-first'
      })
        .valueChanges
        .pipe(
          map(res => res.data)
        ).pipe(
        filter(data => data !== undefined))
        .subscribe(data => {
          observer.next(data)
        }, err => {
          observer.error(err)
        })
    })
  }

  mutate(mutation, variables) {
    return new Promise((resolve, reject) => {
      this.apollo.mutate({
        mutation: mutation,
        variables: variables
      }).pipe(
        map(res => res.data))
        .subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
    })
  }

  mutateWithRefetch(mutationQuery, mutationVariables, refetch) {
    const refetchQueries = refetch.map(queryData => ({
      query: queryData.query,
      variables: queryData.variables
    }))
    return new Promise((resolve, reject) => {
      this.apollo.mutate({
        mutation: mutationQuery,
        variables: mutationVariables,
        refetchQueries: refetchQueries
      }).pipe(
        map(res => res.data))
        .subscribe(data => {
          resolve(data)
        }, err => {
          reject(err)
        })
    })
  }

  async fetchSchema() {
    return new Promise((resolve, reject) => {
      this.http.get('./assets/fragmentTypes.json')
      .subscribe(async res => {
          const response = await res;
          resolve(response);
      }, async error => {
        console.log(error);
        console.log('Fetching schema again..');
        const res = await this.fetchSchemaOnRuntime().catch(e => {
          reject(null);
        });
        if (res) {
          resolve(res);
        }
        reject(null);
      });
    });
  }

  fetchSchemaOnRuntime() {
    const token = this.tokenService.token;
    let uri;

    if (token) {
      uri = `${environment.apiBase}/graphql?access_token=${token}`;
    } else {
      uri = `${environment.apiBase}/graphql`;
    }

    const apollo = new ApolloClient({
      cache: new InMemoryCache,
      link: this.httpLink.create({uri})
    });

    const query = gql`
    {
      __schema {
        types {
          kind
          name
          possibleTypes {
            name
          }
        }
      }
    }
    `;

    return new Promise((resolve, reject) => {
      apollo.query({
        query,
        variables: {}
      })
      .then((res: any) => {
        const filteredData = res.data.__schema.types.filter(
          type => type.possibleTypes !== null
        );

        const result = JSON.parse(JSON.stringify(res.data));
        result.__schema.types = filteredData;
        resolve(result);
      }, err => {
        reject(err);
      });
    });
  }
}

