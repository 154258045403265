import { Injectable } from '@angular/core';
// import {CookieService} from 'ngx-cookie';
import { Subject } from 'rxjs';

@Injectable()
export class ActionBannerService {

  showBanner = new Subject();
  isBannerActive = false;

  constructor(
    // private _cookieService: CookieService,
  ) {
  }

  hideActionBanner() {
    // const date = new Date();
    // this._cookieService.put('action_banner_cookie_policy', 'true',
    // 		{'expires': new Date(date.getFullYear() + 1, date.getMonth(), date.getDate())});

    sessionStorage.setItem('action_banner', 'true');

    this.showBanner.next(false);
    this.isBannerActive = false;
  }

}
