import {AbstractControl} from "@angular/forms";
import * as moment from 'moment';

export class ValidatorService {
  static isTwoOptionsChosen(group): { [key: string]: boolean } {
    let count = 0;
    let controls = group.controls;
    for (let item in controls) {
      if (controls[item].value === true) count++;
    }
    return (count !== 2) ? {hasNotChosenTwoOptions: true} : null
  }

  static sameNationalitiesChosen(arr: any): { [key: string]: boolean } {
    let values = [];
    let controls = arr.controls;

    if (controls.length >= 2) {
      let flag = false;

      for (let item of controls) {
        values.push(item.value.nationality);
      }
      // console.log('values', values);
      for (let i = 0; i < values.length; i++) {
        for (let j = i + 1; j < values.length; j++) {
          if (values[i] && values[j]) {
            if (values[i].id === values[j].id) {
              flag = true;
              break;
            }
          }
        }
        if (flag) break;
      }
      if (flag) return {sameNationalities: true};
      else return null;
    }
    return null;

  }

  static isOneChosen(arr): { [key: string]: boolean } {
    let count = 0;
    let controls = arr.controls;
    if (controls.length < 1 || controls.length > 2) {
      return {
        hasNotChosen: true
      };
    } else {
      return null;
    }
  }

  static dob(control): { [key: string]: boolean } {
    return control.value ? null : {noValue: true};
  }

  static minOne(arr): { [key: string]: boolean } {
    let controls = arr.controls
    return controls.length >= 1 ? null : {noneChosen: true};
  }

  static endDate(o: string, ed: string) {
    return (group): { [key: string]: any } => {
      let ongoing = group.controls[o];
      let end_date = group.controls[ed];

      if (ongoing.value && end_date.value && end_date.value.formatted) {
        return {bothPresent: true}
      } else if (!ongoing.value && !(end_date.value && end_date.value.formatted)) {
        return {bothAbsent: true}
      } else {
        return null;
      }
    }
  }

  static forbiddenTextValidator(nameRe: RegExp) {
    const mailRegex =/(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

    return (control): { [key: string]: any } => {
      const forbiddenMail = mailRegex.test(control.value);
      const forbiddenLink = nameRe.test(control.value);
      return (forbiddenLink || forbiddenMail) ? {'forbiddenText': 'Websites links and email addresses are not allowed'} : null;
    };
  }

  static selectionLimit(count: number) {
    return (group): { [key: string]: any } => {
      const forbidden = group.length > 3;
      return forbidden ? {'countError': `  more than ${count} not allowed`} : null
    }
  }

  static weekDifference(start: number, end: number) {
    return (group): { [key: string]: any } => {
      if (group.controls.latest_end_date.value && group.controls.earliest_start_date.value) {
        let a = new Date(group.controls.latest_end_date.value);
        let b = new Date(group.controls.earliest_start_date.value);
        let weeks = Math.round((a.getTime() - b.getTime()) / 604800000);
        const forbidden = weeks < start || weeks > end;
        return forbidden ? {'dateError': ` should be between ${start} and ${end} weeks`} : null
      } else {
        return {'dateError': ' not supplied'}
      }
    }
  }

  static weekDifferenceMoment(start: number, end: number) {
    return (group): { [key: string]: any } => {
      if (group.controls.latest_end_date.value && group.controls.earliest_start_date.value) {
        const sDate = group.controls.earliest_start_date.value;
        const eDate = group.controls.latest_end_date.value;
        const isForbidden = moment(eDate).diff(sDate, 'weeks') < start || moment(eDate).diff(sDate, 'weeks') > end;
        return isForbidden ? {'dateError': ` should be between ${start} and ${end} weeks`} : null;
      } else {
        return {'dateError': ' not supplied'}
      }
    };
  }

  static tenDays(control): { [key: string]: any } {
    if (control.value) {
      let oneDay = 24 * 60 * 60 * 1000;
      let now = new Date();
      let value = new Date(control.value);
      let diff = Math.round(Math.abs((value.getTime() - now.getTime()) / (oneDay)));
      const forbidden = diff < 10;
      return forbidden ? {'tenDays': ` should be at least 10 days from today`} : null;
    } else {
      return {'tenDays': ` not supplied`}
    }
  }

  static minLengthArray(min: number) {
    return (c: AbstractControl): { [key: string]: any } => {
      if (c.value.length >= min)
        return null;

      return {'minLengthArray': {valid: false}};
    }
  }

  static testEmail() {
    const mailRegex =  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return (control): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const isEmail = mailRegex.test(control.value);
      return (!isEmail) ? {'pattern': ''} : null;
    };
  }

  static closeDate() {
    return (group): { [key: string]: any } => {
      if (group.controls.applications_close_date.value && group.controls.earliest_start_date.value) {
        const oneDay = 24 * 60 * 60 * 1000;
        const now = new Date();
        const a = new Date(group.controls.applications_close_date.value);
        const b = new Date(group.controls.earliest_start_date.value);
        const closeDateFromTodayDiff = Math.round(Math.abs((a.getTime() - now.getTime()) / (oneDay)));
        const closeDateFromStartDateDiff = Math.round(Math.abs((a.getTime() - b.getTime()) / (oneDay)));
        if (b < a) {
          const forbidden = b < a;
          return forbidden ? {'closeDate': ` should be before Start Date of opportunity.`} : null
        } else if (closeDateFromStartDateDiff < 30) {       // Minimum two weeks difference required
          const forbidden = closeDateFromStartDateDiff < 30;
          return forbidden ? {'closeDate': ` should be at least 30 days prior to Start Date.`} : null;
        } else {
          if (closeDateFromTodayDiff < 7) {
            const forbidden = closeDateFromTodayDiff < 7;
            return forbidden ? {'closeDate': ` should be at least 7 days from today.`} : null;
          }
        }
      } else if (!group.controls.applications_close_date.value) {
        return {'closeDate': ` not supplied`}
      }
    }
  }

  static shortDurationValidation() {
    return (group): { [key: string]: any } => {
      const salary = group.controls.salary.value;
      const accommodation_covered = group.controls.accommodation_covered.value;
      if (!salary && !accommodation_covered) {
        return {'salaryOrAccommodationRequired': ` must be provided or accommodation must be covered for short duration opportunities.`}
      }
      if (!salary && accommodation_covered.text !== 'Covered') {
        return {'salaryOrAccommodationCovered': ` must be provided or accommodation must be covered for short duration opportunities.`};
      }
      if (salary) {
        const salaryCurrency = group.controls.salary_currency.value;
        const salaryPeriodicity = group.controls.salary_periodicity.value;
        if (!salaryCurrency || !salaryPeriodicity) {
          return {'currencyAndPeriodicityRequired': ` is required`};
        }
      }
      return null;
    }
  }

}
