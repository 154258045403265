import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxUploaderModule } from 'ngx-uploader';
import { CookieModule } from 'ngx-cookie';
import { LandingModule } from './landing/landing.module';
import { MyDashboardModule } from './myDashboard/myDashboard.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { Ng2DragDropModule } from 'ng2-drag-drop';
import { SharedModule } from '../shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeComponent } from './home/home.component';
import { CarouselModule } from 'ngx-bootstrap';
// import { TranslatePipe } from 'app/pipes/translate.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CookieModule.forRoot(),
    NgxUploaderModule,
    ReactiveFormsModule,
    LandingModule,
    MyDashboardModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule,
    Ng2DragDropModule.forRoot(),
    SharedModule,
    CarouselModule.forRoot(),
  ],
  declarations: [ HomeComponent],
  // exports: [TranslatePipe]
})
export class ContainerModule { }
