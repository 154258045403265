import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'relativeDate'
})
export class RelativeDatePipe implements PipeTransform {
    transform(value: any, format?: any): any {
        if (!value) {
            return null;
        }

        const today = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        const date = moment(value).set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

        const diff = today.diff(date, 'days');

        if (diff === 0) {
            return 'today';
        }
        if (diff === 1) {
            return 'yesterday';
        }

        if (format) {
            return moment(value).format(format);
        }
        return moment(value);
    }
}
