
import {filter} from 'rxjs/operators';
import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './../../services/user.service';
import { SearchService } from 'app/services/search.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  showDiv = false;
  public currentUser: any = null;
  public navToggle = false;
  width = window.outerWidth;
  public showProfile = false;
  loading: boolean;
  userPresent: boolean;
  showMyOpportunities = false;
  tranparentNav = false;
  isApprovedInAnyBranch: boolean;
  isGep = false;

  constructor(
    private userService: UserService,
    private _elementRef: ElementRef,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
  ) {
    this.loading = true;
    this.userService.userPresent.subscribe(userPresent => {
      if (userPresent) {
        this.userPresent = true
        this.userService.userDataLoading.pipe(
          filter(data => data))
          .subscribe(data => {
            this.loading = false;
            this.currentUser = this.userService.currentUser;
            this.isGep = this.isGlobalPartner();
            this.isApprovedInAnyBranch = this.userService.atLeastOneApprovedBranch();
          })
      } else {
        this.currentUser = null;
        this.loading = false;
      }
    })
  }

  ngOnInit() {

  }
  
  hideUpgradeToPremium() {
      const routeName = this.router.url.indexOf('/partner/global-partner') > -1;
      if (routeName) {
          return true;
      }
      return false;
  }
 


  isGlobalPartner() {
    const managedCompanies = this.currentUser.managed_companies;
    if (!managedCompanies.length) {
      return false;
    }

    return !!managedCompanies[0].partner_type;    // consider only the first company as GEP.

  }

  redirect(url) {
    window.open(url, '_blank');
  }

  hideElementForSingleRoute(route) {
    const isRoute = this.router.url.indexOf('/' + route + '/') > -1;
    if (!isRoute) {
      return false;
    }
    const array = this.router.url.split('/');
    return array.length < 4;
  }

  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside) {
      this.showProfile = false;
    }
  }

  // @HostListener("window:scroll", [])
  // scrollPos () {
  //     let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  //     if ((number < 100) || (this.userPresent = true)) {
  //         this.tranparentNav = false;
  //     }
  //     else if ((number > 100) && (this.userPresent = false)){
  //         this.tranparentNav = true;
  //     }
  //     console.log(this.tranparentNav, this.userPresent);
  // }


  blog() {
    this.showDiv = !this.showDiv;
    window.location.href = 'https://partners.aiesec.org/blog';
  }
  backToYouth() {
    this.showDiv = !this.showDiv;
    window.location.href = 'http://aiesec.org/';
  }

  logout() {
    window.location.pathname = "/auth/log_out";
  }
  goToLogin() {
    window.location.href = 'auth?action=sign_in';
  }

  closeMyOpportunities(event) {
    this.showMyOpportunities = false
  }

  goToSearch() {
    this.searchService.previousRoute = null;
    this.router.navigateByUrl('/search');
  }

  openCrisp() {
    (<any>window).$crisp.push(['do', 'chat:open']);
  }
}
