
import {timer as observableTimer, BehaviorSubject, Subject } from 'rxjs';
import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie';
import { environment } from './../../environments/environment';
import { DOCUMENT } from "@angular/common";

@Injectable()
export class TokenService {

    tokenPresent = new BehaviorSubject(null);
    token: string;
    refreshedToken = new Subject();
    tokenUrl: string;

    constructor(
        private _cookieService: CookieService,
        private http: HttpClient,
        @Inject(DOCUMENT) private document: any
    ) {
        this.tokenUrl = `${environment.appApiUrl}/auth`;
        this.tokenManager();
    }

    tokenManager() {
        if (this._cookieService.get('aiesec_token')) {

            this.token = JSON.parse(decodeURIComponent(this._cookieService.get('aiesec_token'))).token.access_token;
            this.tokenPresent.next(true);

            let fullToken = JSON.parse(this._cookieService.get('aiesec_token'));
            let d = new Date(fullToken.token.expires_at);

            d.setMinutes(d.getMinutes() - 3);

            let period = 1000 * 7080;

            let timer = observableTimer(d, period);

            timer.subscribe(t => {
                this.getRefreshToken();
            });

        } else {
            this.token = null;
            this.tokenPresent.next(false);
        }

    }

    getRefreshToken(reload?) {
        this.http.get(`${this.tokenUrl}/refresh`, { withCredentials: true })
            .subscribe(data => {
                this.token = JSON.parse(decodeURIComponent(this._cookieService.get('aiesec_token'))).token.access_token;
                this.refreshedToken.next(this.token);
                if(reload) { 
                    this.document.location.reload();
                }
            }, err => {
                this._cookieService.remove('aiesec_token');
                if(reload) { 
                    location.href = `${environment.oauth.url}/users/sign_out`;
                }
            });
    }
}
