import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'date2'
})
export class Date2Pipe implements PipeTransform {

  transform(value: any, args?: any): any {
  	if(!value) return value;

    // for time formatting used in services
    if(args){
      let isNumber = isNaN(value);

      let parsedTime;
      try {
        if(isNumber){
            throw 'alphabets present'
        }
        else{
          if(value.length > 2) parsedTime =  moment.unix(value).format(args);
          else if (value.length === 2 ) parsedTime = moment().set({hours: value, minutes: 0}).format(args);
          else if (value.length === 1 ) return value;
        }

        if (parsedTime === 'Invalid date') throw "Invalid date";

        else return parsedTime;

      }

      catch(e) {

        if (e === 'alphabets present') {
          if (value.length > 8) {
            return moment(value).format(args);
          }
          else {
            return value;
          }
        }
        else if (e === 'Invalid date') return 'N/A';
      }
      return value;
    } else {
      return moment(value).format('MMM Do, YYYY hh:mm a');
    }


    
  }

}
