import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorComponent } from './error.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        ErrorComponent
    ],
    providers: [],
    exports: [
        CommonModule,
        ErrorComponent
    ]
})

export class ErrorModule {
}
