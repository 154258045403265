import { Injectable } from '@angular/core';
import { ModelService } from './model.service';
import { BehaviorSubject ,  Observable ,  Subject } from "rxjs";
import { AppApolloService } from 'app/services/app.apollo.service';

@Injectable()
export class ApplicationsService {

    applicationUpdated$: Subject<any> = new Subject();
    customStatusUpdated$: Subject<any> = new Subject();
    searchInputText = new BehaviorSubject('');
    selectedApplication = new BehaviorSubject(null);
    initializeKanban = new BehaviorSubject(null);

    reject_application = new BehaviorSubject(null);
    showAssignManager = new BehaviorSubject(null);
    application_id = new BehaviorSubject(null);
    panelName = new BehaviorSubject(null);
    allStatusesArray = new BehaviorSubject(null);
    moveApplication = new BehaviorSubject(null);
    statusChanged = new BehaviorSubject(null);
    updateAppToggleArray = new BehaviorSubject(null);
    toggleArrayUpdated = new BehaviorSubject(null);
    appToggleArray = {
        applicationIds: [],
        totalItems: null,
        openedFrom: ''
    };

    interViewMsg;
    private isMessageUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    applicationId;
    opportunityId;
    private isIdUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    bulkRejectApplication: Subject<boolean> = new Subject();
    bulkCvDownload: Subject<boolean> = new Subject();
    bulkCsvDownload: Subject<boolean> = new Subject();
    bulkMessage: Subject<boolean> = new Subject();

    constructor(
        private modelService: ModelService,
        private appApollo: AppApolloService,
    ) { }

    fetchAllApplications(GetOpportunityApplicationsQuery, variables, fetchPolicy?) {

        return new Promise((resolve, reject) => {
            this.appApollo.query(GetOpportunityApplicationsQuery, variables, fetchPolicy)
            .then((data: any) => {
                resolve(data.allOpportunityApplication);
            }, err => {
                reject(err);
            });
        })
    }

    fetchAllApplications$(GetOpportunityApplicationsQuery, variables, fetchPolicy?) {

        return Observable.create(observer => {
            this.appApollo.watchQuery(GetOpportunityApplicationsQuery, variables, fetchPolicy)
            .subscribe((data: any) => {
                observer.next(data.allOpportunityApplication);
            }, err => {
                observer.error(err);
            });
        })

    }

    fetchApplication(GetOpportunityApplicationQuery, appId) {

        const variables = {
            id: appId
        }

        return new Promise((resolve, reject) => {
            this.appApollo.query(GetOpportunityApplicationQuery, variables)
            .then((data: any) => {
                resolve(data.getApplication);
            }, err => {
                reject(err);
            });
        })
    }

    getApplication(opportunityApplicationId) {
        this.modelService.get({name: `applications/${opportunityApplicationId}`, qs: ''}).subscribe(application => {
            this.selectedApplication.next(application)
        })
    }

    rateScorecard(opportunityApplicationId, ratings) {
        return this.modelService.post(`applications/${opportunityApplicationId}/create_scorecards`, { application_scorecard_attributes: ratings })
    }

    updateCustomStatus(opportunityApplicationId, statusId) {
        return this.modelService.post(`applications/${opportunityApplicationId}/change_custom_status`, { new_status_id: statusId })
    }

    updateStatus(opportunityApplicationId, status) {
        return this.modelService.post(`applications/${opportunityApplicationId}/${status}`, {})
    }

    rejectApplication(opportunityApplicationId) {
        return this.modelService.post(`applications/${opportunityApplicationId}/reject`, {})
    }

    getApplicantsList(id, page, per_page) {
        return this.modelService.get({ name: 'applications/my', qs: `page=${page}&per_page=${per_page}&context=people&filters[opportunity_id]=${id}&sort=-applied` })
    }

    // Common post api
    postApi(endPoint, bodyParam) {
        return this.modelService.post(endPoint, { application: bodyParam })
    }

    // Application post api
    postApiInterviewSlot(endPoint, bodyParam) {
        return this.modelService.post(endPoint, { interview_slots: bodyParam })
    }

    // Offline Question post api
    postApiOfflineQue(endPoint, bodyParam) {
        return this.modelService.post(endPoint, { offline_assessments: bodyParam })
    }

    // Application get api
    getApi(endPoint) {
        return this.modelService.get({ name: endPoint })
    }


    getMessageUpdated(): Observable<boolean> {
        return this.isMessageUpdate.asObservable();
    }

    getMessage() {
        if (!this.interViewMsg) {
            this.interViewMsg = JSON.parse(sessionStorage.getItem('msg'));
            this.getMessageUpdated();
        }
        return this.interViewMsg;
    }

    setMessage(value): void {
        sessionStorage.setItem('msg', JSON.stringify(value));
        this.interViewMsg = value;
        this.isMessageUpdate.next(true);
    }

    // Interview Timeslot Details

    getTimeSlotDetails(id) {
        return this.modelService.get({ name: `applications/${id}/interview_slots`, qs: '' });
    }

    getOfflineAssessment(id) {
        return this.modelService.get({ name: `applications/${id}/offline_assessments`, qs: '' });
    }


    // Observable method
    getIdUpdated(): Observable<boolean> {
        return this.isIdUpdate.asObservable();
    }
}
