
import {takeWhile} from 'rxjs/operators';
import { Opportunity } from './../../services/opportunity.service';
import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { AppApolloService } from 'app/services/app.apollo.service';
import { UpdateOpportunity } from './scorecard-modal.graphql';
import { GetOrganisationForScorecards } from './../../graphql/queries';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'app-scorecard-modal',
  templateUrl: './scorecard-modal.component.html',
  styleUrls: ['./scorecard-modal.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.Default
})
export class ScorecardModalComponent implements OnInit {

  @Input() organisationId;
  @Input() opportunity;
  @Output() closeScorecardModal: EventEmitter<any> = new EventEmitter();
  @Output() createNewScorecard: EventEmitter<any> = new EventEmitter();
  scorecardsList: any;
  showModal: boolean = false;
  selectedScorecard = new Object();
  activeScorecard: any;
  errors = new Object();
  componentAlive: boolean = true;
  loading: boolean;
  // list: any = [];
  // currentOpportunity: any;

  constructor(
    private opportunityService: Opportunity,
    private appApollo: AppApolloService,
    private errorService: ErrorService,
  ) { }

  ngOnInit() {
    const variables = {
      id: this.organisationId
    }
    this.appApollo.watchQuery(GetOrganisationForScorecards, variables).pipe(
    takeWhile(() => this.componentAlive))
    .subscribe((data: any) => {
      const organisation = data.getOrganisation;
      const scorecardsConnection = organisation.scorecards;
      if (scorecardsConnection && scorecardsConnection.edges && scorecardsConnection.edges.length) {
        this.scorecardsList = scorecardsConnection.edges.map(edge => ({id: edge.node.id, text: edge.node.name})).filter(scorecard => scorecard.text);
        this.showModal = true;
        if (this.scorecardsList && this.opportunity && this.opportunity.scorecard) {
          this.activeScorecard = this.opportunity['scorecard'];
          this.activeScorecard =  {
            id: this.opportunity['scorecard'].id,
            text: this.opportunity['scorecard'].name
          }
        }
      }
    }, err => {
      this.errorService.showErrorModal(err.networkErr);
    })
  }

  ngOnDestroy() {
    this.componentAlive = false;
  }

  closeScorecard() {
    this.closeScorecardModal.emit();
  }

  scorecardSelected(selectedScorecard) {
    this.selectedScorecard = selectedScorecard
    if (this.selectedScorecard['id']) {
      this.errors['scorecardAssignError'] = '';
    }
  }

  createScoreCard() {
    this.createNewScorecard.emit();
  }

  assignScorecard() {
    this.loading = true;
    if (this.activeScorecard) {
      this.errors['scorecardAssignError'] = 'Scorecard is already assigned';
      this.loading = false;
      return
    } else {
      if (this.selectedScorecard['id']) {
        const opportunityInput = {
          scorecard_id: parseInt(this.selectedScorecard['id'] )
        }

        const variables = {
          id: this.opportunity.id,
          opportunity: opportunityInput
        }
        this.appApollo.mutate(UpdateOpportunity, variables)
        .then((data: any) => {
          const opportunity = data.updateOpportunity;
          this.opportunity = opportunity;
          this.opportunityService.opportunityUpdated$.next(true);
          this.activeScorecard = {
            id: this.opportunity.scorecard.id,
            text: this.opportunity.scorecard.name
          }
          this.loading = false;
          this.closeScorecard();
        }, err => {
          this.loading = false;
          this.errorService.showErrorModal(err.networkErr);
        })
      } else {
        this.errors['scorecardAssignError'] = 'Please Select Scorecard';
        this.loading = false;
      }
    }
  }

}
