import { Subscription } from 'rxjs/Rx';

import {takeWhile} from 'rxjs/operators';
import { CookieService } from 'ngx-cookie';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, HostListener, ElementRef, OnDestroy } from '@angular/core';
import { Opportunity } from 'app/services/opportunity.service';
import { Router } from '@angular/router';
import { AppApolloService } from 'app/services/app.apollo.service';
import { GetOpportunity, UpdateOpportunity } from './opportunity-navbar.graphql';
import { ErrorService } from '../../services/error.service';
import { environment } from '../../../environments/environment';
import { SearchService } from 'app/services/search.service';

@Component({
  selector: 'app-opportunity-navbar',
  templateUrl: './opportunity-navbar.component.html',
  styleUrls: ['./opportunity-navbar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class OpportunityNavbarComponent implements OnInit, OnDestroy {

  @Input() opportunityId: any;
  @Output() opportunitySwitched: EventEmitter<any> = new EventEmitter(null);
  @Output() switchToSlotsView: EventEmitter<any> = new EventEmitter(false);
  @Output() slotSelected: EventEmitter<any> = new EventEmitter(null);
  opportunity: any;
  activeOpportunity: any;
  isAlive = true;
  showAssignOPManager: boolean;
  showAssignScorecard: boolean;
  showMobileSettings = false;
  showMobileInfo = false;
  opportunityWatch: boolean;
  showSettingsPopup = false;
  aiesecUrl: any;
  showQuestionnaires: boolean;
  loading = false;
  onSearchPage = false;
  showAlertPopup = false;
  subscription: Subscription;
  slots = [];
  selectedSlot = null;

  constructor(
    private opportunityService: Opportunity,
    private router: Router,
    private _elementRef: ElementRef,
    private appApollo: AppApolloService,
    private errorService: ErrorService,
    private searchService: SearchService,
    private cookies: CookieService
    ) { }

  ngOnInit() {
    if (this.router.url.indexOf('/search') === 0) {
      this.onSearchPage = true;
    }
    this.aiesecUrl = environment.aiesec;
    this.loadOpportunity(this.opportunityId);
  }
  @HostListener('document:click', ['$event.target'])
  onClick(targetElement) {
      const clickedInside = this._elementRef.nativeElement.contains(targetElement);
      if (!clickedInside) {
        this.showMobileSettings = false;
        this.showMobileInfo = false;
        this.showSettingsPopup = false;
      }
  }

  ngOnDestroy() {
    this.isAlive = false;
    this.subscription.unsubscribe();
  }

  toggleView() {
    this.switchToSlotsView.emit(true);
  }

  loadOpportunity(opId) {

    const variables = {
      id: opId
    }
    this.loading = true;
    this.subscription = this.appApollo.watchQuery(GetOpportunity, variables, 'cache-and-network').pipe(
    takeWhile(() => this.isAlive))
    .subscribe((opData: any) => {
      this.loading = false;
      this.opportunity = opData.getOpportunity;
      if (this.opportunity) {
        this.activeOpportunity = { id: this.opportunity.id, text: this.opportunity.title, status: this.opportunity.status };
        this.opportunityService.selectedOpportunity = this.activeOpportunity;
        if (this.opportunity.slots.length) {
          this.slots = this.opportunity.slots.map(slot => {
            slot.text = slot.title;
            return slot;
          });
          this.selectedSlot = this.slots[0];
        }
        const questionnaireAlertShown = this.cookies.get('questionnaireAlertShown');
        if (!this.opportunity.questionnaire && !questionnaireAlertShown) {
          this.showAlertPopup = true;
        }
      }
    }, err => {
      this.loading = false;
      this.errorService.showErrorModal(err.networkErr);
    })
  }

  selectSlot(slot) {
    this.selectedSlot = slot;
    const slotFilter = {
      data: {
        slots: [+slot.id]
      },
      type: 'filters'
    }
    this.slotSelected.emit(slotFilter);
  }

  switchOpportunity(event) {
    this.opportunitySwitched.emit(event)
    this.loadOpportunity(event.id);
  }

  showOpManager() {
    this.showAssignOPManager = true;
  }

  assignOpManager(employee) {
    const data = {
      manager_ids: [+employee.id]
    }

    this.opportunity.managers.forEach(manager => {
      data.manager_ids.push(+manager.id)
    })

    this.updateOpportunity(data);
  }

  unassignOpManager(employeeId) {
    const data = {
      manager_ids: []
    }

    this.opportunity.managers.forEach(manager => {
      if (employeeId !== manager.id) {
        data.manager_ids.push(+manager.id)
      }
    })

    this.updateOpportunity(data);
  }

  updateOpportunity(opportunityInput) {
    const variables = {
      id: +this.opportunity.id,
      opportunity: opportunityInput
    }

    this.appApollo.mutate(UpdateOpportunity, variables)
    .then((opData: any) => {
      this.opportunity = opData.updateOpportunity;
      this.opportunityService.opportunityUpdated$.next(true);
    }, err => {
      this.errorService.showErrorModal(err.networkErr);
    })
  }

  closeScoreCardModal() {
    this.showAssignScorecard = false;
  }


  closeQuestionnaireModal() {
    this.showQuestionnaires = false;
  }

  routeToSpecificSearch() {
    this.router.navigateByUrl(`/search?opportunity=${this.opportunityId}`);
    this.searchService.previousRoute = this.router.url;
  }

  showScorecardButton() {
    const url = this.router.routerState.snapshot.url
    if (url.indexOf('applications') > -1) {
      return true
    } else {
      return false
    }
  }

  showManageButton() {
    const url = this.router.routerState.snapshot.url
    if (url.indexOf('applications') > -1) {
      return false
    } else {
      return true
    }
  }

  showExploreButton() {
    const url = this.router.routerState.snapshot.url
    if (url.indexOf('search') > -1) {
      return false
    } else {
      return true
    }
  }

  handleSettingsPopup() {
    this.showSettingsPopup = !this.showSettingsPopup;
  }

  goToPreviousRoute() {
    this.router.navigateByUrl(this.searchService.previousRoute);
    this.searchService.previousRoute = null;
  }

  getOpStatusClass(status) {
    switch (status) {
      case 'draft':
        return 'chip-draft'
      case 'open':
        return 'chip-open'
      case 'under_review':
        return 'chip-under-review'
      case 'completed':
      case 'finished':
        return 'chip-completed'
      case 'closed':
        return 'chip-closed'
      case 'removed':
        return 'chip-archived'
    }
  }

  hideAlert() {
    this.showQuestionnaires = true;
    this.cancelAlert();
  }

  cancelAlert() {
    this.showAlertPopup = false;
    this.cookies.put('questionnaireAlertShown', 'true');
  }

}
