export class CommonRegex{
    static URL_REGEXP = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
}

export class ValidationMessages{
    REQUIRED:string = ' is required.';
    QUESTION_REQUIRED:string = "Question" + this.REQUIRED;
    ADD_MAX_QUESTION:string = "You can add maximum three Questions.";
    VIDEO_DURATION_REQUIRED:string = "Question" + this.REQUIRED;
    DEADLINE_REQUIRED:string = "Deadline" + this.REQUIRED;
    INTERVIEWING_PLATFORM_REQUIRED:string = "Interviewing platform" + this.REQUIRED;
    INTERVIEWING_PLATFORM_VALID:string = "Enter valid URL";
    DESCRIPTION_REQUIRED:string = "Description" + this.REQUIRED;
    MODE_REQUIRED:string = "Mode" + this.REQUIRED;
    TIMESLOT_REQUIRED:string = "Time slot" + this.REQUIRED;
    HOURS_REQUIRED:string = "Hours" + this.REQUIRED;
    MINUTES_REQUIRED:string = "Minutes" + this.REQUIRED;
}
