import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApplicationsService} from "app/services/applications.service";
import * as moment from 'moment';
import {ValidationMessages} from "app/validation-component/validation-constant";
import { OfflineAssessmentApplicationMutation, OfflineAssessmentOpportunityMutation, UpdateInterviewDetails } from '../interview-details/interview-details.graphql';
import { AppApolloService } from 'app/services/app.apollo.service';

@Component({
    selector: 'app-offline-interview-modal',
    templateUrl: './offline-interview-modal.component.html',
    styleUrls: ['./offline-interview-modal.component.scss']
})
export class OfflineInterviewModalComponent implements OnInit {

    @Input() applicationDetails: any;
    @Output() close: EventEmitter<any> = new EventEmitter(null);
    @Input() onlineInterviewObj;

    //Constant message
    validationMsg = new ValidationMessages();

    // Array variables
    offlineQuestionsList: any[] = [];
    videoDurationArray: any[] = [];

    // Object variables
    applications = new Object();

    // Form group variables
    offlineInterviewForm: FormGroup;
    offlineQuestionsForm: FormGroup;
    opportunityID: any;

    constructor(
        private fb: FormBuilder, 
        private applicationsService: ApplicationsService,
        private appApollo: AppApolloService) {
        let minVideoDuration  = 5
        let maxVideoDuration = 60
        for(let i = 0; i <= maxVideoDuration; i+=5 ) this.videoDurationArray.push({id: 0, text: minVideoDuration + i})
    }

    ngOnInit() {
        this.createInterviewForm();
        this.createofflineQuestionsForm();
        this.opportunityID = this.applicationDetails.opportunity.id;
        if (this.opportunityID) {
            this.setOffilineQuestionaireList();
        }
    }

    setVideoDuration(duration) {
        this.offlineInterviewForm.get('maximum_video_duration').patchValue(duration.text)
    }

    createInterviewForm() {
        this.offlineInterviewForm = this.fb.group({
            maximum_video_duration: new FormControl('', [<any>Validators.required]),
            deadline: new FormControl('', [<any>Validators.required]),
        })
    }

    createofflineQuestionsForm() {
        this.offlineQuestionsForm = this.fb.group({
            offline_assessments: new FormArray([])
        });
    }

    question(val?) {
        if (!val) {
            val = '';
        }
        return new FormGroup({
            question: new FormControl(val, [<any>Validators.required])
        })
    }

    get questionsArray(): FormArray {
        return this.offlineQuestionsForm.get('offline_assessments') as FormArray;
    }

    addQuestion(val?) {
        this.questionsArray.push(this.question(val));
    }

    removeQuestion(index) {
        this.questionsArray.removeAt(index);
    }

    setOffilineQuestionaireList() {
        const offlineAssessments = this.applicationDetails.opportunity.offline_assessments;
        offlineAssessments.forEach(assessment => {
            this.questionsArray.push(this.question(assessment.question));
        })
    }

    // Events
    onSubmitOfflineQuestionaireForm(formParam, isValid) {
        let offline_assessments: any = [];
        if (formParam.offline_assessments.length) {
            for (let i = 0; i < formParam.offline_assessments.length; i++) {
                offline_assessments.push(formParam.offline_assessments[i]);
            }
        }

        if (isValid) {
            const variables = {
                id: this.applicationDetails.id,
                offline_assessments: offline_assessments
            }
            this.appApollo.mutate(OfflineAssessmentApplicationMutation, variables)
            .then((data: any) => {
            })

            if (this.offlineQuestionsList.length == 0) {
                this.appApollo.mutate(OfflineAssessmentOpportunityMutation, variables)
                .then((data: any) => {
                    const offlineAssessments = data.offlineAssessmentApplication;
                    this.offlineQuestionsList = offlineAssessments;
                })
            }
        }
    }

    onSubmitOfflineInterview(formParam, isValid) {
        this.onSubmitOfflineQuestionaireForm(this.offlineQuestionsForm.value, this.offlineQuestionsForm.valid)
        if (isValid) {
            let date = formParam.deadline;
            let UTCTime = moment.utc(new Date(date)).format();
            formParam["deadline"] = UTCTime;
            let values = Object.assign(this.onlineInterviewObj, formParam);
            
            const variables = {
                id: this.applicationDetails.id,
                opportunity_application: values
            }
            this.appApollo.mutate(UpdateInterviewDetails, variables)
            .then((data: any) => {
                const application = JSON.parse(JSON.stringify(data.updateInterviewDetailsApplication));
                this.applicationsService.applicationUpdated$.next(true);
                this.closeModal(application);
            })
        }
    }

    closeModal(value) {
        this.close.emit(value);
    }

    isRequiredValidation(formControlName) {
        return formControlName.hasError('required') && formControlName.touched;
    }

}
