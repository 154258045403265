import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'app/services/user.service';

@Injectable()
export class OnboardedGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return Observable.create(observer => {
        this.userService.userDataLoading.subscribe(data => {
          if (data) {
            const user = this.userService.currentUser
            if (user && user.managed_companies && user.managed_companies.length) {
              observer.next(true);
              return;
            }
            observer.next(this.router.navigateByUrl('/'));
          } else if (data === false) {
            observer.next(this.router.navigateByUrl('/'));
          }
        }, err => {
          observer.next(this.router.navigateByUrl('/'));
        })
      })
  }
}
