import * as moment from 'moment';

export function validateEmail(email) {  
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i.test(email)) return true 
     
    return false
}

export function flagName(name) {
    let result = name.toLowerCase();
    result = result.replace(/[ -]/g, '_').replace(",", '');
    return result;
  }

export function getApplicationStatusTitle(status) {
    status = status.toLowerCase();
    switch (status) {
      case 'open':
        return 'Applied'
      case 'matched':
        return 'Selected'
      case 'accepted':
        return 'Accepted'
      case 'approved':
        return 'Approved'
      case 'remote_realized':
      case 'realized':
      case 'completed':
      case 'finished':
        return 'Hired';
      default:
        return status;
    }
  }

  export function getStatusEndpointName(status) {
    status = status.toLowerCase();
    switch (status) {
      case 'approved':
        return 'approve'
      case 'matched':
        return 'match'
      case 'realized':
      case 'remote_realised':
        return 'realize'
      case 'completed':
      case 'finished':
        return 'complete'
      case 'open':
        return 'reopen'
      case 'rejected':
        return 'reject'
      case 'withdrawn':
        return 'withdraw'
      default:
        return null;
    }
  }

  export function filterInt(value) {
    if (/^(\-|\+)?([0-9]+|Infinity)$/.test(value)) return Number(value);

    return NaN;
  }
  
  export function treeify(nodes) {
    nodes = JSON.parse(JSON.stringify(nodes));
    let indexed_nodes = {}, tree_roots = [];
    for (let i = 0; i < nodes.length; i += 1) {
        indexed_nodes[nodes[i].id] = nodes[i];
    }
    for (let j = 0; j < nodes.length; j += 1) {
        let parent = nodes[j].parent;
        if (!parent || parent === null) {
            tree_roots.push(nodes[j]);
        } else {
            if (indexed_nodes[parent.id]['children']) {
              indexed_nodes[parent.id]['children'].push(nodes[j]);
            } else {
              indexed_nodes[parent.id]['children'] = [nodes[j]]
            }
        }
    }
    return tree_roots;
  }


export function makeQueryParams(object) {
  let q = ''
  
  if(typeof object === 'object') {
    Object.keys(object).forEach(key => {
      q = q + '&' + key + '=' + object[key]
    })
  }
  
  return q
}

export function getOppTypeName(oppTypeId) {

  if(!oppTypeId) return null

  oppTypeId = parseInt(oppTypeId)

  switch(oppTypeId) {
    case 1: 
        return 'Global Volunteer'
    case 2: 
        return 'Global Talent'
    case 5: 
        return 'Global Enterpreneur'
    default:
        return null
}
}

export function makeDateTime(date, hours, minutes, type) {
  if(type) {
      if(type === 'am') {
          if(hours === 12) hours = 0
      } else if(type === 'pm') {
          if(hours !== 12) hours+=12;
      }
  }
  let newDate = moment(date);
  newDate.set({'hour': hours, 'minutes': minutes})
  return newDate;
}

export function getCurrencySymbol(currencyCode) {
  if (!currencyCode) {
    return currencyCode;
  }
  const currency = currencyCode.toUpperCase();
  switch (currency) {
    case 'USD':
      return '$';
    case 'EUR':
      return '€';
    case 'GBP':
      return '£';
    case 'INR':
      return '₹';
    case 'CNY':
      return '¥';
    case 'JPY':
      return '¥';
    default:
      return currencyCode;
  }
}

export function getReverseStatusName(status) {
  if (!status || typeof status !== 'string') {
    return null;
  }
  status = status.toLowerCase();
  switch (status) {
    case 'accepted':
    case 'approved_ep_manager':
    case 'approved_tn_manager':
      return 'accepted';
    case 'rejected':
    case 'approval_broken':
    case 'realization_broken':
    case 'remote_realization_broken':
      return 'rejected'
    default:
      return status;
  }
}

export function getRelativeTime(dateTime) {
  if (!dateTime) {
      return null;
  }

  const today = moment();

  const date = moment(dateTime);

  const difference = today.diff(date);

  const dayInMilli = 86400000;
  const hourInMilli = 3600000;
  const minuteInMilli = 60000;

  if (difference >= dayInMilli) {
    return moment(date).format(`MMM Do, h:mm a`);
  } else if (difference >= hourInMilli) {
    const diff = today.diff(date, 'hours');
    return `${diff} ${diff > 1 ? 'hours' : 'hour' } ago`;
  } else if (difference >= minuteInMilli) {
    const diff = today.diff(date, 'minutes');
    return `${diff} ${diff > 1 ? 'minutes' : 'minute' } ago`;
  } else if (difference < minuteInMilli){
    return 'Just now';
  }
}

export function getMinimalisticRelativeTime(dateTime) {
  if (!dateTime) {
    return null;
  }

  const today = moment();

  const time = moment(dateTime);

  const diff = today.diff(time);

  const duration = moment.duration(diff);

  if (duration.years() > 0) {
    return duration.years() + 'y';
  } else if (duration.weeks() > 0) {
    return duration.weeks() + 'w';
  } else if (duration.days() > 0) {
    return duration.days() + 'd';
  } else if (duration.hours() > 0) {
    return duration.hours() + 'h';
  } else if (duration.minutes() > 0) {
    return duration.minutes() + 'm';
  } else if (duration.seconds() > 0) {
    return duration.seconds() + 's';
  }
}

export const deepCopy = (item) => {
  if (Array.isArray(item)) {
    return deepCopyArray(item)
  }
  if (typeof item === 'object') {
    return deepCopyObject(item)
  }
  return item
}

export const deepCopyArray = (arr) => {
  if (!arr) {
    return arr;
  }
  const copy = [];
  arr.forEach(elem => {
    if (Array.isArray(elem)) {
      copy.push(deepCopyArray(elem))
    } else {
      if (typeof elem === 'object') {
        copy.push(deepCopyObject(elem))
      } else {
        copy.push(elem)
      }
    }
  })
  return copy;
}

export const deepCopyObject = (obj) => {
  if (!obj) {
    return obj;
  }
  if (obj instanceof Date || obj instanceof Function) {
    return obj;
  }
  const tempObj = {};
  const keys = Object.keys(obj);
  for (const key of keys) {
    if (Array.isArray(obj[key])) {
      tempObj[key] = deepCopyArray(obj[key]);
    } else {
      if (typeof obj[key] === 'object') {
        tempObj[key] = deepCopyObject(obj[key]);
      } else {
        tempObj[key] = obj[key]
      }
    }
  }
  return tempObj;
}

export const isObjectEmpty = obj => {
  if (!obj) {
    return true;
  }
  for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        return false;
      }
  }
  return true;
}

export const getLevelColor = percentage => {
  if (percentage < 30) {
    return 'red-text';
  } else if (percentage < 50) {
    return 'sunsetOrange-text';
  } else if (percentage < 100) {
    return 'orange-text';
  }
  return 'kellyGreen-text';
}

export const ignoreKeys = ($event: KeyboardEvent) => {
  if ($event.key === '-') {
    $event.preventDefault();
  }
}

export function isMobile() {
	if ( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
		return true;
	} else {
		return false;
	}
}
