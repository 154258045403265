import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'searchByProperty'
})
export class SearchByPropertyPipe implements PipeTransform {

  transform(items: any, property: string, filterText: any): any {
    if (!filterText) return items
    
    return items.filter(item => {
      if(item[property] && filterText) return item[property].toLowerCase().search(filterText.toLowerCase()) > -1
    })
  }

}
