import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from 'app/services/user.service';

@Injectable()
export class ApprovedUserGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return Observable.create(observer => {
        this.userService.userDataLoading.subscribe(data => {
          if (data) {
            if (this.userService.atLeastOneApprovedBranch()) {
              return observer.next(true);
            } else {
              return observer.next(this.router.navigateByUrl('/'));
            }
          } else if (data === false) {
            return observer.next(this.router.navigateByUrl('/'));
          }
        }, err => {
          return observer.next(this.router.navigateByUrl('/'));
        })
      })
  }
}
